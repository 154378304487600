import { inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { OutlookStore } from '../../state';
import {
  catchError,
  from,
  lastValueFrom,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import {
  GRAPH_API_SCOPES,
  GRAPH_API_URI,
} from '../../outlook-shared-data-access.module';
import { InsightsService } from '@maximizer/core/shared/insights';
import { HttpClient } from '@angular/common/http';
import { GraphUserProfile } from '../../models';

@Injectable()
export class OutlookMsalService {
  private readonly scopes = inject(GRAPH_API_SCOPES);
  private readonly graphURI = inject(GRAPH_API_URI);
  private readonly msalService = inject(MsalService);
  private readonly insightsService = inject(InsightsService);
  private readonly http = inject(HttpClient);
  public outlookStore = inject(OutlookStore);

  getActiveAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  async initializeMsal(): Promise<void> {
    try {
      await this.msalService.instance.initialize();
    } catch (error) {
      console.error('MSAL initialization failed:', error);
      if (error instanceof Error) {
        this.insightsService.trackException({ error });
      } else {
        this.insightsService.trackException({
          error: new Error('Unknown error initializing MSAL'),
        });
      }
      throw error;
    }
  }

  readGraphProfile(): Observable<GraphUserProfile> {
    const request = { scopes: this.scopes };

    return from(this.msalService.acquireTokenSilent(request)).pipe(
      switchMap((data) => {
        if (!data.accessToken) {
          console.error('No token received to read graph profile.');
          return throwError(
            () => new Error('No token received to read graph profile.'),
          );
        }
        return this.http.get<GraphUserProfile>(this.graphURI, {
          headers: {
            Authorization: `Bearer ${data.accessToken}`,
          },
        });
      }),
      catchError((error) => {
        console.error('Error reading graph profile:', error);
        this.insightsService.trackException({ error });
        return throwError(() => error);
      }),
    );
  }

  private setGraphUserProperties(): void {
    this.readGraphProfile().subscribe((data) => {
      this.outlookStore.setGraphMailAccount(data.mail);
    });
  }

  checkAndSetActiveAccount() {
    const accounts = this.msalService.instance.getAllAccounts();
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (activeAccount) {
      this.msalService.instance.setActiveAccount(activeAccount);
      this.setGraphUserProperties();
      this.outlookStore.setHasActiveAccount(true);
    } else if (accounts.length > 0) {
      this.msalService.instance.setActiveAccount(accounts[0]);
      this.setGraphUserProperties();
      this.outlookStore.setHasActiveAccount(true);
    }
  }

  loginPopup() {
    this.msalService
      .loginPopup({ scopes: this.scopes })
      .pipe(
        catchError((error) => {
          console.error('Login failed', error);
          this.insightsService.trackException({ error });
          return of(undefined);
        }),
      )
      .subscribe((response: AuthenticationResult | undefined) => {
        if (response?.account) {
          this.msalService.instance.setActiveAccount(response.account);
          this.setGraphUserProperties();
          this.outlookStore.setHasActiveAccount(true);
        } else {
          console.warn('Login failed or was canceled.');
        }
      });
  }

  switchAccount() {
    this.msalService
      .loginPopup({
        scopes: this.scopes,
        prompt: 'select_account',
      })
      .pipe(
        catchError((error) => {
          console.error('Switch account failed', error);
          this.insightsService.trackException({ error });
          return of(undefined);
        }),
      )
      .subscribe((response: AuthenticationResult | undefined) => {
        if (response?.account) {
          this.msalService.instance.setActiveAccount(response.account);
          this.setGraphUserProperties();
          this.outlookStore.setHasActiveAccount(true);
        } else {
          console.warn('Switch account failed or was canceled.');
        }
      });
  }

  async getMsalAuthentication(): Promise<AuthenticationResult | null> {
    try {
      const request = {
        scopes: this.scopes,
      };
      const response = await lastValueFrom(
        this.msalService.acquireTokenSilent(request),
      );
      return response;
    } catch (error) {
      console.error('Error acquiring msal silent access token:', error);
      if (error instanceof Error) {
        this.insightsService.trackException({ error });
      } else {
        this.insightsService.trackException({
          error: new Error('Unknown error acquiring msal silent access token'),
        });
      }
      return null;
    }
  }
}
