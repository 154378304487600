import { Component, ElementRef, Inject, Input } from '@angular/core';
import { CDN_URL } from '@maximizer/core/shared/domain';
import { EmbeddedComponent } from '@maximizer/core/shared/ui';
import { OutlookEmail } from '@maximizer/outlook/shared/domain';

@Component({
  selector: 'maximizer-outlook-entry-list-item',
  templateUrl: './entry-list-item.component.html',
  standalone: false,
})
export class OutlookEntryListItemComponent extends EmbeddedComponent {
  constructor(
    @Inject(CDN_URL) public cdn: string,
    element: ElementRef,
  ) {
    super(element);
  }

  @Input()
  dataItem?: OutlookEmail;

  getAvatarLetters(dataItem: OutlookEmail) {
    if (!dataItem.emailAddress && !dataItem.displayName) return '';
    if (!dataItem.displayName) {
      return this.getFirstTwoLetters(dataItem.emailAddress);
    }
    if (dataItem?.abentryType === 'contact') {
      return this.getContactName(dataItem);
    }

    if (
      dataItem.displayName.indexOf(' ') === -1 &&
      dataItem.displayName.length > 1
    ) {
      if (dataItem.emailAddress === undefined) return '';
      return this.getFirstTwoLetters(dataItem.emailAddress);
    }
    return dataItem.displayName;
  }

  getAvatarClass(): string{
    if(this.dataItem?.abentryType === 'individual' ||
      this.dataItem?.abentryType === 'company') {
      return 'avatar-border avatar-square';
    }
    if(this.dataItem?.type === undefined) return 'avatar-square';
    return 'avatar-border';
  }

  private getFirstTwoLetters(item: string){
    if(item.length < 2) return '';
    const firstLetters = item.substring(0, 2);
    return `${firstLetters[0]} ${firstLetters[1]}`;
  }

  private getContactName(dataItem: OutlookEmail){
    if (dataItem?.abentryType !== 'contact') return ''; 

    const contactName = dataItem.displayName.substring(
      0,
      dataItem.displayName.lastIndexOf(' - '),
    );
    const lastNameIdx = contactName.lastIndexOf(' ');

    if (lastNameIdx === -1) {
      return this.getFirstTwoLetters(dataItem.displayName);
    }
    return contactName;
  }
}
