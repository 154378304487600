import { Component, Input } from '@angular/core';
import {
  OutlookTimelineDirection,
  OutlookTimelineEvent,
} from '@maximizer/outlook/shared/domain';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'maximizer-outlook-timeline-card',
  templateUrl: './timeline-card.component.html',
})
export class OutlookTimelineCardComponent {
  @Input() event!: OutlookTimelineEvent;

  private eventConnection: {
    from: string;
    to: string;
    with: string;
    for: string;
  };

  expanded = false;

  constructor(private translateService: TranslateService) {
    this.eventConnection = this.translateService.instant(
      'outlook.timeline.eventConnection',
    );
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  getTitleConnection(event: OutlookTimelineEvent) {
    let connection;
    switch (event.type) {
      case 'appointment':
      case 'task':
        return this.eventConnection.with;
      case 'interaction':
      case 'email':
        if (event.direction === OutlookTimelineDirection.Incoming) {
          connection = this.eventConnection.from;
          break;
        }
        if (event.direction === OutlookTimelineDirection.Outgoing) {
          connection = this.eventConnection.to;
          break;
        }
        connection = this.eventConnection.for;
        break;
      default:
        connection = this.eventConnection.for;
        break;
    }

    return connection;
  }
}
