import { Octopus } from '../..';
import { Mapper } from '../../../mappers';
import { CreateDocument } from '../../../models';

export class CreateDocumentMapper extends Mapper<
  CreateDocument,
  Octopus.DocumentWriteRequest
> {
  from(source: CreateDocument): Octopus.DocumentWriteRequest {
    return {
      Document: {
        Data: {
          Key: null,
          ParentKey: source.parentKey,
          Type: source.type,
          Name: source.name,
          BinaryDataId: source.binaryDataId,
          DateTime: source.dateTime.toISOString(),
          Description: source.description ?? '',
          Size: source.size,
          SecAccess: source.secAccess,
          Ext: source.extension,
        },
      },
      Configuration: Octopus.DocumentAccessDriver,
    };
  }
}
