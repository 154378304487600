import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DatePickerModule,
  DateTimePickerModule,
  TimePickerModule,
} from '@progress/kendo-angular-dateinputs';
import {
  ComboBoxModule,
  DropDownListModule,
  MultiSelectModule,
} from '@progress/kendo-angular-dropdowns';
import {
  CheckBoxModule,
  FormFieldModule,
  NumericTextBoxModule,
  TextAreaModule,
  TextBoxModule,
} from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { NgLetModule } from 'ng-let';
import { KendoModule } from '../kendo/kendo.module';
import { GoogleModule } from '../google/google.module';
import { UiPipesModule } from '../pipes/pipes.module';
import {
  CardLayoutComponent,
  DateFieldComponent,
  DisplayFieldComponent,
  DynamicFieldComponent,
  LayoutElementComponent,
  LayoutEntityComponent,
  NumberFieldComponent,
  SelectorFieldComponent,
  StringFieldComponent,
} from './components';
import { KendoComponentInjectorDirective } from './directives/kendo-component-injector.directive';
import { FieldLabelDirective } from './directives/label.directive';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { CustomDialogDirective } from '../directives/custom-dialog.directive';

@NgModule({
  imports: [
    ButtonsModule,
    CheckBoxModule,
    ComboBoxModule,
    CommonModule,
    DatePickerModule,
    DateTimePickerModule,
    DropDownListModule,
    FormFieldModule,
    FormsModule,
    KendoModule,
    GoogleModule,
    LabelModule,
    NgLetModule,
    NumericTextBoxModule,
    MultiSelectModule,
    ReactiveFormsModule,
    TextBoxModule,
    TextAreaModule,
    TimePickerModule,
    TooltipModule,
    UiPipesModule,
  ],
  exports: [
    CardLayoutComponent,
    DateFieldComponent,
    DisplayFieldComponent,
    DynamicFieldComponent,
    FieldLabelDirective,
    CustomDialogDirective,
    KendoComponentInjectorDirective,
    LayoutElementComponent,
    LayoutEntityComponent,
    NumberFieldComponent,
    SelectorFieldComponent,
    StringFieldComponent,
  ],
  declarations: [
    CardLayoutComponent,
    CustomDialogDirective,
    DateFieldComponent,
    DisplayFieldComponent,
    DynamicFieldComponent,
    FieldLabelDirective,
    KendoComponentInjectorDirective,
    LayoutElementComponent,
    LayoutEntityComponent,
    NumberFieldComponent,
    SelectorFieldComponent,
    StringFieldComponent,
  ],
})
export class UiLayoutModule {}
