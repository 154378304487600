import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ContextService } from './context.service';
import { Octopus } from '@maximizer/core/shared/domain';

@Injectable()
export class RecentEntryService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  private getQuery(
    entityType?: Octopus.EntityType,
    type?: Octopus.RecentEntryType,
  ): Octopus.LogicalQuery<Octopus.RecentEntry> {
    return {
      $AND: [
        { User: { $EQ: Octopus.CurrentUser } },
        entityType ? { EntityType: { $EQ: entityType } } : {},
        type ? { Type: { $EQ: type } } : {},
      ],
    };
  }

  private getRequest(
    query: Octopus.LogicalQuery<Octopus.RecentEntry>,
  ): Octopus.RecentEntryReadRequest {
    return {
      RecentEntry: {
        Criteria: {
          SearchQuery: query,
        },
        Scope: {
          Fields: {
            Key: 1,
          },
        },
      },
      Globalization: Octopus.DefaultGlobalization,
    };
  }

  getRecentEntry(
    entityType?: Octopus.EntityType,
    type?: Octopus.RecentEntryType,
  ): Observable<string | null> {
    const request = this.getRequest(this.getQuery(entityType, type));

    return this.http
      .post<Octopus.RecentEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === Octopus.ResponseStatusCode.Successful) {
            const data = response.RecentEntry?.Data;
            return data?.[0]?.Key ?? null;
          }
          return null;
        }),
      );
  }

  setRecentEntry(
    key: string | null,
    entries: string[],
    entityType?: Octopus.EntityType,
    type?: Octopus.RecentEntryType,
  ): Observable<boolean> {
    const request: Octopus.RecentEntryWriteRequest<string> = {
      RecentEntry: {
        Data: {
          Key: key,
          Items: entries,
          ...(key === null && {
            User: Octopus.CurrentUser,
            EntityType: entityType,
            Type: type,
          }),
        },
        Options: {
          ReadAfterSave: false,
        },
      },
      Globalization: Octopus.DefaultGlobalization,
    };

    return this.http
      .post<Octopus.RecentEntryWriteResponse>(
        `${this.context.api}${key ? Octopus.Action.UPDATE : Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map(
          (response) => response.Code === Octopus.ResponseStatusCode.Successful,
        ),
      );
  }
}
