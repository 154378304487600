import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepGuideId, StepGuide } from '@maximizer/outlook/shared/data-access';
import { fadeInAnimation } from '@maximizer/outlook/shared/ui';

@Component({
  selector: 'maximizer-outlook-onboarding-step-guide',
  templateUrl: './step-guide.component.html',
  animations: [fadeInAnimation],
  standalone: false,
})
export class StepGuideComponent {
  @Output() exit = new EventEmitter<StepGuideId>();
  @Output() next = new EventEmitter<number>();

  @Input() onboardingStep = 1;
  @Input() guide: StepGuide = {
    stepId: StepGuideId.AddContact1,
    guide: {
      step: 1,
      total: 1,
    },
    canNavigate: false,
    title: '',
    content: '',
    hideStepCount: false,
  };
}
