import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { EditableListItem, Octopus } from '@maximizer/core/shared/domain';
import { ContextService } from '@maximizer/core/shared/data-access';

type ModifyResult = {
  successful: boolean;
  item: EditableListItem<string> | null;
};

type RelatedEntryRequest = Octopus.Request & {
  Opportunity: Octopus.ReadRequest<Octopus.Opportunity>;
  QuotaRevenue: Octopus.ReadRequest<Octopus.QuotaRevenue>;
};

type RelatedEntryResponse = Octopus.Response & {
  Opportunity: Octopus.DataResponse<Octopus.Opportunity>;
  QuotaRevenue: Octopus.DataResponse<Octopus.QuotaRevenue>;
};

@Injectable()
export class RevenueTypeService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  get(): Observable<EditableListItem<string>[]> {
    const request: Octopus.OpportunityFieldOptionRequest = {
      Opportunity: {
        FieldOptions: {
          RevenueType: [
            {
              Key: 1,
              DisplayValue: 1,
              InActive: 1,
            },
          ],
        },
        Options: {
          ShowInactive: true,
        },
      },
    };

    return this.http
      .post<Octopus.OpportunityFieldOptionResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.Opportunity.FieldOptions.RevenueType
          ) {
            const mapper = new Octopus.OpportunityMapper();

            return result.Opportunity.FieldOptions.RevenueType.map((item) =>
              mapper.mapRevenueTypeForEdit(item),
            );
          }
          return [];
        }),
      );
  }

  add(
    type: EditableListItem<string>,
  ): Observable<EditableListItem<string> | null> {
    const request: Octopus.OpportunityFieldOptionWriteRequest = {
      Opportunity: {
        FieldOptions: {
          RevenueType: [
            {
              Key: null,
              Name: type.name,
              DisplayValue: type.name,
              InActive: false,
            },
          ],
        },
        Options: {
          Append: true,
        },
      },
    };

    return this.http
      .post<Octopus.OpportunityFieldOptionResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.Opportunity.FieldOptions.RevenueType?.length
          ) {
            const mapper = new Octopus.OpportunityMapper();

            return mapper.mapRevenueTypeForEdit(
              result.Opportunity.FieldOptions.RevenueType[0],
            );
          }
          return null;
        }),
      );
  }

  checkDuplicatedName(name: string): Observable<boolean> {
    const request: Octopus.OpportunityFieldOptionReadRequest = {
      Opportunity: {
        FieldOptions: {
          RevenueType: {
            Scope: {
              Fields: {
                Key: 1,
              },
            },
            Criteria: {
              SearchQuery: {
                Name: {
                  $EQ: name,
                },
              },
            },
          },
        },
        Options: {
          ShowInactive: true,
        },
      },
    };

    return this.http
      .post<Octopus.OpportunityFieldOptionReadResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.Opportunity.FieldOptions.RevenueType?.Data
          ) {
            return result.Opportunity.FieldOptions.RevenueType?.Data.length > 0;
          }
          return false;
        }),
      );
  }

  checkUse(type: string): Observable<boolean> {
    const readRequest: Octopus.ReadRequest<
      Pick<Octopus.Opportunity, 'Key' | 'RevenueType'>
    > = {
      Criteria: {
        SearchQuery: {
          RevenueType: {
            $EQ: type,
          },
        },
        Top: 1,
      },
      Scope: {
        Fields: {
          Key: 1,
        },
      },
    };

    const request: RelatedEntryRequest = {
      Configuration: Octopus.OpportunityReadDriver,
      Opportunity: readRequest,
      QuotaRevenue: readRequest,
    };

    return this.http
      .post<RelatedEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          return (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            (result.Opportunity.Data.length > 0 ||
              result.QuotaRevenue.Data.length > 0)
          );
        }),
      );
  }

  update(
    type: EditableListItem<string>,
  ): Observable<EditableListItem<string> | null> {
    return this.modify(type, { Modify: true }).pipe(
      map((result) => result.item),
    );
  }

  delete(type: EditableListItem<string>): Observable<boolean> {
    return this.modify(type, { Remove: true }).pipe(
      map((result) => result.successful),
    );
  }

  private modify(
    type: EditableListItem<string>,
    operation: Octopus.FieldOptionsWriteOperation,
  ): Observable<ModifyResult> {
    const request: Octopus.OpportunityFieldOptionWriteRequest = {
      Opportunity: {
        FieldOptions: {
          RevenueType: [
            {
              Key: type.id,
              Name: type.name,
              DisplayValue: type.name,
              InActive: !type.isActive,
            },
          ],
        },
        Options: operation,
      },
    };

    return this.http
      .post<Octopus.OpportunityFieldOptionResponse>(
        `${this.context.api}${Octopus.Action.UPDATE}`,
        request,
      )
      .pipe(
        map((result) => {
          const modify: ModifyResult = {
            successful: result.Code === Octopus.ResponseStatusCode.Successful,
            item: null,
          };

          if (
            modify.successful &&
            result.Opportunity?.FieldOptions.RevenueType?.length
          ) {
            const mapper = new Octopus.OpportunityMapper();

            modify.item = mapper.mapRevenueTypeForEdit(
              result.Opportunity.FieldOptions.RevenueType[0],
            );
          }
          return modify;
        }),
      );
  }
}
