import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { OpenAI } from '@maximizer/core/shared/domain';
import { ContextService } from './context.service';

@Injectable({
  providedIn: 'root',
})
export class OpenAiService {
  readonly completionsUrl = 'https://api.maximizer.com/ai/v1/chat/completions';

  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  getSmartQuestions(
    message: OpenAI.GPTMessage[],
    maxTokens: number,
    temperature: number,
  ): Observable<string> {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.context.token}`,
    );

    const request: OpenAI.CompletionRequest = {
      messages: message,
      model: 'gpt-3.5-turbo',
      max_tokens: maxTokens,
      temperature: temperature,
    };

    return this.http
      .post<OpenAI.CompletionResponse>(this.completionsUrl, request, {
        headers: headers,
      })
      .pipe(
        map((response: OpenAI.CompletionResponse) => {
          return response.choices[0].message.content;
        }),
      );
  }

  summarize<T>(
    texts: string[],
    prompt: string,
    model = 'gpt-3.5-turbo',
    temperature = 0.1,
  ): Observable<T | null> {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.context.token}`,
    );

    const userMessages: OpenAI.GPTMessage[] = texts.map((text) => ({
      role: 'user',
      content: text,
    }));

    const systemMessage: OpenAI.GPTMessage = {
      role: 'system',
      content: prompt,
    };

    const request: OpenAI.CompletionRequest = {
      messages: [...userMessages, systemMessage],
      model,
      temperature,
      response_format: { type: 'json_object' },
    };

    return this.http
      .post<OpenAI.CompletionResponse>(this.completionsUrl, request, {
        headers,
      })
      .pipe(
        map((response) => {
          return JSON.parse(response.choices[0].message.content);
        }),
        catchError(() => of(null)),
      );
  }
}
