<div class="text-xs bg-neutral-10 p-4 h-full flex w-full">
  @if (loading) {
    <maximizer-loader
      type="legacy"
      class="outlook-loader my-auto"
    ></maximizer-loader>
  }

  <div class="pb-8 space-y-2 w-full h-full">
    @if (!loading) {
      <div class="filter-panel">
        <div class="flex flex-col w-full">
          <div class="flex space-x-2 justify-between">
            <kendo-textbox
              id="outlook-timeline-ui-timeline-search"
              (valueChange)="filterByText($event)"
              [placeholder]="'outlook.timeline.search' | translate"
              [clearButton]="true"
              class="w-2/3"
            >
              <ng-template kendoTextBoxPrefixTemplate>
                <kendo-svgicon [icon]="searchIcon"></kendo-svgicon>
                <kendo-textbox-separator></kendo-textbox-separator>
              </ng-template>
            </kendo-textbox>

            <button
              kendoButton
              #togglePopup
              id="outlook-timeline-ui-timeline-filters"
              (click)="toggleFilterPopup()"
              [classList]="'flex ml-auto px-0'"
              fillMode="flat"
              rounded="medium"
            >
              <div class="flex">
                <span
                  class="material-icons icon-filter-alt icon-16 mx-1 my-auto font-bold"
                ></span>
                <p>{{ 'outlook.timeline.filter.title' | translate }}</p>
                <span
                  class="fa-icons icon-solid icon-caret-down icon-16 mx-1 my-auto"
                ></span>
              </div>
            </button>
          </div>

          @if (filtersByType > 0 || filtersByUser > 0) {
            <div class="flex py-1">
              @if (filtersByType > 0) {
                <kendo-chip
                  size="medium"
                  rounded="full"
                  fillMode="solid"
                  themeColor="base"
                  [removable]="true"
                  (remove)="onRemoveTypeFilter()"
                  class=""
                >
                  {{ filtersByType }}
                  {{ 'outlook.timeline.filter.typeChip' | translate }}
                </kendo-chip>
              }
              @if (filtersByUser > 0) {
                <kendo-chip
                  size="medium"
                  rounded="full"
                  fillMode="solid"
                  themeColor="base"
                  [removable]="true"
                  (remove)="onRemoveUserFilter()"
                >
                  {{ filtersByUser }}
                  {{ 'outlook.timeline.filter.userChip' | translate }}
                </kendo-chip>
              }
            </div>
          }

          <!-- Filters -->
          @if (showFilterPopup) {
            <kendo-popup
              [anchor]="togglePopup.element"
              [collision]="{ horizontal: 'fit', vertical: 'fit' }"
              class="w-full px-2"
            >
              <form
                [formGroup]="form!"
                class="flex flex-col justify-between mt-1"
                (submit)="applyFilters($event)"
              >
                <!-- User -->
                <div class="flex items-center p-2">
                  <span class="fa-icons icon-solid icon-user icon-14"></span>
                  <p class="font-bold ml-1">
                    {{ 'outlook.timeline.filter.users' | translate }}
                  </p>
                </div>
                <div class="mx-2">
                  <kendo-multiselect
                    kendoMultiSelectSummaryTag
                    [data]="users"
                    [formControlName]="'users'"
                    size="small"
                    textField="displayName"
                    valueField="key"
                    [popupSettings]="{ popupClass: 'ml-1 w-full' }"
                  >
                  </kendo-multiselect>
                </div>

                <!-- Type -->
                <div class="flex items-center p-2">
                  <span class="fa-icons icon-clock icon-14 font-bold"></span>
                  <p class="font-bold ml-1">
                    {{ 'outlook.timeline.filter.timelineItems' | translate }}
                  </p>
                </div>
                <div
                  class="scrollbar max-h-[120px] [@media(min-height:750px)]:max-h-[300px]"
                >
                  @for (filterType of types; track $index) {
                    <div
                      id="outlook-timeline-ui-timeline-filter{{
                        filterType.tag
                      }}"
                      class="hover:bg-inserv-10 cursor-pointer px-2 py-1"
                      [class]="
                        verifyCheckbox(filterType.key) ? 'bg-inserv-5' : ''
                      "
                      (click)="toggleCheckbox(filterType.key)"
                    >
                      <label class="k-checkbox-label cursor-pointer">
                        <input
                          type="checkbox"
                          kendoCheckBox
                          [formControlName]="filterType.key"
                          class="mt-0.5"
                        />
                        {{ filterType.name }}
                      </label>
                    </div>
                  }
                </div>
                <!-- Filter Buttons -->
                <div
                  class="bg-neutral-5 z-50 flex self-end space-x-2 p-2 w-full sticky bottom-0"
                >
                  <button
                    kendoButton
                    id="outlook-timeline-ui-timeline-cancel"
                    rounded="large"
                    class="uppercase rounded"
                    themeColor="primary"
                    fillMode="flat"
                    (click)="toggleFilterPopup()"
                    [disabled]="loading"
                    fillMode="flat"
                    style="margin-left: auto"
                  >
                    {{ 'outlook.buttons.cancel' | translate }}
                  </button>
                  <button
                    kendoButton
                    id="outlook-timeline-ui-timeline-apply"
                    class="uppercase rounded ml-auto"
                    themeColor="primary"
                    fillMode="outline"
                    (click)="applyFilters($event)"
                  >
                    <span>{{ 'outlook.buttons.apply' | translate }} </span>
                  </button>
                </div>
              </form>

              <ng-template #checkboxTemplate let-filterType>
                <div class="flex m-1" (click)="toggleCheckbox(filterType.key)">
                  <input
                    type="checkbox"
                    [id]="filterType.key"
                    #filterType
                    kendoCheckBox
                  />
                  <kendo-label
                    class="k-checkbox-label"
                    [for]="filterType"
                    [text]="filterType.name"
                  ></kendo-label>
                </div>
              </ng-template>
            </kendo-popup>
          }
        </div>
      </div>
    }

    <!-- Notes -->
    @if (!importantNotes && !groupedTimeline) {
      <div class="flex" (click)="toggleFilterPopup(false)">
        <div class="flex flex-col min-h-[120px] m-auto">
          <img
            class="mx-auto mt-auto max-h-[40px]"
            [src]="cdn + '/assets/images/outlook/timeline-emptystate.png'"
            alt="No items available"
          />
          <span class="mb-auto mt-2 text-xs text-center">{{
            'outlook.timeline.emptyState' | translate
          }}</span>
        </div>
      </div>
    }

    @if (importantNotes) {
      <div
        class="rounded shadow-card flex flex-col divide-y divide-neutral-30 text-xs bg-white"
      >
        <div class="flex flex-row justify-center text-center">
          <span class="font-bold p-2"
            >{{ 'outlook.timeline.importantNotes' | translate }}
          </span>
          <span class="text-xs my-auto text-neutral-70 font-normal"
            >{{ importantNotes.length }}
            {{ 'outlook.timeline.items' | translate }}</span
          >
        </div>
        @for (event of importantNotes; track $index) {
          <div class="flex flex-col">
            <maximizer-outlook-timeline-card
              [event]="event"
            ></maximizer-outlook-timeline-card>
          </div>
        }
      </div>
    }

    <!-- Events -->
    @for (item of groupedTimeline; track $index) {
      <div
        class="rounded shadow-card flex flex-col divide-y divide-neutral-30 text-xs bg-white"
        (click)="toggleFilterPopup(false)"
      >
        <div class="flex justify-center text-center">
          <span class="font-bold p-2"
            >{{ item.date | date: 'MMMM YYYY' }}
          </span>
          <span class="text-xs my-auto text-neutral-70 text-caption"
            >{{ item.events.length }}
            {{ 'outlook.timeline.items' | translate }}</span
          >
        </div>
        <ng-container class="flex flex-col" *ngFor="let event of item.events">
          <maximizer-outlook-timeline-card
            [event]="event"
          ></maximizer-outlook-timeline-card>
        </ng-container>
      </div>
    }
  </div>
</div>

<maximizer-outlook-notification
  #notification
  entryId="timeline"
  [closable]="true"
>
</maximizer-outlook-notification>
