import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import {
  SmartFill,
  UrlHeaders,
  CompanyInfo,
} from '@maximizer/core/shared/domain';
import { ContextService } from './context.service';
import { SMART_FILL } from '../data-access.module';

@Injectable()
export class SmartFillService {
  url?: string;

  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
    @Inject(SMART_FILL) private readonly smartFill: string,
  ) {
    this.url = this.context.smartFill || this.smartFill;
  }

  getUrlHeaders(url: string): Observable<UrlHeaders> {
    const mapper = new SmartFill.HeadersMapper();
    const apiUrl = `${this.url}headers?url=${url}`;
    const headers = new HttpHeaders({
      Authorization: this.context.token,
      'Content-Type': 'application/json',
    });

    return this.http.get<SmartFill.Headers>(apiUrl, { headers }).pipe(
      map((response) => {
        return mapper.from(response);
      }),
    );
  }

  getCompanyInfo(url: string): Observable<CompanyInfo> {
    const mapper = new SmartFill.CompanyInfoMapper();
    const apiUrl = `${this.url}companies?emailDomain=${url}`;
    const headers = new HttpHeaders({
      Authorization: this.context.token,
      'Content-Type': 'application/json',
    });

    return this.http.get<SmartFill.Company>(apiUrl, { headers }).pipe(
      map((response) => {
        return mapper.from(response);
      }),
    );
  }
}
