import {
  atArray,
  doPhoneFormat,
  ifWhitespaceThenEmpty,
} from '@maximizer/shared/util';
import { Mapper } from '../../../mappers';
import {
  CreateUser,
  Email,
  PhoneNumber,
  Security,
  User,
  UserForm,
  UserRights,
  UserRoles,
} from '../../../models';
import { Permissions } from '../models';
import { Email as OctopusEmail } from '../models/email';
import { Phone as OctopusPhone } from '../models/phone';
import {
  User as OctopusUser,
  UserRoles as OctopusUserRoles,
} from '../models/user';

export class UserMapper extends Mapper<OctopusUser, User> {
  from(source: OctopusUser): User {
    return {
      key: source.Key.Value ?? '',
      id: source.Key.Uid,
      name: source.DisplayValue,
      roles: this.mapRoles(source.Roles),
      rights: this.mapRights(source),
      phone1: this.mapPhone(source.Phone1),
      phone2: this.mapPhone(source.Phone2),
      phone3: this.mapPhone(source.Phone3),
      phone4: this.mapPhone(source.Phone4),
      email1: this.mapEmail(source.Email1),
      email2: this.mapEmail(source.Email2),
      email3: this.mapEmail(source.Email3),
      displayName: source.DisplayName,
      mrMs: source.MrMs,
      initial: source.Initial,
      firstName: source.FirstName,
      lastName: source.LastName,
      companyName: source.CompanyName,
      position: source.Position,
      department: source.Department,
      division: source.Division,
      webSite: source.WebSite,
      address: {
        key: source.Address?.Key as string,
        addressLine1: source.Address?.AddressLine1,
        addressLine2: source.Address?.AddressLine2,
        city: source.Address?.City,
        country: source.Address?.Country,
        stateProvince: source.Address?.StateProvince,
        zipCode: source.Address?.ZipCode,
      },
      enabled: source.Enabled,
      webAccess: (source.Disabled & 0x02) === 0,
      serviceAccess: (source.Disabled & 0x0010) == 0,
      creationDate: source.CreationDate,
      lastLoginDate: source.LastLoginDate,
      lastModifyDate: source.LastModifyDate
    };
  }

  private mapPhone(phone?: OctopusPhone): PhoneNumber {
    return {
      phoneNumber: doPhoneFormat(phone?.Number ?? ''),
      extension: phone?.Extension ?? '',
    };
  }

  private mapEmail(email?: OctopusEmail): Email {
    return {
      email: email?.Address ?? '',
      description: email?.Description ?? '',
      validated: false,
      isNew: false,
      default: email?.Default ?? false,
    };
  }

  private mapRoles(roles?: OctopusUserRoles): UserRoles {
    return {
      administrator: roles?.Administrator ?? false,
      salesManager: roles?.SalesManager ?? false,
      salesRepresentative: roles?.SalesRepresentative ?? false,
      customerServiceManager: roles?.CustomerServiceManager ?? false,
      customerServiceRepresentative:
        roles?.CustomerServiceRepresentative ?? false,
    };
  }

  private mapRights(source: OctopusUser): UserRights {
    return {
      addressBook: this.mapPermissions(source.Permissions?.AddressBook),
      accountSetup: this.mapPermissions(source.Permissions?.AccountSetup),
      targets: this.mapPermissions(source.Permissions?.Quota),
      opportunities: this.mapPermissions(source.Permissions?.Opportunity),
      interactionLog: this.mapPermissions(source.Permissions?.InteractionLog),
      notes: this.mapPermissions(source.Permissions?.Notes),
      documents: this.mapPermissions(source.Permissions?.Documents),
      userDefinedFieldSetup: this.mapPermissions(
        source.Permissions?.UserDefinedFieldSetup,
      ),
      userDefinedFields: this.mapPermissions(
        source.Permissions?.UserDefinedFields,
      ),
      privateEntries: source.Privileges?.PrivateEntries ?? false,
      globalEdit: source.Privileges?.GlobalEdit ?? false,
      modifyKeyFields: source.Privileges?.ModifyKeyFields ?? false,
      modifySystemTables: source.Privileges?.ModifySystemTables ?? false,
      import: source.Privileges?.Import ?? false,
      export: source.Privileges?.Export ?? false,
      customerService: this.mapPermissions(source.Permissions?.CustomerService),
      modifyPrivateEntries: source.Privileges?.BypassEntryPermissions ?? false,
      lead: this.mapPermissions(source.Permissions?.Lead),
    };
  }

  private mapPermissions(source?: Permissions): Security {
    return {
      read: source?.Read ?? false,
      insert: source?.Create ?? false,
      update: source?.Update ?? false,
      delete: source?.Delete ?? false,
    };
  }
}

export class UserFormMapper {
  from(user: UserForm, currentUser: User): Partial<OctopusUser> {
    const octopusUser: Partial<OctopusUser> = {
      Key: {
        Uid: currentUser.id,
        Value: currentUser.key,
      },
      DisplayName: ifWhitespaceThenEmpty(user.DisplayName).trim(),
      MrMs: ifWhitespaceThenEmpty(user.MrMs).trim(),
      Initial: ifWhitespaceThenEmpty(user.Initial).trim(),
      FirstName: ifWhitespaceThenEmpty(user.FirstName).trim(),
      LastName: ifWhitespaceThenEmpty(user.LastName).trim(),
      CompanyName: ifWhitespaceThenEmpty(user.CompanyName).trim(),
      Position: ifWhitespaceThenEmpty(user.Position).trim(),
      Department: ifWhitespaceThenEmpty(user.Department).trim(),
      Division: ifWhitespaceThenEmpty(user.Division).trim(),
      WebSite: ifWhitespaceThenEmpty(user.WebSite).trim(),
      Address: {
        AddressLine1: ifWhitespaceThenEmpty(user.Address).trim(),
        AddressLine2: '',
        City: ifWhitespaceThenEmpty(user.City).trim(),
        Country: ifWhitespaceThenEmpty(user.Country).trim(),
        StateProvince: ifWhitespaceThenEmpty(user.StateProvince).trim(),
        ZipCode: ifWhitespaceThenEmpty(user.ZipCode).trim(),
      },
    };

    octopusUser.Phone1 = this.toOctopusPhone(atArray(user.Phones, 0));
    octopusUser.Phone2 = this.toOctopusPhone(atArray(user.Phones, 1));
    octopusUser.Phone3 = this.toOctopusPhone(atArray(user.Phones, 2));
    octopusUser.Phone4 = this.toOctopusPhone(atArray(user.Phones, 3));
    octopusUser.Email1 = this.toOctopusEmail(atArray(user.Emails, 0));
    octopusUser.Email2 = this.toOctopusEmail(atArray(user.Emails, 1));
    octopusUser.Email3 = this.toOctopusEmail(atArray(user.Emails, 2));

    return octopusUser;
  }

  private toOctopusPhone(data?: PhoneNumber): OctopusPhone | undefined {
    return data
      ? ({
          Number: ifWhitespaceThenEmpty(data.phoneNumber).trim(),
          Extension: ifWhitespaceThenEmpty(data.extension).trim(),
        } as OctopusPhone)
      : ({ Number: '', Extension: '' } as OctopusPhone);
  }

  private toOctopusEmail(data?: Email): OctopusEmail | undefined {
    return data
      ? ({
          Address: ifWhitespaceThenEmpty(data.email).trim(),
          Description: ifWhitespaceThenEmpty(data.description).trim(),
          Default: data.default,
        } as OctopusEmail)
      : ({ Address: '', Description: '', Default: false } as OctopusEmail);
  }
}

export class CreateUserMapper extends Mapper<CreateUser, Partial<OctopusUser>> {
  from(user: CreateUser): Partial<OctopusUser> {
    return {
      Key: {
        Uid: user.id,
      },
      AccessType: user.accessType,
      Password: user.password,
      Email1: {
        Address: user.email1.email,
        Description: '',
      },
      Privileges: {
        BypassEntryPermissions: user.rights?.modifyPrivateEntries ?? false,
        Export: false,
        Import: false,
        GlobalEdit: false,
        PrivateEntries: false,
        ModifyKeyFields: false,
        ModifySystemTables: false,
      },
      Permissions: {
        AccountSetup: this.mapPermissions(user.rights?.accountSetup),
        AddressBook: this.mapPermissions(user.rights?.addressBook),
        Quota: this.mapPermissions(),
        Opportunity: this.mapPermissions(),
        UserDefinedFieldSetup: this.mapPermissions(
          user.rights?.userDefinedFieldSetup,
        ),
        UserDefinedFields: this.mapPermissions(user.rights?.userDefinedFields),
        InteractionLog: this.mapPermissions(),
        Notes: this.mapPermissions(),
        Documents: this.mapPermissions(),
        CustomerService: this.mapPermissions(user.rights?.customerService),
        Lead: this.mapPermissions(),
      },
    };
  }

  private mapPermissions(source?: Security): Permissions {
    return {
      Read: source?.read ?? false,
      Create: source?.insert ?? false,
      Update: source?.update ?? false,
      Delete: source?.delete ?? false,
    };
  }
}
