import { formatISO } from 'date-fns';
import { TranslationListItem } from '../../../models/customization';
import { DateRange } from '../../../models';

export enum DateOptionId {
  TODAY = '$TODAY',
  TOMORROW = '$TOMORROW',
  YESTERDAY = '$YESTERDAY',
  THISWEEK = '$THISWEEK',
  NEXTWEEK = '$NEXTWEEK',
  LASTWEEK = '$LASTWEEK',
  THISMONTH = '$THISMONTH',
  NEXTMONTH = '$NEXTMONTH',
  LASTMONTH = '$LASTMONTH',
  THISANDLASTMONTH = '$THISANDLASTMONTH',
  LASTTHREEMONTHS = '$LASTTHREEMONTHS',
  LASTSIXMONTHS = '$LASTSIXMONTHS',
  THISFISCALQUARTER = '$THISFISCALQUARTER',
  NEXTFISCALQUARTER = '$NEXTFISCALQUARTER',
  LASTFISCALQUARTER = '$LASTFISCALQUARTER',
  THISFISCALYEAR = '$THISFISCALYEAR',
  NEXTFISCALYEAR = '$NEXTFISCALYEAR',
  LASTFISCALYEAR = '$LASTFISCALYEAR',
  THISYEAR = '$THISYEAR',
  NEXTYEAR = '$NEXTYEAR',
  LASTYEAR = '$LASTYEAR',
  CUSTOM = 'custom',
}

export const DateOffset: Record<string, string | string[]> = {
  [DateOptionId.TODAY]: '$TODAY',
  [DateOptionId.TOMORROW]: "$OFFSET(D+1, '$TODAY')",
  [DateOptionId.YESTERDAY]: "$OFFSET(D-1, '$TODAY')",
  [DateOptionId.THISWEEK]: '$THISWEEK',
  [DateOptionId.NEXTWEEK]: "$OFFSET(W+1, '$THISWEEK')",
  [DateOptionId.LASTWEEK]: "$OFFSET(W-1, '$THISWEEK')",
  [DateOptionId.THISMONTH]: '$THISMONTH',
  [DateOptionId.NEXTMONTH]: "$OFFSET(M+1, '$THISMONTH')",
  [DateOptionId.LASTMONTH]: "$OFFSET(M-1, '$THISMONTH')",
  [DateOptionId.THISANDLASTMONTH]: ["$OFFSET(M-1, '$THISMONTH')", '$THISMONTH'],
  [DateOptionId.LASTTHREEMONTHS]: ["$OFFSET(M-2, '$THISMONTH')", '$NOW()'],
  [DateOptionId.LASTSIXMONTHS]: ["$OFFSET(M-5, '$THISMONTH')", '$NOW()'],
  [DateOptionId.THISFISCALQUARTER]: '$THISFISCALQUARTER',
  [DateOptionId.NEXTFISCALQUARTER]: "$OFFSET(Q+1, '$THISFISCALQUARTER')",
  [DateOptionId.LASTFISCALQUARTER]: "$OFFSET(Q-1, '$THISFISCALQUARTER')",
  [DateOptionId.THISFISCALYEAR]: '$THISFISCALYEAR',
  [DateOptionId.NEXTFISCALYEAR]: "$OFFSET(Y+1, '$THISFISCALYEAR')",
  [DateOptionId.LASTFISCALYEAR]: "$OFFSET(Y-1, '$THISFISCALYEAR')",
  [DateOptionId.THISYEAR]: '$THISYEAR',
  [DateOptionId.NEXTYEAR]: "$OFFSET(Y+1, '$THISYEAR')",
  [DateOptionId.LASTYEAR]: "$OFFSET(Y-1, '$THISYEAR')",
};

export const DateOptions: Record<string, TranslationListItem<string>> = {
  [DateOptionId.TODAY]: {
    id: DateOptionId.TODAY,
    translationID: 'dates.today',
    resourceID: 'JSS_GenTxtToday',
  },
  [DateOptionId.YESTERDAY]: {
    id: DateOptionId.YESTERDAY,
    translationID: 'dates.yesterday',
    resourceID: 'JSS_GenTxtYesterday',
  },
  [DateOptionId.TOMORROW]: {
    id: DateOptionId.TOMORROW,
    translationID: 'dates.tomorrow',
    resourceID: 'JSS_GenTxtTomorrow',
  },
  [DateOptionId.THISWEEK]: {
    id: DateOptionId.THISWEEK,
    translationID: 'dates.thisWeek',
    resourceID: 'JSS_GenTxtThisWeek',
  },
  [DateOptionId.LASTWEEK]: {
    id: DateOptionId.LASTWEEK,
    translationID: 'dates.lastWeek',
    resourceID: 'JSS_GenTxtLastWeek',
  },
  [DateOptionId.NEXTWEEK]: {
    id: DateOptionId.NEXTWEEK,
    translationID: 'dates.nextWeek',
    resourceID: 'JSS_GenTxtNextWeek',
  },
  [DateOptionId.THISMONTH]: {
    id: DateOptionId.THISMONTH,
    translationID: 'dates.thisMonth',
    resourceID: 'JSS_GenTxtThisMonth',
  },
  [DateOptionId.LASTMONTH]: {
    id: DateOptionId.LASTMONTH,
    translationID: 'dates.lastMonth',
    resourceID: 'JSS_GenTxtLastMonth',
  },
  [DateOptionId.THISANDLASTMONTH]: {
    id: DateOptionId.THISANDLASTMONTH,
    translationID: 'dates.thisAndLastMonth',
    resourceID: 'JSS_GenTxtThisAndLastMonth',
  },
  [DateOptionId.LASTTHREEMONTHS]: {
    id: DateOptionId.LASTTHREEMONTHS,
    translationID: 'dates.lastThreeMonths',
    resourceID: 'JSS_GenTxtLastThreeMonths',
  },
  [DateOptionId.LASTSIXMONTHS]: {
    id: DateOptionId.LASTSIXMONTHS,
    translationID: 'dates.lastSixMonths',
    resourceID: 'JSS_GenTxtLastSixMonths',
  },
  [DateOptionId.NEXTMONTH]: {
    id: DateOptionId.NEXTMONTH,
    translationID: 'dates.nextMonth',
    resourceID: 'JSS_GenTxtNextMonth',
  },
  [DateOptionId.THISFISCALQUARTER]: {
    id: DateOptionId.THISFISCALQUARTER,
    translationID: 'dates.thisFiscalQuarter',
    resourceID: 'JSS_GenTxtThisFiscalQuarter',
  },
  [DateOptionId.LASTFISCALQUARTER]: {
    id: DateOptionId.LASTFISCALQUARTER,
    translationID: 'dates.lastFiscalQuarter',
    resourceID: 'JSS_GenTxtLastFiscalQuarter',
  },
  [DateOptionId.NEXTFISCALQUARTER]: {
    id: DateOptionId.NEXTFISCALQUARTER,
    translationID: 'dates.nextFiscalQuarter',
    resourceID: 'JSS_GenTxtNextFiscalQuarter',
  },
  [DateOptionId.THISFISCALYEAR]: {
    id: DateOptionId.THISFISCALYEAR,
    translationID: 'dates.thisFiscalYear',
    resourceID: 'JSS_GenTxtThisFiscalYear',
  },
  [DateOptionId.LASTFISCALYEAR]: {
    id: DateOptionId.LASTFISCALYEAR,
    translationID: 'dates.lastFiscalYear',
    resourceID: 'JSS_GenTxtLastFiscalYear',
  },
  [DateOptionId.NEXTFISCALYEAR]: {
    id: DateOptionId.NEXTFISCALYEAR,
    translationID: 'dates.nextFiscalYear',
    resourceID: 'JSS_GenTxtNextFiscalYear',
  },
  [DateOptionId.THISYEAR]: {
    id: DateOptionId.THISYEAR,
    translationID: 'dates.thisYear',
    resourceID: 'JSS_GenTxtThisYear',
  },
  [DateOptionId.LASTYEAR]: {
    id: DateOptionId.LASTYEAR,
    translationID: 'dates.lastYear',
    resourceID: 'JSS_GenTxtLastYear',
  },
  [DateOptionId.NEXTYEAR]: {
    id: DateOptionId.NEXTYEAR,
    translationID: 'dates.nextYear',
    resourceID: 'JSS_GenTxtNextYear',
  },
  [DateOptionId.CUSTOM]: {
    id: DateOptionId.CUSTOM,
    translationID: 'dates.custom',
    resourceID: 'JSS_GenTxtCustom',
  },
};

export function getDateOptions(
  ids: DateOptionId[],
): TranslationListItem<string>[] {
  const options: TranslationListItem<string>[] = [];
  ids.forEach((id) => options.push(DateOptions[id]));
  return options;
}

export function getDateOffset(date: string): string | string[] {
  return DateOffset[date];
}

export function getDateRange(start: Date, end: Date): string[] {
  const startDate = formatISO(start, {
    representation: 'date',
  });
  const endDate = formatISO(end, {
    representation: 'date',
  });
  return [startDate, endDate];
}

export function getDateSelection(
  value: string,
  date?: DateRange,
): string | string[] {
  if (value === DateOptionId.CUSTOM && date?.start && date?.end) {
    return getDateRange(date.start, date.end);
  }
  return getDateOffset(value);
}
