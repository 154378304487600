import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Octopus, SalesTeam } from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import { ContextService } from './context.service';

@Injectable()
export class SalesTeamService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}
  private getRequest(key?: string): Octopus.SalesTeamReadRequest {
    const request: Octopus.SalesTeamReadRequest = {
      SalesTeam: {
        Scope: {
          Fields: {
            Key: 1,
            DisplayName: 1,
            MemberKeys: [
              {
                Key: 1,
                DisplayValue: 1,
                Role: 1,
                Right: 1,
              },
            ],
          },
        },
      },
    };

    if (key) {
      request.SalesTeam.Criteria = {
        SearchQuery: {
          Key: {
            $EQ: key,
          },
        },
      };
    } else {
      request.SalesTeam.Criteria = {
        SearchQuery: {
          Inactive: {
            $EQ: false,
          },
        },
      };
    }

    return request;
  }

  get(key?: string): Observable<SalesTeam | null> {
    const request = this.getRequest(key);

    return this.requestAndMap(request).pipe(
      map((result) => {
        if (result.length) {
          return result[0];
        }
        return null;
      }),
    );
  }

  getSalesTeam(): Observable<SalesTeam[]> {
    const request = this.getRequest();
    return this.requestAndMap(request);
  }

  private requestAndMap(
    request: Octopus.SalesTeamReadRequest,
  ): Observable<SalesTeam[]> {
    return this.http
      .post<Octopus.SalesTeamResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.SalesTeam?.Data
          ) {
            const mapper = new Octopus.SalesTeamMapper();
            return mapper.from(result.SalesTeam.Data);
          }

          return [];
        }),
      );
  }
}
