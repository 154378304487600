import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { OAuth2Service } from '@maximizer/core/shared/data-access';
import { GlobalServicesLoginDetails } from '@maximizer/core/shared/domain';
import { OutlookSyncService } from '@maximizer/outlook/shared/data-access';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'maximizer-outlook-sync-install',
  templateUrl: './install.component.html',
  standalone: false,
})
export class SyncInstallComponent implements OnInit {
  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Output() closeTooltips = new EventEmitter<void>();

  loginDetails?: GlobalServicesLoginDetails;

  constructor(
    private readonly outlookSyncService: OutlookSyncService,
    private readonly oAuthService: OAuth2Service,
  ) {}

  ngOnInit(): void {
    this.loginDetails = this.oAuthService.getStorageLoginDetails();
  }

  installOutlookSync(): void {
    if (!this.loginDetails) {
      return;
    }

    this.closeTooltips.emit();

    this.outlookSyncService
      .buildAppStoreFormRequest(this.loginDetails, true)
      .pipe(
        catchError(() => {
          this.notification.showMessage(
            'error',
            'outlook.errors.appStore',
            true,
          );
          return of(null);
        }),
      )
      .subscribe();
  }
}
