import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import {
  UiModule,
  defaultTranslateConfiguration,
} from '@maximizer/core/shared/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { OutlookAuthUiModule } from '@maximizer/outlook/auth/ui';
import { CoreSettingsDataAccessModule } from '@maximizer/core/settings/data-access';
import { OutlookSharedDataAccessModule } from '@maximizer/outlook/shared/data-access';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import { OutlookMenuComponent } from './menu/menu.component';
import { OutlookOnboardingUiModule } from '@maximizer/outlook/onboarding/ui';
import { OutlookSharedUiModule } from '@maximizer/outlook/shared/ui';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { OutlookSyncUiModule } from '@maximizer/outlook/sync/ui';

@NgModule({
  declarations: [OutlookMenuComponent],
  exports: [OutlookMenuComponent],
  imports: [
    CommonModule,
    IntlModule,
    LabelModule,
    ButtonsModule,
    DateInputsModule,
    FormFieldModule,
    ReactiveFormsModule,
    DropDownsModule,
    IconsModule,
    FormsModule,
    InputsModule,
    ProgressBarModule,
    IndicatorsModule,
    UiModule,
    CoreSettingsDataAccessModule,
    OutlookSharedDataAccessModule,
    OutlookSharedDomainModule,
    OutlookOnboardingUiModule,
    OutlookSharedUiModule,
    DialogsModule,
    TooltipsModule,
    OutlookSyncUiModule,
    OutlookAuthUiModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class OutlookMenuUiModule {}
