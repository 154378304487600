import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  computed,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SecurityContext,
  Signal,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContextService,
  FeatureService,
  GlobalStore,
  OAuth2Service,
  SessionService,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import {
  OnboardingStore,
  OutlookSyncService,
  OutlookStore,
  InitOnboardingAction,
  OutlookMsalService,
  OutlookService,
  OutlookSyncType,
  OutlookSyncStatus,
} from '@maximizer/outlook/shared/data-access';
import { TranslateService } from '@ngx-translate/core';
import {
  MenuItem,
  OutlookNotificationComponent,
  slideDownAnimation,
} from '@maximizer/outlook/shared/ui';
import { CelebrationComponent } from '@maximizer/outlook/onboarding/ui';
import { GlobalServicesLoginDetails } from '@maximizer/core/shared/domain';
import { catchError, forkJoin, map, of, takeWhile, tap } from 'rxjs';

enum MenuType {
  Refresh = 'refresh',
  Maximizer = 'maximizer',
  Settings = 'settings',
  Guide = 'guide',
  Logout = 'logout',
  AppVersion = 'appVersion',
  Database = 'database',
  SwitchAccount = 'switchAccount',
  EnableSync = 'enableSync',
  SyncStatus = 'syncStatus',
}

@Component({
  selector: 'maximizer-outlook-menu',
  templateUrl: './menu.component.html',
  animations: [slideDownAnimation],
  standalone: false,
})
export class OutlookMenuComponent implements OnInit {
  @ViewChild('celebration')
  celebration!: CelebrationComponent;
  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Input() pageTitle = '';
  @Input() pageType!: 'lead' | 'abentry' | 'none';
  @Input() id = '';
  @Input() showOnlyTitle = false;
  @Input() hideBack = false;
  @Input() hideRefresh = false;
  @Input() hideLogout = false;
  @Input() backRoutePath = '';
  @Input() forceWindowRefresh = false;

  @Output() exit = new EventEmitter<void>();
  @Output() initFirstOnboardingStep = new EventEmitter<InitOnboardingAction>();

  private readonly CACHE_STATUS_KEY = 'syncStatusCache';
  private readonly CACHE_STATUS_DURATION = 5 * 60 * 1000; // store status for 5 mins until the next syncs occur

  private readonly userGuideUrl =
    'https://support.maximizer.com/hc/en-us/articles/29776800842253-Microsoft-Outlook-Integration-Getting-Started-Guide-Maximizer-AI-CRM-app-for-Outlook';

  private readonly syncInstallMenuItem = {
    id: MenuType.EnableSync,
    text: this.translate.instant('outlook.menu.enableSync'),
    icon: 'fa-icons icon-14 icon-box-open',
    click: () => this.installOutlookSync(),
    disabled: false,
  };

  private readonly statusEnabledMenuItem = {
    id: MenuType.SyncStatus,
    text: this.translate.instant('outlook.menu.syncStatusEnabled'),
    icon: 'fa-icons icon-14 icon-solid icon-circle-check',
    class: 'sync-status-enabled justify-center',
    disabled: false,
  };

  private readonly statusErrorMenuItem = {
    id: MenuType.SyncStatus,
    text: this.translate.instant('outlook.menu.syncStatusError'),
    icon: 'fa-icons icon-14 icon-solid icon-circle-exclamation',
    class: 'sync-status-error justify-center',
    disabled: false,
  };

  menu: MenuItem[] = [];
  deepLink = '';
  outlookSyncInstalled = false;
  outlookSyncFeatureFlagEnabled = false;
  showRemindLater = false;
  startOnboarding = false;
  supportedOnboardingPage = true;
  mailboxAccount = this.outlookService.mailbox.userProfile.emailAddress;
  hideMsalMismatchDialog = false;
  hideMsalLoginDialog = false;
  openSyncSettings = false;
  loginDetails?: GlobalServicesLoginDetails;
  errorInSync = false;

  hasActiveAccount: Signal<boolean> = computed(() =>
    this.outlookStore.hasActiveAccount(),
  );

  activeAccountMatchEffect = effect(() => {
    if (!this.outlookStore.hasActiveAccount()) return;

    if (this.mailboxAccount === this.outlookStore.graphMailAccount()) {
      this.outlookStore.setDoesActiveAccountMatch(true);
    } else {
      this.outlookStore.setDoesActiveAccountMatch(false);
    }
  });

  constructor(
    private readonly contextService: ContextService,
    private readonly session: SessionService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly globalStore: GlobalStore,
    private readonly oAuthService: OAuth2Service,
    private readonly sessionStorage: SessionStorageService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly domSanitizer: DomSanitizer,
    private readonly outlookSyncService: OutlookSyncService,
    private readonly outlookService: OutlookService,
    private readonly msalService: OutlookMsalService,
    public onboardingStore: OnboardingStore,
    public feature: FeatureService,
    public outlookStore: OutlookStore,
  ) {
    const showOnboarding = this.feature.isFeatureOn(
      'integration-o365-plg-onboarding-shown',
      false,
    );
    this.onboardingStore.setOnboardingVisibility(showOnboarding);

    this.outlookSyncFeatureFlagEnabled = feature.isFeatureOn(
      'microsoft-outlook-sync-emails-shown',
      false,
    );
  }

  ngOnInit(): void {
    const currentPath = this.getCurrentPath();
    const isOutlookLegacy = this.outlookService.isOutlookLegacyDesktop();

    this.mailboxAccount = this.outlookService.mailbox.userProfile.emailAddress;
    this.loginDetails = this.oAuthService.getStorageLoginDetails();

    this.initializeMenuItems();
    this.getOutlookSyncSettings();

    if (currentPath === 'login' || this.outlookService.isCompose) {
      this.supportedOnboardingPage = false;
    }

    if (!(isOutlookLegacy && this.outlookService.isCompose)) {
      this.initializeMsalService();
    }

    if (this.contextService.token) {
      const session = this.globalStore.session();
      if (session?.alias) {
        this.populateDeeplink(session.alias);
      } else {
        this.session.getInfo().subscribe((data) => {
          this.populateDeeplink(data.alias);
        });
      }
    }
  }

  async initializeMsalService(): Promise<void> {
    this.hideMsalMismatchDialog =
      localStorage.getItem('hideMismatchMessage') === 'true';

    this.hideMsalLoginDialog =
      localStorage.getItem('hideLoginMessage') === 'true';

    await this.msalService.initializeMsal();

    this.msalService.checkAndSetActiveAccount();

    if (this.hideMsalLoginDialog && !this.hasActiveAccount()) {
      this.msalService.loginPopup();
    }
  }

  private initializeMenuItems(): void {
    if (this.showOnlyTitle) {
      this.hideBack = true;
      return;
    }

    this.menu = [
      {
        id: MenuType.Settings,
        text: this.translate.instant('outlook.menu.settings'),
        icon: 'fa-icons icon-14 icon-gear',
        click: () => (this.openSyncSettings = true),
        class: 'sync-settings',
        disabled: true,
      },
      {
        id: MenuType.Refresh,
        text: this.translate.instant('outlook.menu.refresh'),
        icon: 'fa-icons icon-solid icon-14 icon-refresh',
        click: () => this.refreshWindow(),
      },
      {
        id: MenuType.Maximizer,
        text: this.translate.instant('outlook.menu.maximizer'),
        icon: 'fa-icons icon-solid icon-14 icon-arrow-up-right-from-square',
        click: () => {
          if (!this.deepLink) this.deepLink = 'https://www.maximizer.com';
          window.open(this.deepLink, '_blank', 'noopener');
        },
      },
      {
        id: MenuType.Guide,
        text: this.translate.instant('outlook.menu.guide'),
        icon: 'fa-icons icon-14 icon-book',
        click: () => {
          window.open(this.userGuideUrl, '_blank', 'noopener');
        },
      },
      {
        id: MenuType.Logout,
        text: this.translate.instant('outlook.menu.logout'),
        icon: 'fa-icons icon-solid icon-14 icon-right-from-bracket',
        click: () => {
          this.logout();
        },
      },
      {
        id: MenuType.AppVersion,
        text: `${this.translate.instant('outlook.menu.appVersion')} ${this.contextService.version}`,
        class: 'app-version',
        disabled: true,
      },
      {
        id: MenuType.Database,
        text: `${this.translate.instant('outlook.menu.database')} ${this.contextService.alias}`,
        class: 'database',
        disabled: true,
      },
    ];

    this.menuItemSettings();
  }

  private menuItemSettings(): void {
    if (this.hideRefresh) {
      this.removeMenuItem(MenuType.Refresh);
    }

    if (this.hideLogout) {
      this.removeMenuItem(MenuType.Logout);
    }

    if (
      !this.outlookSyncFeatureFlagEnabled ||
      !this.outlookStore.versionValidForNewSync()
    ) {
      this.removeMenuItem(MenuType.Settings);
    }
  }

  private addMenuItem(menuItem: MenuItem): void {
    if (!this.menu.some((item) => item.id === menuItem.id)) {
      this.menu.unshift(menuItem);
    }
  }

  private toggleDisabledMenuItem(menuId: MenuType, disabled: boolean): void {
    const menuItem = this.menu.find((item) => item.id === menuId);
    if (menuItem) {
      menuItem.disabled = disabled;
    }
  }

  private removeMenuItem(menuItem: MenuType): void {
    this.menu = [...this.menu.filter((item) => item.id !== menuItem)];
  }

  private getCurrentPath(): string | undefined {
    let route = this.route.snapshot;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.routeConfig?.path;
  }

  getOutlookSyncSettings(): void {
    const session = this.globalStore.session();

    if (
      session &&
      this.outlookSyncFeatureFlagEnabled &&
      this.outlookStore.versionValidForNewSync()
    ) {
      this.outlookSyncService
        .getConfiguration(session.user.id, session.workspace)
        .subscribe((config) => {
          if (!config) {
            this.addMenuItem(this.syncInstallMenuItem);
            return;
          }

          this.outlookSyncInstalled = !!config?.enabled;
          this.outlookStore.updateSyncConfiguration(config);

          if (this.outlookSyncInstalled) {
            this.checkSyncStatus();
            this.toggleDisabledMenuItem(MenuType.Settings, false);
          } else {
            if (!this.loginDetails) {
              return;
            }
            this.addMenuItem(this.syncInstallMenuItem);
          }
        });
    }
  }

  private checkSyncStatus(): void {
    const syncConfiguration = this.outlookStore.syncConfiguration();
    const cachedData = this.getCachedSyncStatus();

    if (cachedData) {
      this.applySyncStatus(cachedData);
      return;
    }

    if (syncConfiguration) {
      const syncTypes = [
        OutlookSyncType.Contacts,
        OutlookSyncType.Appointments,
        OutlookSyncType.Tasks,
        OutlookSyncType.Emails,
      ];

      forkJoin(
        syncTypes.map((type) =>
          this.outlookSyncService
            .getHistoryBySyncType(type, syncConfiguration.id, 1)
            .pipe(catchError(() => of(undefined))),
        ),
      )
        .pipe(
          map((histories) =>
            histories.filter((history) => history !== undefined).flat(),
          ),
          takeWhile(
            (histories) =>
              !histories.some(
                (history) =>
                  history && history.syncStatus === OutlookSyncStatus.Problem,
              ),
            true,
          ),
          tap((histories) => {
            const errorInSync = histories.some(
              (history) =>
                history && history.syncStatus === OutlookSyncStatus.Problem,
            );
            const syncData = { errorInSync, timestamp: Date.now() };

            this.setCachedSyncStatus(syncData);
            this.applySyncStatus(syncData);
          }),
        )
        .subscribe();
    }
  }

  private getCachedSyncStatus(): {
    errorInSync: boolean;
    timestamp: number;
  } | null {
    const data = sessionStorage.getItem(this.CACHE_STATUS_KEY);
    if (!data) return null;

    const parsed = JSON.parse(data);
    const currentTime = Date.now();
    const timeDifference = currentTime - parsed.timestamp;
    const isExpired = timeDifference >= this.CACHE_STATUS_DURATION;

    if (isExpired) {
      sessionStorage.removeItem(this.CACHE_STATUS_KEY);
      return null;
    }

    return parsed;
  }

  private setCachedSyncStatus(syncData: {
    errorInSync: boolean;
    timestamp: number;
  }): void {
    sessionStorage.setItem(this.CACHE_STATUS_KEY, JSON.stringify(syncData));
  }

  private applySyncStatus(syncData: { errorInSync: boolean }): void {
    this.errorInSync = syncData.errorInSync;
    if (syncData.errorInSync) {
      this.addMenuItem(this.statusErrorMenuItem);
    } else {
      this.addMenuItem(this.statusEnabledMenuItem);
    }
  }

  private populateDeeplink(alias: string): void {
    const urlArray = this.contextService.website.split('/');
    const domainIndex = urlArray.findIndex((o) =>
      o.toLocaleLowerCase().includes('maximizer'),
    );
    if (domainIndex == -1) return;
    let domain = '';
    for (let i = 0; i <= domainIndex; i++) {
      domain += urlArray[i] + '/';
    }
    const accountLink = domain?.replace('1', 'w') + alias;
    switch (this.pageType) {
      case 'lead':
        this.deepLink = `${accountLink}?sslead=KEY(${this.id})`;
        break;
      case 'abentry':
        this.deepLink = `${accountLink}?ss=KEY(${this.id})`;
        break;
      default:
        this.deepLink = accountLink;
        break;
    }
  }

  private isRouteValid(path: string): boolean {
    const configuredPaths = this.router.config.map((route) => `/${route.path}`);
    return configuredPaths.includes(path);
  }

  goBack(): void {
    if (this.backRoutePath) {
      if (this.isRouteValid(this.backRoutePath)) {
        this.router.navigate([this.backRoutePath]);
      } else {
        this.router.navigate(['/home']);
      }
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  logout(): void {
    this.oAuthService.clearAuth();
    this.session.clearSessionCache();
    this.contextService.clearAuthentication();
    this.sessionStorage.clear();
    window.localStorage.clear();

    const loginPath = '/login';
    const destinationUrl = window.location.origin + loginPath;

    if (destinationUrl.startsWith(window.location.origin)) {
      window.location.href = destinationUrl;
    }
  }

  private refreshWindow(): void {
    if (this.forceWindowRefresh) {
      const destinationUrl = `${window.location.origin}${window.location.pathname}`;
      window.location.href = destinationUrl;
      return;
    }

    const path = this.router.url.split('?')[0];
    const queryParamStr = this.router.url.split('?')[1];
    const queryParams = queryParamStr ? queryParamStr.split('&') : [];

    const url = self
      ? decodeURIComponent(this.router.url)
      : decodeURIComponent(this.location.path());
    const refreshUrl = url === '/' ? '../' : '/';

    this.router
      .navigateByUrl(refreshUrl, { skipLocationChange: false })
      .then(() => {
        const pathDecomposed = decodeURIComponent(path);
        const sanitizedQueryParams = this.sanitizeQueryParams(queryParams);
        const record = Object.fromEntries(
          sanitizedQueryParams.map((item) => {
            const [key, value] = item.split('=');
            return [key, decodeURIComponent(value)];
          }),
        ) as Record<string, string>;
        this.router.navigate([pathDecomposed], { queryParams: record });
      });
  }

  private sanitizeQueryParams(queryParams: string[]): string[] {
    return queryParams.map((param) => {
      const [key, value] = param.split('=');
      const sanitizedKey =
        this.domSanitizer.sanitize(SecurityContext.URL, key) || '';
      const sanitizedValue =
        this.domSanitizer.sanitize(SecurityContext.URL, value) || '';
      return `${sanitizedKey}=${sanitizedValue}`;
    });
  }

  installOutlookSync(): void {
    if (!this.loginDetails) {
      return;
    }

    this.outlookSyncService
      .buildAppStoreFormRequest(this.loginDetails, true)
      .pipe(
        catchError(() => {
          this.notification.showMessage(
            'error',
            'outlook.errors.appStore',
            true,
          );
          return of(null);
        }),
      )
      .subscribe();
  }

  handleOnboardingAction(action: InitOnboardingAction): void {
    this.startOnboarding = false;

    if (action === 'start') {
      this.onboardingStore.setOnboardingInProgress(true);

      const currentPath = this.getCurrentPath();

      if (currentPath !== 'read-email') {
        this.router.navigate(['/home']);
      } else {
        this.initFirstOnboardingStep.emit('start');
      }
    } else {
      this.showRemindLater = true;
      this.onboardingStore.setOnboardingInProgress(false);
    }
  }
}
