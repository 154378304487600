@if (event) {
  <div
    class="flex min-h-[40px] cursor-pointer w-full"
    (click)="toggleExpand()"
    [class]="expanded ? 'max-h-100' : 'max-h-[95px]'"
  >
    <div class="flex mt-3 ml-2">
      @if (!expanded) {
        <span
          class="fa-icons icon-solid icon-angle-down icon-12 text-neutral-70"
        ></span>
      } @else {
        <span
          class="fa-icons icon-solid icon-angle-up icon-12 text-neutral-70"
        ></span>
      }
      <span
        class="ml-2 text-neutral-70 icon-14"
        [ngClass]="
          event.interactionType
            ? (event.interactionType | interactionIcon)
            : undefined
        "
      ></span>
    </div>

    <div class="flex flex-col p-2 text-xs w-full max-w-[calc(100%-2rem)]">
      <div class="flex justify-between">
        <span class="font-bold my-auto line-clamp-2 whitespace-pre-wrap">{{
          'outlook.timeline.eventFor'
            | translate
              : {
                  type: event.interactionType?.display,
                  entry: event.entriesDisplay,
                  for: getTitleConnection(event),
                }
        }}</span>
        @if (event.type.includes('note') && event.important) {
          <span
            class="fa-icons icon-solid icon-18 icon-exclamation pl-1 text-error"
          ></span>
        }
      </div>
      <div class="flex">
        <span class="font-bold my-auto line-clamp-2 whitespace-pre-wrap"
          >{{ 'outlook.timeline.createdBy' | translate }}
          {{ event.creator.display }}
        </span>
      </div>

      <div class="flex text-neutral-70">
        <span class="">{{
          event.date.value | date: 'MMMM dd, yyyy hh:mm a'
        }}</span>
      </div>

      @if (event.subject) {
        <div class="flex text-neutral-70 overflow-hidden text-ellipsis">
          <span class="whitespace-pre-wrap">{{ event.subject }}</span>
        </div>
      } @else {
        <div class="flex text-neutral-70 overflow-hidden text-ellipsis">
          <span class="whitespace-pre-wrap">{{ event.description }}</span>
        </div>
      }
    </div>
  </div>
}
