import { Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Subscription } from 'rxjs';

@Component({
  selector: 'maximizer-disposable-component',
  template: ``,
  standalone: false,
})
export class DisposableComponent implements OnDestroy {
  subscriptions: Subscription[] = [];

  @ViewChildren(TooltipDirective)
  public tooltips?: QueryList<TooltipDirective>;

  closeTooltips(): void {
    this.tooltips?.forEach((tooltip) => tooltip.hide());
  }

  addSubscription(subscription?: Subscription): void {
    if (subscription) {
      this.subscriptions.push(subscription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
