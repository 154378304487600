import { Injectable } from '@angular/core';
import {
  TrackEventData,
  DefaultActionValue,
  DefaultJourneyValue,
  SystemEventData,
} from '../models/track-event.model';

declare global {
  interface Window {
    dataLayer?: Array<TrackEventData>;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TrackEventService {
  trackEventData: TrackEventData = {
    event: '',
    journey: DefaultJourneyValue,
    action: DefaultActionValue,
  };

  trackEvent(data: TrackEventData): void {
    const dataValue = this.getTrackEventData(data);
    if (dataValue) {
      this.setDataLayer(dataValue);
    }
  }

  trackSystemEvent(data: SystemEventData): void {
    this.setDataLayer(data);
  }

  private getTrackEventData(data: TrackEventData): TrackEventData | null {
    const eventData = { ...this.trackEventData, ...data };
    if (!eventData.event) {
      eventData.event = [eventData.module, eventData.journey, eventData.action]
        .filter(Boolean)
        .join('_');
    }
    return eventData;
  }

  private setDataLayer(data: TrackEventData | SystemEventData): void {
    const parent = top || window;
    parent.dataLayer = parent.dataLayer || [];
    parent.dataLayer.push({ ...data });
  }
}
