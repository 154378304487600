import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbEntryService,
  LeadService,
} from '@maximizer/outlook/shared/data-access';
import {
  OutlookLeadSearch,
  OutlookAbEntryDetails,
} from '@maximizer/outlook/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { catchError, finalize } from 'rxjs';

@Component({
  selector: 'maximizer-entry-details',
  templateUrl: './entry-details.component.html',
  standalone: false,
})
export class EntryDetailsComponent implements OnInit {
  @Input() type?: string;
  private _id = '';

  @Input()
  set id(id: string) {
    this._id = decodeURI(id);
  }
  get id(): string {
    return this._id;
  }

  isCompany = false;
  abentry?: OutlookAbEntryDetails;
  lead?: OutlookLeadSearch;
  loading = true;

  @ViewChild('notification', { static: true })
  notification!: OutlookNotificationComponent;

  constructor(
    private readonly abentryService: AbEntryService,
    private readonly leadService: LeadService,
  ) {}

  ngOnInit(): void {
    if (this.type === 'lead') {
      this.leadService
        .getByKey(this.id)
        .pipe(
          catchError(() => {
            this.notification.show('load', 'error');
            return [];
          }),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe((data) => {
          if (data) {
            this.lead = data;
          }
        });
    }

    if (this.type === 'abentry') {
      this.abentryService
        .getAllDetailsByKey(this.id)
        .pipe(
          catchError(() => {
            this.notification.show('load', 'error');
            return [];
          }),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe((data) => {
          if (data) {
            if (data.type === 'Company') this.isCompany = true;
            this.abentry = data;
          }
        });
    }
  }

  getAddress(): string {
    const entry = this.abentry || this.lead;
    if (entry?.address) {
      const address = entry.address;
      return `${address.address1} ${address.address2}\n${address.city} ${address.provinceState} ${address.zipcode}\n${address.country}`.trim();
    }
    return '';
  }
}
