import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { EmbeddedComponent } from '@maximizer/core/shared/ui';

import { TaskService } from '@maximizer/outlook/task/data-access';
import { TaskSummary } from '@maximizer/outlook/shared/domain';
import { catchError, finalize } from 'rxjs';
import { CDN_URL } from '@maximizer/core/shared/domain';
import { UserService } from '@maximizer/core/shared/data-access';

@Component({
  selector: 'maximizer-summary-task',
  templateUrl: './summary-task.component.html',
  standalone: false,
})
export class SummaryTaskComponent extends EmbeddedComponent implements OnInit {
  @Output() loadErrorEvent: EventEmitter<string> = new EventEmitter();
  @Output() loadingEvent: EventEmitter<boolean> = new EventEmitter();

  loading = true;

  @Input() deepLink?: string;
  @Input() type?: string;
  @Input() id?: string;

  configuration: TaskSummary;

  constructor(
    @Inject(CDN_URL) public cdn: string,
    element: ElementRef,
    private readonly taskService: TaskService,
    private readonly userService: UserService,
  ) {
    super(element);
    this.configuration = {
      overdueTaskAmount: 0,
      upcomingTaskAmount: 0,
    };
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    if(!this.id || !this.type) return; 
    this.taskService
      .getSummary(this.id, this.type)
      .pipe(
        catchError(async () => this.loadErrorEvent.emit('task')),
        finalize(() => {
          this.loadingEvent.emit(false);
          this.loading = false;
        }),
      )
      .subscribe((data) => {
        if (!data) return;

        if (data.overdueTaskAmount > 0) {
          data.overdueTaskAmount = data.overdueTaskAmount - 1;
        }
        if (data.upcomingTaskAmount > 0) {
          data.upcomingTaskAmount = data.upcomingTaskAmount - 1;
        }
        this.configuration = data;
        this.getEmail(this.configuration.overdue?.assignedTo.Key, true);
      });
  }

  getEmail(key: string | undefined, isOverdue: boolean) {
    if(!key) return;
    
    if(isOverdue && this.configuration.upcomingUser?.key == key){
      this.configuration.overdueUser = this.configuration.upcomingUser;
      return;
    } 
    if(!isOverdue && this.configuration.overdueUser?.key == key){      
      this.configuration.upcomingUser = this.configuration.overdueUser;
      return;
    }

    this.userService
    .getByKey(key)
    .pipe(
      catchError(async () => this.loadErrorEvent.emit('task')),
      finalize(() => {
        this.loadingEvent.emit(false);
        this.loading = false;
      }),
    )
    .subscribe((data) => {
      if (!data) return;
      if(isOverdue){
        this.configuration.overdueUser = {
          email: data.email1.email,
          key: key
        };
      } else { 
        this.configuration.upcomingUser = {
          email: data.email1.email,
          key: key
        };
      }      
    });
  }

}
