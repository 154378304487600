<p class="mt-4 pb-2 font-bold text-xs">
  {{ 'outlook.summary.appointment.title' | translate }}
</p>

@if (loading) {
  <maximizer-outlook-skeleton
    containerClasses="h-[120px] w-full rounded-md bg-neutral-25"
  ></maximizer-outlook-skeleton>
}

@if (!loading && !configuration.appointment) {
  <p class="text-body pb-8">
    {{ 'outlook.summary.appointment.emptyState' | translate }}
  </p>
}

@if (!loading && configuration.appointment) {
  <div class="text-xs space-y-2 mt-2">
    <div
      class="bg-white min-h-[120px] max-h-[150px] w-100 rounded shadow-card flex flex-col p-2 pl-2 mb-2 justify-around"
    >
      <div class="flex flex-row">
        <em
          class="mr-1 my-auto fa-icons icon-solid icon-12 icon-calendar text-neutral-70"
        ></em>
        <span
          class="font-bold my-auto text-ellipsis overflow-hidden whitespace-nowrap"
        >
          {{ configuration.appointment.subject }}</span
        >
      </div>

      <div class="flex flex-row">
        <span
          class="text-neutral-70 mr-1 text-ellipsis overflow-hidden whitespace-nowrap"
          >{{ 'outlook.summary.appointment.location' | translate }}:
        </span>
        <span>{{ configuration.appointment.location?.DisplayValue }}</span>
      </div>

      <div class="flex flex-row">
        <span class="text-neutral-70 mr-1"
          >{{ 'outlook.summary.appointment.time' | translate }}:
        </span>
        <span>{{
          configuration.appointment.startDate | date: 'MMMM dd, yyyy hh:mm a'
        }}</span>
      </div>
      <p
        class="text-neutral-70 text-ellipsis overflow-hidden whitespace-nowrap"
      >
        {{ configuration.appointment.description }}
      </p>

      <div class="flex flex-row">
        <span class="ml-auto">{{
          configuration.appointment.creationDate | date: 'MMMM dd, yyyy hh:mm a'
        }}</span>
      </div>
    </div>
    <a
      id="outlook-appointment-ui-summaryAppointment-more"
      class="mb-2 text-inserv-100 line-clamp-3 underline hover:cursor-pointer"
      [href]="deepLink"
      target="_blank"
      >{{ configuration.appointmentAmount }}
      {{ 'outlook.summary.appointment.amount' | translate }}</a
    >
  </div>
}
