import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

type OutlookState = {
  inMaximizerIds: string[];
  versionValidForNewSync: boolean;
};

const initialState: OutlookState = {
  inMaximizerIds: [],
  versionValidForNewSync: false,
};

export const OutlookStore = signalStore(
  withDevtools('outlook'),
  withState({
    ...initialState,
    inMaximizerIds: JSON.parse(localStorage.getItem('inMaximizerIds') || '[]'),
  }),
  withMethods((store) => ({
    updateInMaximizerIdList(inMaximizerIds: string[]) {
      patchState(store, () => ({ inMaximizerIds }));
      localStorage.setItem('inMaximizerIds', JSON.stringify(inMaximizerIds));
    },
    updateVersion(valid: boolean) {
      patchState(store, () => ({ versionValidForNewSync: valid }));
    },
  })),
);

export type OutlookStore = InstanceType<typeof OutlookStore>;
