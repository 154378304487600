import { FavoriteList } from '../../../models/favorite-list';
import { FavoriteList as OctopusFavoriteList } from '../models/favorite-list';
import { EntityMapper } from './entity';

export class FavoriteListMapper extends EntityMapper<
  OctopusFavoriteList,
  FavoriteList
> {
  from(source: OctopusFavoriteList): FavoriteList {
    return {
      key: source.Key,
      name: source.Name,
      entityType: source.EntityType,
      description: source.Description,
      creator: source.Creator,
      creationDate: source.CreationDate,
    };
  }
}
