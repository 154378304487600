/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  DialogResult,
  DialogService,
  DialogSettings,
} from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';

@Injectable()
export class KendoService {
  constructor(private readonly dialog: DialogService) {}

  showDialog(settings: DialogSettings): Observable<DialogResult> {
    const body = top ? top.document.body : document.body;
    const container = body.querySelector<any>('kendo-angular-container');

    if (container?.subscribeToAction) {
      return container.subscribeToAction('showDialog', settings);
    }

    return this.dialog.open(settings).result;
  }
}
