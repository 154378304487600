import {
  ReadRequest,
  Request,
  RequestConfiguration,
  WriteRequest,
} from './request';
import { DataResponse, Response, WriteResponse } from './response';
import { Permissions } from './permissions';
import { UserKey } from './key';
import { Phone } from './phone';
import { Email } from './email';
import { Address } from './address';

export const CompanyUserKey = 'VXNlcglDT01QQU5Z';

export interface User {
  Key: UserKey;
  Phone1: Phone;
  Phone2: Phone;
  Phone3: Phone;
  Phone4: Phone;
  Email1: Email;
  Email2: Email;
  Email3: Email;
  DisplayName: string;
  MrMs: string;
  Initial: string;
  FirstName: string;
  LastName: string;
  CompanyName: string;
  Position: string;
  Department: string;
  Division: string;
  WebSite: string;
  Address: Address;
  DisplayValue: string;
  Roles: UserRoles;
  Permissions: UserPermissions;
  Privileges: UserPrivileges;
  Enabled: boolean;
  Disabled: number;
  AccessType: number[];
  Password: string;
  CreationDate: string;
  LastModifyDate: string;
  LastLoginDate: string;
}

export interface UserRoles {
  Administrator: boolean;
  SalesManager: boolean;
  SalesRepresentative: boolean;
  CustomerServiceManager: boolean;
  CustomerServiceRepresentative: boolean;
}

export interface UserPermissions {
  AccountSetup: Permissions;
  AddressBook: Permissions;
  Quota: Permissions;
  Opportunity: Permissions;
  UserDefinedFieldSetup: Permissions;
  UserDefinedFields: Permissions;
  InteractionLog: Permissions;
  Notes: Permissions;
  Documents: Permissions;
  CustomerService: Permissions;
  Lead: Permissions;
}

export interface UserPrivileges {
  Export: boolean;
  Import: boolean;
  GlobalEdit: boolean;
  PrivateEntries: boolean;
  ModifyKeyFields: boolean;
  ModifySystemTables: boolean;
  BypassEntryPermissions: boolean;
}

export interface UserReadRequest extends Request {
  User: ReadRequest<User>;
}

export interface UserUpdateRequest extends Request {
  User: WriteRequest<User>;
}

export interface UserReadResponse extends Response {
  User: DataResponse<User>;
}

export interface UserUpdateResponse extends Response {
  User: WriteResponse<User>;
}

export const UserReadDriver: RequestConfiguration = {
  Drivers: {
    IUserSearcher: 'Maximizer.Model.Access.Sql.UserSearcher',
  },
};

export const UserWriteDriver: RequestConfiguration = {
  Drivers: {
    IUserAccess: 'Maximizer.Model.Access.Sql.UserAccess',
  },
};
