import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CDN_URL } from '@maximizer/core/shared/domain';
import {
  InitOnboardingAction,
  InitOnboardingDialog,
  OnboardingStepNumber,
  OnboardingStore,
} from '@maximizer/outlook/shared/data-access';

@Component({
  selector: 'maximizer-outlook-onboarding-celebration',
  template: ` <kendo-dialog [width]="272" [height]="404">
    <button
      kendoButton
      fillMode="flat"
      iconClass="fa-icons icon-xmark icon-16"
      class="absolute top-1 right-1"
      [attr.aria-label]="'outlook.buttons.close' | translate"
      (click)="this.action.emit('close')"
    ></button>
    <div
      class="confetti pt-23 bg-top bg-contain bg-no-repeat"
      [ngStyle]="{
        'background-image': 'url(' + confettiBackground + ')',
      }"
    >
      <div class="px-8 flex flex-col gap-3">
        <p
          class="subtitle-1"
          [translate]="'outlook.onboarding.' + step + '.title'"
        ></p>
        <p
          class="text-caption"
          [translate]="'outlook.onboarding.' + step + '.message1'"
        ></p>
        <p
          class="text-caption"
          [innerHTML]="'outlook.onboarding.' + step + '.message2' | translate"
        ></p>

        @if (step === 'start') {
          <p
            class="text-caption"
            [innerHTML]="'outlook.onboarding.start.message3' | translate"
          ></p>
          <div class="flex flex-col gap-2">
            <button
              kendoButton
              id="outlook-onboarding-ui-celebration-start"
              themeColor="primary"
              (click)="start()"
            >
              {{ 'outlook.buttons.start' | translate }}
            </button>
            <button
              kendoButton
              id="outlook-onboarding-ui-celebration-remindLater"
              themeColor="primary"
              fillMode="flat"
              (click)="this.action.emit('close')"
            >
              {{ 'outlook.buttons.remindLater' | translate }}
            </button>
          </div>
        } @else {
          <button
            kendoButton
            id="outlook-onboarding-ui-celebration-done"
            class="mt-32"
            themeColor="primary"
            (click)="this.action.emit('close')"
          >
            {{ 'outlook.buttons.done' | translate }}
          </button>
        }
      </div>
    </div>
  </kendo-dialog>`,
  styles: `
    a {
      color: blue;
    }
  `,
  standalone: false,
})
export class CelebrationComponent {
  @Input() step: InitOnboardingDialog = 'start';
  @Output() action = new EventEmitter<InitOnboardingAction>();

  readonly userGuideUrl =
    'https://support.maximizer.com/hc/en-us/articles/29776800842253-Microsoft-Outlook-Integration-Getting-Started-Guide-Maximizer-AI-CRM-app-for-Outlook';

  constructor(
    @Inject(CDN_URL) public readonly cdn: string,
    public onboardingStore: OnboardingStore,
  ) {}

  start(): void {
    this.onboardingStore.setCurrentStep(OnboardingStepNumber.Step1);
    this.action.emit('start');
  }

  get confettiBackground() {
    return `${this.cdn}/assets/images/outlook/welcome_confetti_50.png`;
  }
}
