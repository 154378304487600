import { ComparisonOperator, LogicalQuery } from './criteria';
import { EntityType } from './entity-type';
import { FieldType } from './field-type';
import { IdKey, Key, UserKey } from './key';
import { KeyAndDescription } from './key-and-description';
import { ReadRequest, Request, WriteRequest } from './request';
import { DataResponse, Response, WriteResponse } from './response';
import { SecurityAccess } from './security-access';
import { SecurityStatus } from './security-status';

export const MetadataRootFolderKey = 'VWRmU2V0dXAJMA==';

export interface Metadata {
  Key: Key;
  Name: string;
  Description: string;
  Alias: string[];
  AppliesTo: string[];
  Type: FieldType;
  Assignable: boolean;
  Inactive: boolean;
  Sortable: boolean;
  Queryable?: ComparisonOperator[];
  HasOption: boolean;
  ReadOnly: boolean;
  Nullable: boolean;
  Attributes?: StringAttributes &
    NumberAttributes &
    CurrencyAttributes &
    DateAttributes &
    SelectorAttributes &
    DurationAttributes &
    BooleanAttributes;
  Folder?: string;
  Path?: string;
  Mandatory?: string;
  Formula?: string;
  SortValue: string;
}

export interface Folder {
  Key: string;
  Name: string;
  Path: string;
  SortValue: string;
  ParentFolderKey: string;
  AppliesTo: string[];
}

type StringAttributes = {
  MaxLength?: number;
  Encrypted?: boolean;
  Url?: boolean;
};

type NumberAttributes = {
  MinValue?: number;
  MaxValue?: number;
};

type CurrencyAttributes = {
  DecimalPlaces?: number;
  CorporateCode?: string;
};

type DateAttributes = {
  Date?: boolean;
  Time?: boolean;
  IgnoreYear?: boolean;
};

type SelectorAttributes = {
  MultiSelect?: boolean;
  YesNo?: boolean;
};

type DurationAttributes = {
  Precission?: string;
  Anchor?: string;
  Rounding?: string;
  Format?: string;
};

type BooleanAttributes = {
  True?: {
    DisplayValue: string;
  };
  False?: {
    DisplayValue: string;
  };
};

export interface KeyFieldList extends KeyAndDescription {
  Name: string;
  RequestedBy: UserKey;
  SecAccess: SecurityAccess;
  SecStatus: SecurityStatus;
  Target: string | null;
}

export interface KeyFieldDefinition {
  Key: IdKey<number> | string;
  Name: string;
  Layout: KeyFieldGroup[];
  ParentKey: string;
  EntityType: EntityType;
  Description: string;
  Rule?: LogicalQuery<{ Type: string; Lead: boolean }>;
  Target: string | null;
}

export interface KeyFieldGroup {
  Name?: string;
  ColumnNo: number;
  Element?: KeyFieldElement[];
}

export interface KeyFieldElement {
  Id: string;
  Field: Metadata;
}

export interface KeyFieldDefinitionRequest extends Request {
  KeyFieldDefinition: ReadRequest<KeyFieldDefinition> & {
    Options?: {
      IncludeSeparator?: boolean;
    };
  };
}

export interface KeyFieldListRequest extends Request {
  KeyFieldList: ReadRequest<KeyFieldList>;
}

export interface SchemaFolderRequest extends Request {
  SchemaFolder: ReadRequest<Folder>;
}

export interface MetadataRequest extends Request {
  $Metadata: ReadRequest<Metadata>;
}

export type CatalogRequest = MetadataRequest &
  Partial<SchemaFolderRequest> &
  Partial<KeyFieldListRequest> &
  Partial<KeyFieldDefinitionRequest>;

export interface MetadataResponse extends Response {
  $Metadata: DataResponse<Metadata>;
}

export interface SchemaFolderResponse extends Response {
  SchemaFolder: DataResponse<Folder>;
}

export interface KeyFieldListWriteRequest extends Request {
  KeyFieldList: WriteRequest<KeyFieldList>;
}

export interface KeyFieldListWriteResponse extends Response {
  KeyFieldList: WriteResponse<KeyFieldList>;
}

export interface KeyFieldListResponse extends Response {
  KeyFieldList: DataResponse<KeyFieldList>;
}

export interface KeyFieldDefinitionWriteRequest extends Request {
  KeyFieldDefinition: WriteRequest<KeyFieldDefinition>;
}

export interface KeyFieldDefinitionWriteResponse extends Response {
  KeyFieldDefinition: WriteResponse<KeyFieldDefinition>;
}

export interface KeyFieldDefinitionResponse extends Response {
  KeyFieldDefinition: DataResponse<KeyFieldDefinition>;
}

export type CatalogResponse = MetadataResponse &
  SchemaFolderResponse &
  Partial<KeyFieldListResponse> &
  Partial<KeyFieldDefinitionResponse>;
