import { Injectable } from '@angular/core';
import {
  ContextService,
  FeatureService,
  GlobalStore,
  OAuth2Service,
  SessionService,
  SystemService,
} from '@maximizer/core/shared/data-access';
import { catchError, lastValueFrom, map, of, retry, tap, timeout } from 'rxjs';
import { InsightsService } from '@maximizer/core/shared/insights';
import {
  InsightsLogDetails,
  OnboardingStore,
  OutlookInsightsEvents,
  OutlookStore,
  logCustomEventDetailsToInsights,
} from '@maximizer/outlook/shared/data-access';

@Injectable({
  providedIn: 'root',
})
export class OutlookAuthService {
  private get officeEmail(): string {
    return (
      Office.context.mailbox?.userProfile?.emailAddress?.toLocaleLowerCase() ??
      'mx_default'
    );
  }

  private get isTokenExpired(): boolean {
    const expiresAt = this.oauth2Service.oAuth2Token?.expires_at;
    return !!expiresAt && expiresAt < new Date();
  }

  constructor(
    private readonly contextService: ContextService,
    private readonly globalStore: GlobalStore,
    private readonly oauth2Service: OAuth2Service,
    private readonly insightsService: InsightsService,
    private readonly session: SessionService,
    private readonly onboardingStore: OnboardingStore,
    private readonly feature: FeatureService,
    private readonly outlookStore: OutlookStore,
    private readonly systemService: SystemService,
  ) {}

  async initialize() {
    try {
      this.setOAuth2ServiceKeys(this.officeEmail);

      if (
        !this.oauth2Service.requiredPropertiesPopulated &&
        (!this.oauth2Service.populateFromStorage() || this.isTokenExpired)
      ) {
        return this.refreshTokenOrHandleError();
      }

      await this.populateContext();
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.insightsService.trackException({ error });
        this.logAuthDetailsToInsights('Auth-Initialize', error.message);
      } else {
        this.logAuthDetailsToInsights(
          'Auth-Initialize',
          `An unknown error occurred`,
        );
      }
    }
  }

  private setOAuth2ServiceKeys(officeEmail: string): void {
    const partitionKey = Office.context.partitionKey ?? officeEmail;
    this.oauth2Service.storagePartitionKey = partitionKey;
    this.oauth2Service.encryptPassword = officeEmail;
  }

  private refreshTokenOrHandleError(): void {
    this.oauth2Service
      .refreshToken()
      .pipe(
        timeout(15000),
        catchError(async (error) => {
          this.insightsService.trackException({ error });
          this.logAuthDetailsToInsights('Refresh-Token', error.message);
          return of(undefined);
        }),
      )
      .subscribe(async (result) => {
        if (result) {
          await this.populateContext();
        } else {
          this.oauth2Service.clearAuth();
          this.session.clearSessionCache();
        }
      });
  }

  private async populateContext() {
    if (
      !this.oauth2Service.requiredPropertiesPopulated ||
      !this.oauth2Service.mxUserEmail
    ) {
      return;
    }

    this.contextService.token =
      this.oauth2Service.oAuth2Token?.access_token ?? '';
    const loginDetailsList = await lastValueFrom(
      this.oauth2Service.getLoginDetails(this.oauth2Service.mxUserEmail),
    );

    if (!this.oauth2Service.requiredPropertiesPopulated || !loginDetailsList) {
      return;
    }

    const loginDetails = loginDetailsList.find(
      (o) => o.tenant.id === this.oauth2Service.loginDetails?.tenant.id,
    );
    if (!loginDetails) {
      return;
    }

    this.oauth2Service.populateContextOAuthData();
    this.oauth2Service.setStorageLoginDetails(loginDetails);
    this.checkSystemInfoForSyncSupport();

    await lastValueFrom(this.getSessionAndStartAppInsights());
  }

  private getSessionAndStartAppInsights() {
    return this.session.getInfo().pipe(
      timeout(15000),
      retry({ count: 2, delay: 2000 }),
      catchError(async (error) => {
        this.insightsService.trackException({ error });
        this.logAuthDetailsToInsights('Get-Session-Info', error.message);
        return undefined;
      }),
      tap((result) => {
        if (!result) {
          this.logAuthDetailsToInsights(
            'Get-Session-Return',
            'No session return result',
          );
          return;
        }
        this.oauth2Service.mxUserId = result.user.id;
        this.globalStore.setSession(result);

        this.initializeInsightsService();
        this.initializeFeatureFlags();
      }),
    );
  }

  private initializeInsightsService(): void {
    const outlookVersion = Office.context.platform?.toString() ?? 'unknown';
    this.insightsService.setContextData({ 'outlook-version': outlookVersion });

    this.insightsService.trackPageView({
      name: 'outlook-version',
      pageType: outlookVersion,
    });
  }

  private initializeFeatureFlags(): void {
    const showOnboarding = this.feature.isFeatureOn(
      'integration-o365-plg-onboarding-shown',
      false,
    );
    this.onboardingStore.setOnboardingVisibility(showOnboarding);
  }

  private logAuthDetailsToInsights(name: string, error: string): void {
    const event: InsightsLogDetails = {
      insights: this.insightsService,
      name: name,
      eventId: OutlookInsightsEvents.OutlookAuth,
      token: this.contextService.token,
      alias: this.globalStore.session()?.alias,
      error: error,
      metadata: { isTokenExpired: this.isTokenExpired },
    };

    logCustomEventDetailsToInsights(event);
  }

  checkSystemInfoForSyncSupport(): void {
    this.systemService
      .getInfo()
      .pipe(
        map((systemInfo) =>
          systemInfo
            ? this.systemService.validateDbVersionForNewSync(systemInfo)
            : false,
        ),
        catchError((error) => {
          console.error('Error fetching system info:', error);
          this.insightsService.trackException(
            { error },
            { message: `Failed to get system info: ${error.message}` },
          );
          return of(false);
        }),
      )
      .subscribe((valid) => {
        this.outlookStore.updateVersion(valid);
      });
  }
}
