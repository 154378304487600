import { AbEntry } from './ab-entry';
import { CurrencyValue } from './currency-value';
import { KeyAndDescription } from './key-and-description';
import { KeyAndDisplay } from './key-and-display';
import {
  ReadRequest,
  Request,
  RequestConfiguration,
  WriteRequest,
} from './request';
import { DataResponse, Response, WriteResponse } from './response';
import {
  FieldOption,
  FieldOptionDataResponse,
  FieldOptionsRead,
  FieldOptionsResponse,
  FieldOptionsWithData,
  FieldOptionsWriteRequest,
} from './field-options';
import { Entity, EntityFields } from './entity';
import { SalesStageSetup } from './stage';
import { ValueAndDisplay } from './value-and-display';

export enum OpportunityStatus {
  New = 0,
  Updated = 1,
  InProgress = 2,
  Won = 3,
  Lost = 4,
  Abandoned = 5,
  Suspended = 6,
}

export const OpportunityFieldsMaxValue = 9999999999;

export enum OpportunityFields {
  ActualRevenue = 'ActualRevenue',
  ActualRevenueCurrency = 'ActualRevenue/CurrencyCode',
  AbEntry = 'AbEntry',
  AbEntryKey = 'AbEntryKey',
  Age = 'Age',
  Category = 'Category',
  CloseDate = 'CloseDate',
  Comment = 'Comment',
  Competitor = 'CompetitorInfo',
  Contact = 'Contact',
  ContactKey = 'ContactKey',
  Cost = 'Cost',
  CorporateRevenue = 'CorporateRevenue',
  Creator = EntityFields.Creator,
  CreationDate = EntityFields.CreationDate,
  CurrentSalesStage = 'CurrentSalesStage',
  CurrentSalesStageAge = 'CurrentSalesStageAge',
  Description = 'Description',
  ForecastRevenue = 'ForecastRevenue',
  ForecastRevenueCurrency = 'ForecastRevenue/CurrencyCode',
  Key = EntityFields.Key,
  LastModifyDate = EntityFields.LastModifyDate,
  Leader = 'Leader',
  LostTo = 'LostTo',
  LostToKey = 'LostToKey',
  NextAction = 'NextAction',
  ModifiedBy = EntityFields.ModifiedBy,
  Objective = 'Objective',
  Partner = 'PartnerInfo',
  ParentKey = 'ParentKey',
  Product = 'Product',
  Rating = 'Rating',
  Reason = 'Reason',
  Revenue = 'Revenue',
  RevenueType = 'RevenueType',
  SalesProcessSetup = 'SalesProcessSetup',
  SalesProcessSetupKey = 'SalesProcessSetupKey',
  SalesStageSetup = 'SalesStageSetup',
  SalesStageSetupKey = 'SalesStageSetupKey',
  SalesTeam = 'SalesTeam',
  Status = 'Status',
  StartDate = 'StartDate',
}

export interface Opportunity extends Entity {
  [EntityFields.Key]: string;
  [OpportunityFields.ParentKey]: string;
  [OpportunityFields.AbEntry]: AbEntry;
  [OpportunityFields.Contact]: AbEntry;
  [OpportunityFields.Objective]: string;
  [OpportunityFields.Description]: string;
  [EntityFields.DisplayValue]: string;
  [OpportunityFields.Status]: number | ValueAndDisplay<number>;
  [OpportunityFields.StartDate]: ValueAndDisplay<string>;
  [OpportunityFields.CloseDate]: ValueAndDisplay<string>;
  [OpportunityFields.CorporateRevenue]: CurrencyValue;
  [OpportunityFields.Cost]: CurrencyValue;
  [OpportunityFields.ActualRevenue]: CurrencyValue;
  [OpportunityFields.ForecastRevenue]: CurrencyValue;
  [OpportunityFields.Leader]: KeyAndDisplay;
  [OpportunityFields.SalesProcessSetupKey]: string;
  [OpportunityFields.SalesStageSetupKey]: string;
  [OpportunityFields.SalesStageSetup]: SalesStageSetup;
  [OpportunityFields.CurrentSalesStageAge]: number;
  [OpportunityFields.CreationDate]: ValueAndDisplay<string>;
  [OpportunityFields.Creator]: KeyAndDisplay;
  [OpportunityFields.LastModifyDate]: ValueAndDisplay<string>;
  [OpportunityFields.ModifiedBy]: KeyAndDisplay;
  [OpportunityFields.RevenueType]: KeyAndDisplay | string;
  [OpportunityFields.CurrentSalesStage]: SalesStageSetup;
  [OpportunityFields.Product]: KeyAndDisplay;
}

export const OpportunityEditableListFields = {
  [OpportunityFields.Objective]: 60200,
  [OpportunityFields.NextAction]: 60044,
};

export interface OpportunityFieldOptions {
  SalesProcessSetup: KeyAndDescription;
  SalesStageSetup: SalesStageSetup;
  RevenueType: FieldOption;
}

export interface OpportunityCreate {
  Key?: string | null;
  Objective: string;
  AbEntryKey: string;
  ContactKey: string | null;
  Description: string | null;
  Status: number;
  SalesProcessSetupKey: string | null;
  SalesStageSetupKey: string | null;
  Cost: number | null;
  ActualRevenue: number | null;
  ForecastRevenue: number | null;
  CloseDate: string | null;
}

export interface OpportunityReadRequest extends Request {
  Opportunity: ReadRequest<Opportunity>;
}

export interface OpportunityResponse extends Response {
  Opportunity: DataResponse<Opportunity>;
}

export interface OpportunityWriteRequest extends Request {
  Opportunity: WriteRequest<OpportunityCreate>;
}

export interface OpportunityWriteResponse extends Response {
  Opportunity: WriteResponse<OpportunityCreate>;
}

export interface OpportunityFieldOptionRequest extends Request {
  Opportunity: FieldOptionsWithData<OpportunityFieldOptions, Opportunity>;
}

export interface OpportunityFieldOptionResponse extends Response {
  Opportunity: FieldOptionsResponse<OpportunityFieldOptions>;
}

export interface OpportunityFieldOptionReadRequest extends Request {
  Opportunity: FieldOptionsRead<OpportunityFieldOptions>;
}

export interface OpportunityFieldOptionReadResponse extends Response {
  Opportunity: FieldOptionDataResponse<OpportunityFieldOptions>;
}

export interface OpportunityFieldOptionWriteRequest extends Request {
  Opportunity: FieldOptionsWriteRequest<OpportunityFieldOptions>;
}

export const OpportunityReadDriver: RequestConfiguration = {
  Drivers: {
    IOpportunitySearcher: 'Maximizer.Model.Access.Sql.OpportunitySearcher',
  },
};

export const OpportunityWriteDriver: RequestConfiguration = {
  Drivers: {
    IOpportunityAccess: 'Maximizer.Model.Access.Sql.OpportunityAccess',
  },
};
