import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  ContextService,
  GlobalStore,
} from '@maximizer/core/shared/data-access';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import {
  INTEGRATION_URL,
  INTEGRATION_SUBSCRIPTION_KEY,
  APP_STORE_OAUTH_URL,
  APP_STORE_CONFIGURATION_URL,
} from '../../outlook-shared-data-access.module';
import {
  EmailSubscription,
  EmailSubscriptionConfiguration,
  UserSyncConfiguration,
  OutlookSyncEntityType,
  UpdateSyncConfiguration,
  OutlookSyncType,
  SyncHistory,
} from '../../models';
import {
  insightsHeader,
  InsightsService,
} from '@maximizer/core/shared/insights';
import {
  constructAppStoreForm,
  constructAppStoreParams,
  InsightsLogDetails,
  logCustomEventDetailsToInsights,
  OutlookInsightsEvents,
} from '../../utils';
import { GlobalServicesLoginDetails } from '@maximizer/core/shared/domain';

@Injectable()
export class OutlookSyncService {
  constructor(
    @Inject(APP_STORE_OAUTH_URL) private readonly appStoreOAuthUrl: string,
    @Inject(APP_STORE_CONFIGURATION_URL)
    private readonly appStoreConfigUrl: string,
    @Inject(INTEGRATION_URL) private readonly apiUrl: string,
    @Inject(INTEGRATION_SUBSCRIPTION_KEY)
    private readonly subscriptionKey: string,
    private readonly http: HttpClient,
    private readonly contextService: ContextService,
    private readonly insightsService: InsightsService,
    private readonly globalStore: GlobalStore,
  ) {}

  getConfiguration(
    userId: string,
    workspaceId: string,
  ): Observable<UserSyncConfiguration | undefined> {
    return this.http
      .get<UserSyncConfiguration>(
        `${this.apiUrl}/Outlook/v1/sync-configurations?userUid=${userId}&workspaceId=${workspaceId}`,
        {
          headers: {
            Authorization: this.contextService.token,
            'Ocp-Apim-Subscription-Key': this.subscriptionKey,
            [insightsHeader('ignore')]: 'true',
          },
        },
      )
      .pipe(
        map((result) => {
          if (result) {
            return result;
          }
          return undefined;
        }),
        catchError(() => of(undefined)),
      );
  }

  updateConfiguration(
    settings: UpdateSyncConfiguration,
    id: string,
  ): Observable<boolean> {
    return this.http
      .put<void>(
        `${this.apiUrl}/outlook/v1/sync-configurations/${id}`,
        settings,
        {
          headers: {
            Authorization: `Bearer ${this.contextService.token}`,
            'Ocp-Apim-Subscription-Key': this.subscriptionKey,
            'Content-Type': 'application/json-patch+json',
          },
          observe: 'response',
        },
      )
      .pipe(
        map((response: HttpResponse<void>) => response.status === 204),
        catchError((error) => {
          console.error('Error in updateConfiguration:', error);
          return throwError(() => error);
        }),
      );
  }

  saveEmail(
    configurationId: string,
    entityType: OutlookSyncEntityType,
    key: string,
    internetId: string,
  ): Observable<boolean> {
    const request = {
      maximizerEntityType: entityType,
      maximizerKey: key,
      internetMessageId: internetId,
    };
    return this.http
      .post<HttpResponse<void>>(
        `${this.apiUrl}/Outlook/v1/sync-configurations/${configurationId}/`,
        request,
        {
          headers: {
            Authorization: this.contextService.token,
            'Ocp-Apim-Subscription-Key': this.subscriptionKey,
          },
        },
      )
      .pipe(map((response: HttpResponse<void>) => response.status === 202));
  }

  updateEmailAutoSaveSubscription(
    syncId: string,
    subscription: EmailSubscription,
  ): Observable<boolean> {
    return this.http
      .put<HttpResponse<void>>(
        `${this.apiUrl}/Outlook/v1/sync-configurations/${syncId}/email-subscriptions`,
        subscription,
        {
          headers: {
            Authorization: `Bearer ${this.contextService.token}`,
            'Ocp-Apim-Subscription-Key': this.subscriptionKey,
          },
        },
      )
      .pipe(map((response: HttpResponse<void>) => response.status === 202));
  }

  getEmailSubscriptionByKey(
    maximizerKey: string,
    syncId: string,
  ): Observable<EmailSubscriptionConfiguration | undefined> {
    return this.http
      .get<EmailSubscriptionConfiguration>(
        `${this.apiUrl}/Outlook/v1/sync-configurations/${syncId}/email-subscriptions/${maximizerKey}`,
        {
          headers: {
            Authorization: `Bearer ${this.contextService.token}`,
            'Ocp-Apim-Subscription-Key': this.subscriptionKey,
            [insightsHeader('ignore')]: 'true',
          },
        },
      )
      .pipe(
        map((result) => {
          return result || undefined;
        }),
      );
  }

  getHistoryBySyncType(
    type: OutlookSyncType,
    id: string,
    pageSize = 5,
    offset = 0,
  ): Observable<SyncHistory[] | undefined> {
    const url = `${this.apiUrl}/outlook/v1/sync-configurations/${id}/sync-histories?syncType=${type}&pageSize=${pageSize}&offset=${offset}`;

    return this.http
      .get<SyncHistory[]>(url, {
        headers: {
          Authorization: `Bearer ${this.contextService.token}`,
          'Ocp-Apim-Subscription-Key': this.subscriptionKey,
        },
      })
      .pipe(catchError(() => of(undefined)));
  }

  buildAppStoreFormRequest(
    logInDetails: GlobalServicesLoginDetails | null,
    isInstall: boolean = false,
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const { alias, token, tenantId } = this.contextService;
      const session = this.globalStore.session();

      if (!session || !tenantId || !logInDetails) {
        this.handleAppStoreFromError(
          isInstall ? 'Install-AppStore-Params' : 'Settings-AppStore-Params',
          'Missing app store form parameters',
        );
        observer.error(new Error('Missing app store form parameters.'));
        return;
      }

      const urlParams = constructAppStoreParams(
        session,
        alias,
        token,
        tenantId,
        logInDetails,
        isInstall,
        this.insightsService,
      );

      if (!urlParams) {
        this.handleAppStoreFromError(
          isInstall
            ? 'Install-AppStore-Missing-Url-Params-Error'
            : 'Settings-AppStore-Missing-Url-Params-Error',
          'App store missing URL parameters.',
        );
        observer.error(new Error('App store missing URL parameters.'));
        return;
      }

      const appStoreUrl = isInstall
        ? this.appStoreOAuthUrl
        : this.appStoreConfigUrl;
      const formDetails = constructAppStoreForm(
        token,
        urlParams,
        appStoreUrl,
        this.insightsService,
      );

      if (!formDetails) {
        observer.error(new Error('App store form details incomplete.'));
        return;
      }

      try {
        this.handleAppStoreFormSubmission(
          formDetails.form,
          formDetails.window,
          isInstall,
        );
        observer.next(true);
        observer.complete();
      } catch (error) {
        this.handleAppStoreFromError(
          isInstall
            ? 'Install-AppStore-Form-Submit-Error'
            : 'Settings-AppStore-Form-Submit-Error',
          (error as Error).message,
        );
        observer.error(new Error('App store form submission failed.'));
      }
    });
  }

  private handleAppStoreFormSubmission(
    appStoreForm: HTMLFormElement,
    newWindow: Window,
    isInstall: boolean,
  ): void {
    try {
      newWindow.document.body.appendChild(appStoreForm);
      appStoreForm.submit();
      newWindow.focus();
    } catch (error) {
      this.handleAppStoreFromError(
        isInstall
          ? 'Install-AppStore-Form-Submit-Error'
          : 'Settings-AppStore-Form-Submit-Error',
        (error as Error).message,
      );
    } finally {
      const event: InsightsLogDetails = {
        insights: this.insightsService,
        name: isInstall
          ? 'Install-AppStore-Form-Success'
          : 'Settings-AppStore-Form-Success',
        eventId: OutlookInsightsEvents.AppStoreRequest,
        token: this.contextService.token,
        alias: this.globalStore.session()?.alias,
      };

      logCustomEventDetailsToInsights(event);

      if (appStoreForm.parentNode) {
        newWindow.document.body.removeChild(appStoreForm);
      }
    }
  }

  private handleAppStoreFromError(name: string, error: string): void {
    const event: InsightsLogDetails = {
      insights: this.insightsService,
      name,
      eventId: OutlookInsightsEvents.AppStoreRequest,
      token: this.contextService.token,
      alias: this.globalStore.session()?.alias,
      error,
    };

    logCustomEventDetailsToInsights(event);
    console.error(`An error occurred opening the App Store page: ${error}`);
  }
}
