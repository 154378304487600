import { Entity } from './entity';
import { Response, WriteResponse } from './response';
import { SecurityAccess } from './security-access';
import { ValueAndDisplay } from './value-and-display';
import { DocumentType } from '../../../models';
import { Request, RequestConfiguration, WriteRequest } from './request';

export interface Document extends Entity {
  Key: string | null;
  BinaryDataId: string;
  DateTime: string;
  Name: string;
  Description: string;
  Ext: string;
  Type: DocumentType;
  Size: number;
  ParentKey: string;
  SecAccess?: SecurityAccess;
  DisplayValue?: ValueAndDisplay<string>;
}

export interface DocumentWriteRequest extends Request {
  Document: WriteRequest<Document>;
}

export interface DocumentWriteResponse extends Response {
  Document: WriteResponse<Document>;
}

export const DocumentAccessDriver: RequestConfiguration = {
  Drivers: {
    IDocumentAccess: 'Maximizer.Model.Access.Sql.DocumentAccess',
  },
};
