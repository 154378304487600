import { Mapper } from '../../../mappers';
import { CompanyInfo } from '../../../models';
import { Company } from '../models';

export class CompanyInfoMapper extends Mapper<Company, CompanyInfo> {
  from(source: Company): CompanyInfo {
    return {
      name: source.name ?? '',
      phoneNumber: source.phoneNumber ?? '',
      photo: source.logos.small ?? '',
      address1: this.getAddress1(
        source.location.address.streetNumber,
        source.location.address.streetName,
      ),
      address2: source.location.address.subPremise ?? '',
      city: source.location.city ?? '',
      stateProvince: source.location.region ?? '',
      country: source.location.country ?? '',
      zipCode: source.location.address.postalCode ?? '',
      latitude: source.location.address.coordinates?.latitude ?? null,
      longitude: source.location.address.coordinates?.latitude ?? null,
    };
  }
  private getAddress1(
    streetNumber: string | null,
    streetName: string | null,
  ): string {
    let address1 = '';

    if (streetNumber && streetName) {
      address1 = streetNumber + ' ' + streetName;
    } else if (streetNumber) {
      address1 = streetNumber;
    } else if (streetName) {
      address1 = streetName;
    }
    return address1;
  }
}
