import { Component, EventEmitter, Output } from '@angular/core';
import {
  OutlookMsalService,
  OutlookStore,
} from '@maximizer/outlook/shared/data-access';

@Component({
  selector: 'maximizer-outlook-msal-mismatch-message',
  template: `<kendo-dialog [width]="256">
    <div class="py-2">
      <div class="flex flex-col gap-2 pb-3">
        <p
          class="text-body"
          [innerHtml]="'outlook.auth.msal.mismatchTitle' | translate"
        ></p>
        <p class="text-body">
          {{ 'outlook.auth.msal.mismatchError' | translate }}
        </p>
        <p
          class="text-body"
          [innerHTML]="'outlook.auth.msal.mismatchBody' | translate"
        ></p>
      </div>
      <div class="flex justify-between">
        <button
          id="outlook-auth-msal-mismatchMessage-close"
          kendoButton
          (click)="closeAccountMismatchWarning()"
        >
          {{ 'outlook.buttons.close' | translate }}
        </button>
        <button
          id="outlook-auth-msal-mismatchMessage-switch"
          kendoButton
          themeColor="primary"
          (click)="switchAccount()"
        >
          {{ 'outlook.buttons.switch' | translate }}
        </button>
      </div>
    </div>
  </kendo-dialog>`,
  standalone: false,
})
export class MsalMismatchMessageComponent {
  @Output() hideMsalMismatchDialog = new EventEmitter<boolean>();

  constructor(
    private readonly msalService: OutlookMsalService,
    private readonly outlookStore: OutlookStore,
  ) {}

  switchAccount(): void {
    this.msalService.switchAccount();
  }

  closeAccountMismatchWarning(): void {
    this.hideMsalMismatchDialog.emit(true);
    this.outlookStore.setHideMismatchWarning(true);
    localStorage.setItem('hideMismatchMessage', JSON.stringify(true));
  }
}
