import { Component, computed, inject, Signal } from '@angular/core';
import {
  OnboardingStep,
  OnboardingStore,
  StepStatus,
} from '@maximizer/outlook/shared/data-access';

@Component({
  selector: 'maximizer-outlook-onboarding-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  standalone: false,
})
export class OutlookStepperComponent {
  private readonly onboardingStore = inject(OnboardingStore);

  steps: Signal<OnboardingStep[]> = computed(() =>
    this.onboardingStore.onboardingConfig().map((item) => item.step),
  );

  stepsWithStatus: Signal<OnboardingStep[]> = computed(() => {
    const currentStep = this.onboardingStore.currentStep();
    return this.steps().map((step) => ({
      ...step,
      status: this.computeStepStatus(step.step, currentStep),
    }));
  });

  private computeStepStatus(step: number, currentStep: number): StepStatus {
    if (step < currentStep) {
      return 'completed';
    }
    if (step === currentStep) {
      return 'active';
    }
    return 'default';
  }
}
