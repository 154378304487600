import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ContextService } from '@maximizer/core/shared/data-access';
import { Octopus } from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import {
  AppointmentFieldOption,
  AppointmentForm,
  AppointmentSummary,
  AppointmentFieldOptionMapper,
  AppointmentFormMapper,
  AppointmentSummaryMapper,
} from '@maximizer/outlook/shared/domain';

@Injectable()
export class AppointmentService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  getFieldOption(): Observable<AppointmentFieldOption> {
    const request: Octopus.AppointmentFieldOptionsRequest = {
      Appointment: {
        FieldOptions: {
          InteractionCategory: [{ Key: 1, DisplayValue: 1 }],
          Result: [{ Key: 1, DisplayValue: 1 }],
          Location: [{ Key: 1, DisplayValue: 1 }],
        },
        Options: {
          ShowInactive: false,
        },
      },
    };

    return this.http
      .post<Octopus.AppointmentFieldOptionsResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new AppointmentFieldOptionMapper();
            return mapper.from(result);
          }
          return { categories: [], results: [], locations: [] };
        }),
      );
  }

  create(appointmentForm: AppointmentForm): Observable<string | null> {
    const mapper = new AppointmentFormMapper();
    const data = mapper.from(appointmentForm);
    const request: Octopus.AppointmentCreateRequest = {
      Appointment: {
        Data: data,
      },
    };
    return this.http
      .post<Octopus.AppointmentCreateResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            return result.Appointment.Data.Key;
          }
          throw new HttpErrorResponse({ error: 'Unsuccessful response' });
        }),
      );
  }

  getSummary(key: string, type: string): Observable<AppointmentSummary> {
    const query = this.getSummaryQuery(key, type);
    const fields = this.getSummaryScope();
    const request = {
      Compatibility: {
        AppointmentObject: '1.0',
      },
      Appointment: {
        Scope: {
          Fields: fields,
        },
        Criteria: {
          SearchQuery: query,
          Top: 1,
        },
        OrderBy: {
          Fields: [{ StartDate: 'ASC' }],
        },
      },
      'Appointment.Count': {
        Scope: {
          Fields: {
            '$COUNT()': 1,
          },
        },
        Criteria: {
          SearchQuery: query,
        },
      },

      Globalization: Octopus.DefaultGlobalization,
    };

    return this.http
      .post<Octopus.AppointmentSummaryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new AppointmentSummaryMapper();
            return mapper.from(result);
          }

          return {
            appointmentAmount: 0,
          };
        }),
      );
  }

  private getSummaryQuery(
    key: string,
    type: string,
  ): Octopus.LogicalQuery<Octopus.Appointment> {
    const query: Octopus.LogicalQuery<Octopus.Appointment> = {
      $AND: [{ StartDate: { $GT: new Date().toISOString() } }],
    };

    if (type == 'lead') {
      query.$AND?.push({ 'Leads/Key': { $EQ: key } });
    }
    if (type == 'abentry') {
      query.$AND?.push({ 'AbEntries/Key': { $EQ: key } });
    }

    return query;
  }

  private getSummaryScope(): Octopus.Scope<Octopus.Appointment> {
    return {
      Key: 1,
      Subject: 1,
      StartDate: 1,
      EndDate: 1,
      DisplayValue: 1,
      Location: {
        DisplayValue: 1,
      },
      CreationDate: 1,
    };
  }
}
