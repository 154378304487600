<div class="pt-2 flex flex-col gap-2 ml-2 offset-left-tooltip">
  <p class="subtitle-2">
    {{ 'outlook.sync.install.newFeature' | translate }}
  </p>
  <p class="text-body">
    {{ 'outlook.sync.install.installation' | translate }}
  </p>
  <p
    class="text-body"
    [innerHtml]="'outlook.sync.install.installationNote' | translate"
  ></p>
  <div class="flex justify-end">
    <button kendoButton themeColor="primary" (click)="installOutlookSync()">
      {{ 'outlook.buttons.install' | translate }}
    </button>
  </div>
</div>

<maximizer-outlook-notification #notification [closable]="true">
</maximizer-outlook-notification>
