<div class="text-xs space-y-2">
  <p class="mb-2 font-bold">{{ 'outlook.summary.task.title' | translate }}</p>

  @if (loading) {
    <maximizer-outlook-skeleton
      containerClasses="h-[120px] w-full rounded bg-neutral-25"
    ></maximizer-outlook-skeleton>
  }

  @if (!loading && !configuration.overdue && !configuration.upcoming) {
    <p class="text-body pb-8">
      {{ 'outlook.summary.task.emptyState' | translate }}
    </p>
  }

  @if (!loading && configuration.overdue) {
    <div
      class="bg-white min-h-[120px] max-h-[150px] w-100 rounded shadow-card flex flex-col p-2 pl-2 mb-2 justify-around"
    >
      <div class="flex">
        <em
          class="mr-1 my-auto fa-icons icon-solid icon-12 icon-circle-check text-neutral-70"
        ></em>
        <span class="font-bold my-auto line-clamp-1">{{
          configuration.overdue.description
        }}</span>
        <em
          class="ml-auto mr-1 fa-icons icon-solid icon-12 icon-clock text-error"
        ></em>
      </div>
      <div class="flex">
        <span class="text-neutral-70 mr-1"
          >{{ 'outlook.summary.task.dueDate' | translate }}:</span
        >
        <span class="text-error">{{
          configuration.overdue.dueDate | date: 'MMMM dd, yyyy hh:mm a'
        }}</span>
      </div>

      <div class="flex text-neutral-70 text-xxs">
        {{ 'outlook.summary.task.assignedTo' | translate }}:
      </div>

      <div class="flex">
        @if (configuration.overdue.assignedTo) {
          <span
            class="my-auto avatar avatar-xs avatar-neutral-30 avatar-border font-bold text-xxs"
            >{{
              configuration.overdue.assignedTo.DisplayValue | nameInitials
            }}</span
          >
        }
        <div class="flex flex-col">
          <span class="my-auto ml-2 line-clamp-1">{{
            configuration.overdue.assignedTo.DisplayValue
          }}</span>

          <span
            class="my-auto ml-2 text-xxs text-neutral-70 text-ellipsis overflow-hidden whitespace-nowrap"
            >{{ configuration.overdueUser?.email }}</span
          >
        </div>
      </div>

      <div class="flex">
        <span class="text-ellipsis overflow-hidden whitespace-nowrap">{{
          configuration.overdue.priority
        }}</span>

        <span class="ml-auto">{{
          configuration.overdue.creationDate | date: 'MMMM dd, yyyy hh:mm a'
        }}</span>
      </div>
    </div>

    <a
      id="outlook-task-ui-summaryTask-overdue"
      class="text-inserv-100 line-clamp-3 underline"
      [href]="deepLink"
      target="_blank"
      >{{ configuration.overdueTaskAmount }}
      {{ 'outlook.summary.task.overdueAmount' | translate }}</a
    >
  }

  @if (!loading && configuration.upcoming) {
    <div
      class="bg-white min-h-[120px] max-h-[150px] w-100 rounded shadow-card flex flex-col p-2 pl-2 mb-2 justify-around"
    >
      <div class="flex">
        <em
          class="mr-1 my-auto fa-icons icon-solid icon-12 icon-circle-check text-neutral-70"
        ></em>
        <span
          class="font-bold my-auto text-ellipsis overflow-hidden whitespace-nowrap"
          >{{ configuration.upcoming.description }}</span
        >
      </div>
      <div class="flex">
        <span class="text-neutral-70 mr-2"
          >{{ 'outlook.summary.task.dueDate' | translate }}:</span
        >
        <span>{{
          configuration.upcoming.dueDate | date: 'MMMM dd, yyyy hh:mm a'
        }}</span>
      </div>

      <div
        class="flex text-neutral-70 text-xxs leading-3 text-ellipsis overflow-hidden whitespace-nowrap"
      >
        {{ 'outlook.summary.task.assignedTo' | translate }}:
      </div>

      <div class="flex">
        @if (configuration.upcoming.assignedTo) {
          <span
            class="my-auto avatar avatar-xs bg-neutral-70 avatar-neutral-30 avatar-border font-bold text-xxs"
            >{{
              configuration.upcoming.assignedTo.DisplayValue | nameInitials
            }}</span
          >
        }
        <div class="flex flex-col">
          <span
            class="my-auto ml-2 text-ellipsis overflow-hidden whitespace-nowrap"
            >{{ configuration.upcoming.assignedTo.DisplayValue }}</span
          >

          <span class="my-auto ml-2 text-xxs text-neutral-70">{{
            configuration.upcomingUser?.email
          }}</span>
        </div>
      </div>

      <div class="flex">
        <span class="">{{ configuration.upcoming.priority }}</span>

        <span class="ml-auto">{{
          configuration.upcoming.creationDate | date: 'MMMM dd, yyyy hh:mm a'
        }}</span>
      </div>
    </div>
    <a
      id="outlook-task-ui-summaryTask-upcoming"
      class="text-inserv-100 line-clamp-3 underline"
      [href]="deepLink"
      target="_blank"
      >{{ configuration.upcomingTaskAmount }}
      {{ 'outlook.summary.task.upcomingAmount' | translate }}</a
    >
  }
</div>
