import { EntityType } from './entity-type';
import { ReadRequest, Request, WriteRequest } from './request';
import { DataResponse, Response, WriteResponse } from './response';

export enum RecentEntryType {
  RecentEntry = 1,
  RecentFavoriteLists = 2,
  RecentColumnSetups = 3,
}

export interface RecentEntry<T = string> {
  Key: string | null;
  EntityType?: EntityType;
  Type?: RecentEntryType;
  User?: string;
  Items: Array<T>;
}

export interface RecentEntryResponse<T = string> extends Response {
  RecentEntry: DataResponse<RecentEntry<T>>;
}

export interface RecentEntryReadRequest<T = string> extends Request {
  RecentEntry: ReadRequest<RecentEntry<T>>;
}

export interface RecentEntryWriteResponse<T = string> extends Response {
  RecentEntry: WriteResponse<RecentEntry<T>>;
}

export interface RecentEntryWriteRequest<T = string> extends Request {
  RecentEntry: WriteRequest<RecentEntry<T>>;
}
