import { Routes } from '@angular/router';
import {
  AddAbEntryComponent,
  EditAbEntryComponent,
} from '@maximizer/outlook/abentry/ui';
import { AddAppointmentComponent } from '@maximizer/outlook/appointment/ui';
import { CallLogComponent } from '@maximizer/outlook/interaction-log/ui';
import {
  AddLeadComponent,
  EditLeadComponent,
} from '@maximizer/outlook/lead/ui';
import { AddNoteComponent } from '@maximizer/outlook/note/ui';
import { AddOpportunityComponent } from '@maximizer/outlook/opportunity/ui';
import { AddCaseComponent } from '@maximizer/outlook/case/ui';
import {
  EntryDetailsComponent,
  EntryPageComponent,
  EntrySummaryComponent,
} from '@maximizer/outlook/entry/ui';
import { AddTaskComponent } from '@maximizer/outlook/task/ui';
import { TimelineComponent } from '@maximizer/outlook/timeline/ui';
import {
  OutlookComposeComponent,
  OutlookReadComponent,
  OutlookRelatedComponent,
} from '@maximizer/outlook/main/ui';
import { HomeComponent } from './home/home.component';
import {
  OutlookAuthorizeComponent,
  OutlookLoginComponent,
  OutlookLoginEmailComponent,
} from '@maximizer/outlook/auth/ui';
import { authenticationGuard } from './auth/auth.guard';

export const routes: Routes = [
  { path: 'authorize', component: OutlookAuthorizeComponent },
  { path: 'login', component: OutlookLoginComponent },
  { path: 'login-email', component: OutlookLoginEmailComponent },
  {
    path: 'lead/add',
    component: AddLeadComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'abentry/add/:type',
    component: AddAbEntryComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'abentry/:id/edit',
    component: EditAbEntryComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'lead/:id/edit',
    component: EditLeadComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: ':type/:id/add-appointment',
    component: AddAppointmentComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: ':type/:id/add-call-log',
    component: CallLogComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: ':type/:id/add-opportunity',
    component: AddOpportunityComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: ':type/:id/add-case',
    component: AddCaseComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: ':type/:id/add-task',
    component: AddTaskComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: ':type/:id/add-note',
    component: AddNoteComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: ':type/:id/summary',
    component: EntrySummaryComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: ':type/:id/details',
    component: EntryDetailsComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: ':type/:id/timeline',
    component: TimelineComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: ':type/:id',
    component: EntryPageComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'read-email',
    component: OutlookReadComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'compose-email',
    component: OutlookComposeComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'related',
    component: OutlookRelatedComponent,
    canActivate: [authenticationGuard],
  },
  { path: 'home', component: HomeComponent },
  { path: '**', pathMatch: 'full', redirectTo: '/home' },
];
