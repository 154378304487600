/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  Input,
} from '@angular/core';

@Directive({
  selector: '[kendoTooltip]',
  standalone: false,
})
export class OverflowTooltipDirective implements AfterViewInit {
  @Input() removeIfNotOverflow = false;

  constructor(
    private readonly element: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    if (this.removeIfNotOverflow && !this.isTextOverflowing()) {
      this.renderer.removeAttribute(this.element.nativeElement, 'title');
    }
  }

  private isTextOverflowing(): boolean {
    const element = this.element.nativeElement as HTMLElement;
    return element.scrollWidth > element.clientWidth;
  }
}
