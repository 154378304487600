import { FormArray } from '@angular/forms';
import { FormGroupWrapper } from './form-group-wrapper';
import { FormDefinition, ValidationErrors } from './form-types';
import { FormWrapperBuilder } from './form-wrapper-builder';

export class FormArrayWrapper<T> extends Array<FormGroupWrapper<T>> {
  constructor(
    public control: FormArray,
    private readonly shape: FormDefinition<T>,
  ) {
    super();
  }

  get valid(): boolean {
    return this.control.valid;
  }

  get errors(): ValidationErrors<T>[] {
    return this.map((group) => group.getErrors());
  }

  get value(): T[] {
    return this.control.value;
  }

  addRange(count: number, values?: Partial<T>[]): void {
    for (let index = 0; index < count; index++) {
      this.add(values && values.length > index ? values[index] : undefined);
    }
  }

  add(value?: Partial<T>): FormGroupWrapper<T> {
    const group = FormWrapperBuilder.group(this.shape);

    this.push(group);
    this.control.push(group.control);

    if (value) {
      group.patch(value);
    }

    return group;
  }

  delete(index: number): void {
    this.control.removeAt(index);
    this.splice(index, 1);
  }

  reset(): void {
    this.control.clear();
    this.length = 0;
  }
}
