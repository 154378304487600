import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials',
  standalone: false,
})
export class NameInitialsPipe implements PipeTransform {
  transform(name: string): string {
    if (!name) return '';

    const names = name.split(' ').filter((word) => word);
    const initials =
      names[0][0] + (names.length > 1 ? names[names.length - 1][0] : '');
    return initials.toUpperCase();
  }
}
