import {
  GlobalServicesLoginDetails,
  Session,
} from '@maximizer/core/shared/domain';
import { AppStoreRedirectParams } from '../models';
import {
  buildApiWebRequest,
  getDecodedAccessToken,
  mapDataCenterFromToken,
} from './datacenter.util';
import { InsightsService } from '@maximizer/core/shared/insights';
import {
  InsightsLogDetails,
  OutlookInsightsEvents,
  logCustomEventDetailsToInsights,
} from './insights.util';

export function constructAppStoreParams(
  session: Session,
  alias: string,
  token: string,
  tenantId: string,
  logInDetails: GlobalServicesLoginDetails,
  install: boolean,
  insightsService: InsightsService,
): string | undefined {
  const dataCenterId = mapDataCenterFromToken(token);

  if (!dataCenterId) {
    console.error('Could not resolve dataCenterId');

    const event: InsightsLogDetails = {
      insights: insightsService,
      name: 'App-Store-Util-Missing-DataCenterId',
      eventId: OutlookInsightsEvents.AppStoreRequest,
      token: getDecodedAccessToken(token),
      error: 'Could not resolve dataCenterId',
    };

    logCustomEventDetailsToInsights(event);

    return undefined;
  }

  const params: AppStoreRedirectParams = {
    webApiUrlRequest: buildApiWebRequest(dataCenterId, alias),
    userId: session.user.id,
    alias,
    dataCenterId,
    customerId: tenantId ?? '',
    tenantPlatform: 0,
    tenantName: logInDetails?.tenant.name,
    tenantKey: session.database,
    workspace: session.workspace,
    maxToken: token,
  };

  if (install) {
    delete params.maxToken;
  }

  const missingParams = Object.entries(params)
    .filter(([_, value]) => [null, undefined, ''].includes(value))
    .map(([key]) => key);

  if (missingParams.length > 0) {
    const errorMessage = `Missing parameters for App Store request: ${JSON.stringify(missingParams)}`;
    console.error(errorMessage);

    const event: InsightsLogDetails = {
      insights: insightsService,
      name: 'App-Store-Util-Missing-Parameters',
      eventId: OutlookInsightsEvents.AppStoreRequest,
      token: getDecodedAccessToken(token),
      alias,
      error: errorMessage,
      metadata: { missingParams: JSON.stringify(missingParams) },
    };

    logCustomEventDetailsToInsights(event);

    return undefined;
  }

  return JSON.stringify(params);
}

export function constructAppStoreForm(
  token: string,
  params: string,
  url: string,
  insightsService: InsightsService,
): {
  form: HTMLFormElement;
  window: Window;
} | null {
  const newWindow = window.open('', '_blank');

  if (!newWindow) {
    const event: InsightsLogDetails = {
      insights: insightsService,
      name: 'App-Store-Util-Open-Window-Fail',
      eventId: OutlookInsightsEvents.AppStoreRequest,
      token: getDecodedAccessToken(token),
      alias: undefined,
      error: 'Failed to open app store in new window.',
    };

    logCustomEventDetailsToInsights(event);
    console.error('Failed to open app store in new window.');

    return null;
  }

  const appStoreForm = newWindow.document.createElement('form');
  appStoreForm.method = 'POST';
  appStoreForm.action = url;

  try {
    const dataParametersInput = newWindow.document.createElement('input');
    dataParametersInput.type = 'hidden';
    dataParametersInput.name = 'DataParameters';
    dataParametersInput.value = params;

    appStoreForm.appendChild(dataParametersInput);
    newWindow.document.body.appendChild(appStoreForm);
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Unknown Error';

    const event: InsightsLogDetails = {
      insights: insightsService,
      name: 'App-Store-Util-Form-Construct-Fail',
      eventId: OutlookInsightsEvents.AppStoreRequest,
      token: getDecodedAccessToken(token),
      alias: undefined,
      error: errorMessage,
    };

    logCustomEventDetailsToInsights(event);
    console.error('Error constructing form:', errorMessage);

    return null;
  }

  return { form: appStoreForm, window: newWindow };
}
