import {
  APP_INITIALIZER,
  InjectionToken,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import {
  provideRouter,
  RouterLink,
  RouterLinkActive,
  RouterModule,
  RouterOutlet,
  withComponentInputBinding,
} from '@angular/router';
import { CoreGlobalSearchDataAccessModule } from '@maximizer/core/global-search/data-access';
import {
  DataAccessModule,
  GlobalStore,
} from '@maximizer/core/shared/data-access';
import { CDN_URL, DomainModule } from '@maximizer/core/shared/domain';
import { InsightsModule } from '@maximizer/core/shared/insights';
import {
  UiModule,
  defaultTranslateConfiguration,
} from '@maximizer/core/shared/ui';
import { OutlookAppointmentDataAccessModule } from '@maximizer/outlook/appointment/data-access';
import { OutlookAppointmentUiModule } from '@maximizer/outlook/appointment/ui';
import { OutlookInteractionLogDataAccessModule } from '@maximizer/outlook/interaction-log/data-access';
import { OutlookInteractionLogUiModule } from '@maximizer/outlook/interaction-log/ui';
import { OutlookTimelineUiModule } from '@maximizer/outlook/timeline/ui';
import { OutlookNoteDataAccessModule } from '@maximizer/outlook/note/data-access';
import { OutlookNoteUiModule } from '@maximizer/outlook/note/ui';
import { OutlookOpportunityDataAccessModule } from '@maximizer/outlook/opportunity/data-access';
import { OutlookOpportunityUiModule } from '@maximizer/outlook/opportunity/ui';
import { OutlookSharedUiModule } from '@maximizer/outlook/shared/ui';
import { OutlookTaskDataAccessModule } from '@maximizer/outlook/task/data-access';
import { OutlookTaskUiModule } from '@maximizer/outlook/task/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { routes } from './app.routes';
import { OutlookTimelineDataAccessModule } from '@maximizer/outlook/timeline/data-access';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { OutlookLeadUiModule } from '@maximizer/outlook/lead/ui';
import { OutlookAbEntryUiModule } from '@maximizer/outlook/abentry/ui';
import { OutlookEntryUiModule } from '@maximizer/outlook/entry/ui';
import { OutlookSharedDataAccessModule } from '@maximizer/outlook/shared/data-access';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import { OutlookMenuUiModule } from '@maximizer/outlook/menu/ui';
import { OutlookMainUiModule } from '@maximizer/outlook/main/ui';
import { OutlookAuthUiModule } from '@maximizer/outlook/auth/ui';
import { OutlookOnboardingUiModule } from '@maximizer/outlook/onboarding/ui';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { OutlookAuthService } from './auth/auth.service';
import { ADDIN_VERSION } from './app.version';
import { OutlookCaseDataAccessModule } from '@maximizer/outlook/case/data-access';
import { OutlookCaseUiModule } from '@maximizer/outlook/case/ui';

export const INSTRUMENTATION_KEY = new InjectionToken<string>(
  'instrumentationKey',
);
export const APP_STORE_OAUTH_URL = new InjectionToken<string>(
  'appStoreOAthUrl',
);
export const APP_STORE_CONFIGURATION_URL = new InjectionToken<string>(
  'appStoreConfigurationUrl',
);
export const INTEGRATION_WEBHOOK_URL = new InjectionToken<string>(
  'integrationWebhook',
);
export const WEBHOOK_EARLY_ACCESS = new InjectionToken<string>(
  'webhookEarlyAccess',
);

@NgModule({
  declarations: [AppComponent, HomeComponent],
  exports: [RouterModule],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    ButtonsModule,
    InputsModule,
    DropDownsModule,
    PopupModule,
    DialogsModule,
    LayoutModule,
    TooltipsModule,
    IndicatorsModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    UiModule,
    DataAccessModule,
    CoreGlobalSearchDataAccessModule,
    OutlookAbEntryUiModule,
    OutlookAppointmentUiModule,
    OutlookAppointmentDataAccessModule,
    OutlookEntryUiModule,
    OutlookInteractionLogDataAccessModule,
    OutlookInteractionLogUiModule,
    OutlookLeadUiModule,
    OutlookInteractionLogUiModule,
    OutlookLeadUiModule,
    OutlookMainUiModule,
    OutlookNoteUiModule,
    OutlookNoteDataAccessModule,
    OutlookOpportunityDataAccessModule,
    OutlookOpportunityUiModule,
    OutlookTaskUiModule,
    OutlookTaskDataAccessModule,
    OutlookTimelineUiModule,
    OutlookTimelineDataAccessModule,
    OutlookSharedUiModule,
    OutlookSharedDataAccessModule.forRoot(
      environment.integration.url,
      environment.integration.subscriptionKey,
      environment.appStoreOAuthUrl,
      environment.appStoreConfigurationUrl,
      environment.integration.webhookUrl,
      environment.integration.webhookId.earlyAccess,
    ),
    OutlookSharedDomainModule,
    OutlookMenuUiModule,
    OutlookAuthUiModule,
    OutlookOnboardingUiModule,
    OutlookCaseDataAccessModule,
    OutlookCaseUiModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    TranslateModule.forRoot(defaultTranslateConfiguration),
    DomainModule,
    DataAccessModule.forRoot(
      !environment.production,
      environment.launchDarklyClientId,
      environment.smartFill,
      undefined,
      undefined,
      ADDIN_VERSION,
      environment.product,
      environment.oauth,
    ),
    InsightsModule,
  ],
  providers: [
    { provide: CDN_URL, useValue: environment.cdn },
    { provide: INSTRUMENTATION_KEY, useValue: environment.instrumentationKey },
    { provide: APP_STORE_OAUTH_URL, useValue: environment.appStoreOAuthUrl },
    {
      provide: APP_STORE_CONFIGURATION_URL,
      useValue: environment.appStoreConfigurationUrl,
    },
    {
      provide: INTEGRATION_WEBHOOK_URL,
      useValue: environment.integration.webhookUrl,
    },
    {
      provide: WEBHOOK_EARLY_ACCESS,
      useValue: environment.integration.webhookId.earlyAccess,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (global: GlobalStore) => () =>
        global.setInsightsConfiguration({
          default: {
            applicationInsights: {
              instrumentationKey: environment.instrumentationKey,
            },
            eventLevel: SeverityLevel.Information,
            trackPageView: true,
          },
        }),

      deps: [GlobalStore],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: OutlookAuthService) => async () =>
        authService.initialize(),
      deps: [OutlookAuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translate: TranslateService) => () =>
        translate.use(translate.defaultLang),
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
    },
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
