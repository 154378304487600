import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild,
} from '@angular/core';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import {
  INTEGRATION_WEBHOOK_URL,
  WEBHOOK_CREATE_EARLY_ACCESS,
} from '@maximizer/outlook/shared/data-access';
import { GlobalStore, OAuth2Service } from '@maximizer/core/shared/data-access';
import { InsightsService } from '@maximizer/core/shared/insights';
import { catchError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'maximizer-outlook-sync-early-access',
  templateUrl: './early-access.component.html',
  standalone: false,
})
export class SyncEarlyAccessComponent {
  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Output() showEarlyAccessRequest = new EventEmitter<boolean>();

  constructor(
    @Inject(INTEGRATION_WEBHOOK_URL) private readonly webhookUrl: string,
    @Inject(WEBHOOK_CREATE_EARLY_ACCESS)
    private readonly createEarlyAccess: string,
    private readonly http: HttpClient,
    private readonly oAuthService: OAuth2Service,
    private readonly insightsService: InsightsService,
    public globalStore: GlobalStore,
  ) {}

  hideEarlyAccessRequest(): void {
    this.showEarlyAccessRequest.emit(false);
    sessionStorage.setItem('hideEarlyAccess', 'true');
  }

  sendEarlyAccessRequest(): void {
    const logInDetails = this.oAuthService.getStorageLoginDetails();

    const params = {
      userId: this.globalStore.session()?.user.id,
      databaseId: this.globalStore.session()?.database,
      databaseAlias: logInDetails?.tenant.alias,
      email: this.oAuthService.getStorageMxUserEmail(),
    };

    this.http
      .post(`${this.webhookUrl}/${this.createEarlyAccess}`, params)
      .pipe(
        catchError((error) => {
          console.error('Error sending early access request:', error);
          this.insightsService.trackException(
            { exception: error as Error },
            { message: `Error sending early access request: ${error.message}` },
          );
          this.notification.showMessage(
            'error',
            'outlook.sync.earlyAccess.error',
            true,
          );
          return of(null);
        }),
      )
      .subscribe(() => {
        this.showEarlyAccessRequest.emit(false);
        sessionStorage.setItem('hideEarlyAccess', 'true');
        this.notification.showMessage(
          'success',
          'outlook.sync.earlyAccess.notification',
          true,
        );
      });
  }
}
