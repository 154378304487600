import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CDN_URL } from '@maximizer/core/shared/domain';
import { EmbeddedComponent } from '@maximizer/core/shared/ui';
import { NoteService } from '@maximizer/outlook/note/data-access';
import {
  NoteSummary,
  OutlookLeadSearch,
  OutlookAbEntryDetails,
} from '@maximizer/outlook/shared/domain';
import { catchError, finalize } from 'rxjs';

@Component({
  selector: 'maximizer-summary-note',
  templateUrl: './summary-note.component.html',
  standalone: false,
})
export class SummaryNoteComponent
  extends EmbeddedComponent
  implements OnInit, OnChanges
{
  constructor(
    @Inject(CDN_URL) public cdn: string,
    element: ElementRef,
    private readonly noteService: NoteService,
  ) {
    super(element);
    this.configuration = {
      noteAmount: 0,
    };
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['abentry']) {
      this.abentry = changes['abentry'].currentValue;
      if (this.configuration.note) {
        this.configuration.note.parent =
          this.abentry?.firstName + ' ' + this.abentry?.lastName;
      }
    }
    if (changes['lead']) {
      this.lead = changes['lead'].currentValue;
      if (this.configuration.note) {
        this.configuration.note.parent = this.lead?.name;
      }
    }
  }

  configuration: NoteSummary;
  abEntryKey: string | null = null;
  leadKey: string | null = null;

  @Input() abentry?: OutlookAbEntryDetails;
  @Input() lead?: OutlookLeadSearch;
  @Input() deepLink?: string;
  @Input() id = '';

  @Output() loadErrorEvent: EventEmitter<string> = new EventEmitter();
  @Output() loadingEvent: EventEmitter<boolean> = new EventEmitter();
  loading = true;

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.noteService
      .getSummary(this.id)
      .pipe(
        catchError(async () => this.loadErrorEvent.emit('note')),
        finalize(() => {
          this.loadingEvent.emit(false);
          this.loading = false;
        }),
      )
      .subscribe((data) => {
        if (!data) return;

        if (data?.noteAmount > 0) {
          data.noteAmount = data.noteAmount - 1;
        }
        this.configuration = data;
      });
  }
}
