import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  Inject,
  Input,
  OnInit,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import {
  ContextService,
  GlobalStore,
  OAuth2Service,
  SessionService,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import {
  OnboardingStore,
  APP_STORE_CONFIGURATION_URL,
  getDecodedAccessToken,
  OutlookSyncService,
  constructAppStoreParams,
  constructAppStoreForm,
} from '@maximizer/outlook/shared/data-access';
import { trigger, transition, style, animate } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { InsightsService } from '@maximizer/core/shared/insights';
import { GlobalServicesLoginDetails } from '@maximizer/core/shared/domain';

export interface MenuItem {
  id: string;
  text: string;
  click: (event: MouseEvent) => void;
  icon?: string;
  class?: string;
  disabled?: boolean;
}
@Component({
  selector: 'maximizer-outlook-menu',
  templateUrl: './menu.component.html',
  animations: [
    trigger('slideDown', [
      transition(':enter', [
        style({ height: '0', opacity: 0 }),
        animate('300ms ease-in-out', style({ height: '*', opacity: 1 })),
      ]),
    ]),
  ],
})
export class OutlookMenuComponent implements OnInit {
  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Input() pageTitle = '';
  @Input() pageType!: 'lead' | 'abentry' | 'none';
  @Input() id = '';
  @Input() showOnlyTitle = false;
  @Input() hideBack = false;
  @Input() hideRefresh = false;
  @Input() hideLogout = false;
  @Input() backRoutePath = '';
  @Input() forceWindowRefresh = false;

  menu: MenuItem[];
  deepLink = '';
  logInDetails?: GlobalServicesLoginDetails | null;
  outlookSyncInstalled = false;

  constructor(
    @Inject(APP_STORE_CONFIGURATION_URL) private appStoreUrl: string,
    private contextService: ContextService,
    private session: SessionService,
    private location: Location,
    private router: Router,
    private globalStore: GlobalStore,
    private oAuthService: OAuth2Service,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private domSanitizer: DomSanitizer,
    private insightsService: InsightsService,
    private outlookSyncService: OutlookSyncService,
    public onboardingStore: OnboardingStore,
  ) {
    this.menu = [
      {
        id: 'refresh',
        text: this.translate.instant('outlook.menu.refresh'),
        icon: 'fa-icons icon icon-solid icon-14 icon-refresh',
        click: () => this.refreshWindow(),
      },
      {
        id: 'maximizer',
        text: this.translate.instant('outlook.menu.maximizer'),
        icon: 'fa-icons icon icon-solid icon-14 icon-arrow-up-right-from-square',
        click: () => {
          if (!this.deepLink) this.deepLink = 'https://www.maximizer.com';
          window.open(this.deepLink, '_blank');
        },
      },
      {
        id: 'settings',
        text: this.translate.instant('outlook.menu.settings'),
        icon: 'fa-icons icon icon-solid icon-14 icon-arrow-up-right-from-square',
        click: () => this.buildAppStoreFormRequest(),
      },
      {
        id: 'logout',
        text: this.translate.instant('outlook.menu.logout'),
        icon: 'fa-icons icon icon-solid icon-14 icon-right-from-bracket',
        click: () => {
          this.logout();
          this.onboardingStore.showOnboarding(false);
        },
      },
      {
        id: 'appVersion',
        text: `${this.translate.instant('outlook.auth.app-version')} ${this.contextService.version}`,
        class: 'app-version',
        click: (event: MouseEvent) => {
          event.preventDefault();
        },
      },
    ];
  }

  ngOnInit(): void {
    this.logInDetails = this.oAuthService.getStorageLoginDetails();
    this.getOutlookSyncSettings();

    if (this.hideRefresh) this.menu = this.menu.slice(1);
    if (this.hideLogout) this.menu.pop();
    if (this.showOnlyTitle) {
      this.hideBack = true;
      this.menu = [];
    }

    if (this.contextService.token) {
      const session = this.globalStore.session();
      if (session?.alias) {
        this.populateDeeplink(session.alias);
      } else {
        this.session.getInfo().subscribe((data) => {
          this.populateDeeplink(data.alias);
        });
      }
    }
  }

  getOutlookSyncSettings(): void {
    const session = this.globalStore.session();

    if (session) {
      this.outlookSyncService
        .getConfiguration(session.user.id, session.workspace)
        .subscribe((config) => {
          this.outlookSyncInstalled = !!config?.enabled;
          if (!this.outlookSyncInstalled) {
            this.menu = this.menu.filter((item) => item.id !== 'settings');
          }
        });
    }
  }

  private populateDeeplink(alias: string): void {
    const urlArray = this.contextService.website.split('/');
    const domainIndex = urlArray.findIndex((o) =>
      o.toLocaleLowerCase().includes('maximizer'),
    );
    if (domainIndex == -1) return;
    let domain = '';
    for (let i = 0; i <= domainIndex; i++) {
      domain += urlArray[i] + '/';
    }
    const accountLink = domain?.replace('1', 'w') + alias;
    switch (this.pageType) {
      case 'lead':
        this.deepLink = `${accountLink}?sslead=KEY(${this.id})`;
        break;
      case 'abentry':
        this.deepLink = `${accountLink}?ss=KEY(${this.id})`;
        break;
      default:
        this.deepLink = accountLink;
        break;
    }
  }

  private isRouteValid(path: string): boolean {
    const configuredPaths = this.router.config.map((route) => `/${route.path}`);
    return configuredPaths.includes(path);
  }

  goBack(): void {
    if (this.backRoutePath) {
      if (this.isRouteValid(this.backRoutePath)) {
        this.router.navigate([this.backRoutePath]);
      } else {
        this.router.navigate(['/home']);
      }
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  logout(): void {
    this.oAuthService.clearAuth();
    this.session.clearSessionCache();
    this.contextService.clearAuthentication();
    this.sessionStorage.clear();

    const loginPath = '/login';
    const destinationUrl = window.location.origin + loginPath;

    if (destinationUrl.startsWith(window.location.origin)) {
      window.location.href = destinationUrl;
    }
  }

  private refreshWindow(): void {
    if (this.forceWindowRefresh) {
      const destinationUrl = `${window.location.origin}${window.location.pathname}`;
      window.location.href = destinationUrl;
      return;
    }

    const path = this.router.url.split('?')[0];
    const queryParamStr = this.router.url.split('?')[1];
    const queryParams = queryParamStr ? queryParamStr.split('&') : [];

    const url = self
      ? decodeURIComponent(this.router.url)
      : decodeURIComponent(this.location.path());
    const refreshUrl = url === '/' ? '../' : '/';

    this.router
      .navigateByUrl(refreshUrl, { skipLocationChange: false })
      .then(() => {
        const pathDecomposed = decodeURIComponent(path);
        const sanitizedQueryParams = this.sanitizeQueryParams(queryParams);
        const record = Object.fromEntries(
          sanitizedQueryParams.map((item) => {
            const [key, value] = item.split('=');
            return [key, decodeURIComponent(value)];
          }),
        ) as Record<string, string>;
        this.router.navigate([pathDecomposed], { queryParams: record });
      });
  }

  private sanitizeQueryParams(queryParams: string[]): string[] {
    return queryParams.map((param) => {
      const [key, value] = param.split('=');
      const sanitizedKey =
        this.domSanitizer.sanitize(SecurityContext.URL, key) || '';
      const sanitizedValue =
        this.domSanitizer.sanitize(SecurityContext.URL, value) || '';
      return `${sanitizedKey}=${sanitizedValue}`;
    });
  }

  buildAppStoreFormRequest(): void {
    const { alias, token, tenantId } = this.contextService;
    const session = this.globalStore.session();

    if (!session || !tenantId || !this.logInDetails) {
      return;
    }

    const urlParams = constructAppStoreParams(
      session,
      alias,
      token,
      tenantId,
      this.logInDetails,
      false,
    );

    if (!urlParams) {
      this.handleAppStoreFromError(
        'Menu-AppStore-Params',
        'Missing app store form parameters',
      );
      return;
    }

    const formDetails = constructAppStoreForm(urlParams, this.appStoreUrl);
    if (!formDetails) {
      this.handleAppStoreFromError(
        'Menu-AppStore-Form-Build',
        'Failed to create app store form',
      );
      return;
    }

    this.handleAppStoreFormSubmission(formDetails.form, formDetails.window);
  }

  handleAppStoreFormSubmission(
    appStoreForm: HTMLFormElement,
    newWindow: Window,
  ): void {
    try {
      newWindow.document.body.appendChild(appStoreForm);
      appStoreForm.submit();
      newWindow.focus();
    } catch (error) {
      const typedError = error as Error;
      this.handleAppStoreFromError(
        'Menu-AppStore-Form-Submit',
        typedError.message,
      );
    } finally {
      newWindow.document.body.removeChild(appStoreForm);
    }
  }

  handleAppStoreFromError(name: string, error: string) {
    this.notification.showMessage('error', 'outlook.errors.appStore', true);
    this.logDetailsToInsights(name, error);
    console.error(`An error occured opening the App Store page: ${error}`);
  }

  logDetailsToInsights(name: string, error: string): void {
    const customProperties = {
      error,
      decodedToken: getDecodedAccessToken(this.contextService.token),
      databaseAlias: this.globalStore.session()?.alias,
      eventId: 'app-store-request',
    };

    this.insightsService.trackEvent(
      { name },
      SeverityLevel.Information,
      customProperties,
    );
  }
}
