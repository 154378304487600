/* eslint-disable @angular-eslint/directive-selector */
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: 'kendo-dialog',
  standalone: false,
})
export class KendoDialogDirective implements AfterViewInit {
  @Input() public showOverlay = true;

  constructor(private readonly element: ElementRef) {}

  ngAfterViewInit(): void {
    if (!this.showOverlay) {
      this.element.nativeElement.querySelector('.k-overlay').remove();
    }
  }
}
