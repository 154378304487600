import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { NumberField } from '@maximizer/core/shared/domain';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';

@Component({
  selector: 'maximizer-number-field',
  template: `
    <kendo-formfield
      [formGroup]="form.control"
      [ngStyle]="field.containerStyle"
      [ngClass]="[field.labelPosition ?? 'left', field.containerClass ?? '']"
    >
      <kendo-label
        maximizerFieldLabel
        [field]="field"
        [for]="input"
        [required]="field.required ?? false"
      >
      </kendo-label>
      <kendo-numerictextbox
        #input
        [formControlName]="field.id"
        [ngClass]="field.cssClass"
        [autoCorrect]="true"
        [format]="format"
        [step]="field.step ?? 1"
        [spinners]="(field.showSpinners ?? true) && !field.readonly"
        [readonly]="field.readonly"
        [decimals]="field.decimals ?? 0"
        [placeholder]="field.placeholder ?? ''"
        [min]="field.min!"
        [max]="field.max!"
      ></kendo-numerictextbox>
      <kendo-formhint *ngIf="field.hint">{{ field.hint }}</kendo-formhint>
      <kendo-formerror
        >{{
          'JSS_GenTxtPleaseEnter' | resourceOrTranslate: 'error.enterField'
        }}
        {{ field.name }}</kendo-formerror
      >
    </kendo-formfield>
  `,
  styles: [
    `
      :host {
        @apply w-full;
        ::ng-deep {
          .k-numerictextbox {
            &.k-readonly {
              @apply cursor-text border-none hover:shadow-none focus:shadow-none focus-within:shadow-none;
            }
          }
        }
      }
    `,
  ],
  standalone: false,
})
export class NumberFieldComponent implements OnChanges {
  @Input({ required: true })
  field!: NumberField;

  @ViewChild('input')
  input!: NumericTextBoxComponent;

  format?: string | NumberFormatOptions;
  readonly minValue = Number.MIN_VALUE;

  constructor(public form: FormGroupDirective) {}

  ngOnChanges(): void {
    if (this.field.currency) {
      this.format = {
        style: 'currency',
        currency: this.field.currency,
        currencyDisplay: this.field.readonly ? 'code' : 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: this.field.decimals ?? 0,
      };
    } else {
      this.format = this.field.decimals ? 'n' + this.field.decimals : 'n0';
    }
  }
}
