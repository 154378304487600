import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'maximizer-outlook-skeleton',
  styles: `
    :host {
      display: flex;
    }
    .skeleton-line {
      width: 100%;
    }
    .placeholder {
      position: relative;
      display: flex;
    }
    .shimmer {
      overflow: hidden;
      position: relative;
    }

    .shimmer::before {
      content: '';
      position: absolute;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      height: 100%;
      width: 100%;
      z-index: 1;
      animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
  `,
  template: `<div class="placeholder shimmer" [ngClass]="containerClasses">
    <div class="skeleton-line"></div>
  </div>`,
  standalone: false,
})
export class OutlookLineSkeletonComponent {
  @HostBinding('class')
  get themeClass() {
    return this.fullWidth == 'none' ? '' : 'w-full';
  }

  @Input() containerClasses = '';
  @Input() fullWidth: 'full' | 'none' = 'full';
}
