import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainDetailsComponent } from './main-details/main-details.component';
import { SubDetailsComponent } from './sub-details/sub-details.component';
import { SystemInformationComponent } from './system-information/system-information.component';

@NgModule({
  imports: [CommonModule],
  exports: [
    MainDetailsComponent,
    SubDetailsComponent,
    SystemInformationComponent,
  ],
  declarations: [
    MainDetailsComponent,
    SubDetailsComponent,
    SystemInformationComponent,
  ],
})
export class UiDetailsModule {}
