import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  setData,
  Locale,
  CldrIntlService,
  IntlService,
} from '@progress/kendo-angular-intl';
import {
  CDN_URL,
  DateFormatType,
  DateValue,
  NullableDateValue,
} from '@maximizer/core/shared/domain';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { CountryListItem } from '../models/country.model';
import { ContextService } from './context.service';
import { isString } from '@progress/kendo-angular-common';
import { formatISO, isValid, parseISO } from 'date-fns';
import { GlobalStore } from '../state';
import { InterpolationParameters, TranslateParser } from '@ngx-translate/core';
import { isObject } from '@maximizer/shared/util';

declare const LOAD_UID: string;

@Injectable()
export class LocaleService {
  readonly defaultLocale = 'en-US';
  readonly url = '{0}/assets/locales/{1}/all.json';
  readonly intl: CldrIntlService;
  private locales: { [key: string]: Locale } = {};

  constructor(
    intl: IntlService,
    private readonly http: HttpClient,
    private readonly context: ContextService,
    private readonly global: GlobalStore,
    private readonly parser: TranslateParser,
    @Inject(CDN_URL) public cdn: string,
  ) {
    this.intl = intl as CldrIntlService;
  }

  public set(localeId: string): Observable<Locale> {
    if (this.locales[localeId]) {
      this.intl.localeId = localeId;
      return of(this.locales[localeId]);
    }

    const url = this.intl.format(this.url, this.cdn, localeId);
    return this.http.get(url).pipe(
      tap((result) => {
        setData(result);

        this.locales[localeId] = result;
        this.intl.localeId = localeId;
      }),
      catchError(() => {
        if (localeId.indexOf('-') > -1) {
          return this.set(localeId.split('-')[0]);
        }
        return this.set(this.defaultLocale);
      }),
    );
  }

  get localeId(): string {
    return this.intl.localeId;
  }

  get countryCode() {
    return this.locales[this.localeId].territory ?? '';
  }

  get data(): Locale {
    return this.locales[this.localeId];
  }

  getCountries(): Observable<CountryListItem[]> {
    const version = this.context.version ?? LOAD_UID;

    return this.http
      .get<
        { code: string; name: string; flag_4x3: string }[]
      >(`${this.cdn}/assets/resources/country.json?v=${version}`)
      .pipe(
        map((countries) => {
          return countries.map(({ name, code, flag_4x3 }) => ({
            name,
            id: code,
            flag: flag_4x3,
          }));
        }),
      );
  }

  formatDate(date: NullableDateValue, format: DateFormatType): string {
    if (!date) {
      return '';
    }

    switch (format) {
      case 'isoDate':
        return this.formatISODate(date);
      case 'isoDateTime':
        return this.formatISODateTime(date);
      case 'userDate':
        return this.formatDateUserLocale(date);
      case 'userTime':
        return this.formatTimeUserLocale(date);
      case 'userDateTime':
        return `${this.formatDateUserLocale(date)} ${this.formatTimeUserLocale(date)}`;
      default:
        if (isString(date)) {
          date = parseISO(date);
        }

        if (typeof date === 'number') {
          date = new Date(date);
        }

        if (date && isValid(date)) {
          return this.intl.formatDate(date, format);
        }
        return '';
    }
  }

  formatISODateTime(date: DateValue): string {
    return this.formatISODate(date);
  }

  formatISODate(date: DateValue): string {
    return formatISO(date, { representation: 'date' });
  }

  formatTimeUserLocale(date: NullableDateValue): string {
    return this.formatDate(date, this.context.timeFormat ?? 't');
  }

  formatDateUserLocale(date: NullableDateValue): string {
    return this.formatDate(date, this.context.dateFormat ?? 'd');
  }

  formatCurrency(value?: number | null): string {
    if (value === null || value === undefined) {
      return '';
    }

    const multiCurrency =
      this.global.session()?.systemConfiguration?.multiCurrency;
    if (multiCurrency) {
      const currency =
        this.global.session()?.systemConfiguration.corporateCurrency;
      return `${currency} ${this.intl.formatNumber(value, 'n')}`;
    }
    return this.intl.formatNumber(value, 'c');
  }

  format(format: string, ...args: unknown[]): string {
    if (
      !this.useFormat(format) &&
      args &&
      args.length > 0 &&
      isObject(args[0])
    ) {
      return (
        this.parser.interpolate(format, args[0] as InterpolationParameters) ??
        ''
      );
    }

    return this.intl.format(format, ...args);
  }

  private useFormat(rawString: string): boolean {
    return rawString.includes('{0}');
  }
}
