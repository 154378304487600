import {
  ReadRequest,
  Request,
  RequestConfiguration,
  WriteRequest,
} from './request';
import { DataResponse, Response, WriteResponse } from './response';
import { EntityType } from './entity-type';
import { LogicalQuery, Query } from './criteria';

export interface NotificationSetup {
  Key: string | null;
  EntityType: EntityType;
  Type: NotificationType;
  Trigger: NotificationTrigger;
  Status: NotificationStatus;
  Name: string;
  Tag: string;
  Description: string;
  NotifySource: boolean;
  Recipients: [
    {
      Key: {
        UID: string | undefined;
      };
    },
  ];
  Targets: [
    {
      Key: {
        ID: number;
      };
      Data: string;
    },
  ];
  Creator: string;
  CreationDate: string;
  Criteria: Query<unknown> | LogicalQuery<unknown>;
  SecAccess: {
    Write: [
      {
        Key: {
          Uid: string;
        };
      },
    ];
    Read: [
      {
        Key: {
          Uid: string;
        };
      },
    ];
  };
}

export enum NotificationSetupTag {
  ExportAlertAbEntry = 'mx_export_alert_abentry',
  ExportAlertOpportunity = 'mx_export_alert_opps',
  FollowOpportunity = 'mx_follow_opp',
}

export enum NotificationType {
  NotificationManage = 1,
  NotificationService = 2,
}

export enum NotificationTrigger {
  New = 1,
  Update = 2,
  Read = 3,
}

export enum NotificationStatus {
  Disabled = 0,
  Enabled = 1,
  Service = 2,
}

export interface NotificationSetupReadRequest extends Request {
  NotificationSetup: ReadRequest<NotificationSetup>;
}

export interface NotificationSetupResponse extends Response {
  NotificationSetup: DataResponse<NotificationSetup>;
}

export interface NotificationSetupWriteRequest extends Request {
  NotificationSetup: WriteRequest<NotificationSetup>;
}

export interface NotificationSetupWriteResponse extends Response {
  NotificationSetup: WriteResponse<NotificationSetup>;
}

export const NotificationSetupReadDriver: RequestConfiguration = {
  Drivers: {
    IUserSearcher: 'Maximizer.Model.Access.Sql.NotificationSetupSearcher',
  },
};
