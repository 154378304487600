import {
  GlobalServicesLoginDetails,
  Session,
} from '@maximizer/core/shared/domain';
import { AppStoreRedirectParams } from '../models';
import {
  buildApiWebRequest,
  getDecodedAccessToken,
  mapDataCenterFromToken,
} from './datacenter.util';
import { InsightsService } from '@maximizer/core/shared/insights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export function constructAppStoreParams(
  session: Session,
  alias: string,
  token: string,
  tenantId: string,
  logInDetails: GlobalServicesLoginDetails,
  install: boolean,
  insightsService: InsightsService,
): string | undefined {
  const dataCenterId = mapDataCenterFromToken(token);

  if (!dataCenterId) {
    console.error('Could not resolve dataCenterId');
    insightsService.trackEvent(
      { name: 'App-Store-Util-Missing-DataCenterId' },
      SeverityLevel.Information,
      {
        eventId: 'app-store-request',
        decodedToken: getDecodedAccessToken(token),
      },
    );
    return undefined;
  }

  const params: AppStoreRedirectParams = {
    webApiUrlRequest: buildApiWebRequest(dataCenterId, alias),
    userId: session.user.id,
    alias,
    dataCenterId,
    customerId: tenantId ?? '',
    tenantPlatform: 0,
    tenantName: logInDetails?.tenant.name,
    tenantKey: session.database,
    workspace: session.workspace,
    maxToken: token,
  };

  if (install) {
    delete params.maxToken;
  }

  const missingParams = Object.entries(params)
    .filter(([_, value]) => [null, undefined, ''].includes(value))
    .map(([key]) => key);

  if (missingParams.length > 0) {
    console.error('Missing parameters for App Store request:', missingParams);
    insightsService.trackEvent(
      { name: 'App-Store-Util-Missing-Parameters' },
      SeverityLevel.Information,
      {
        eventId: 'app-store-request',
        missingParams,
        params,
        decodedToken: getDecodedAccessToken(token),
      },
    );
    return undefined;
  }

  return JSON.stringify(params);
}

export function constructAppStoreForm(
  token: string,
  params: string,
  url: string,
  insightsService: InsightsService,
): {
  form: HTMLFormElement;
  window: Window;
} | null {
  const newWindow = window.open('', '_blank');

  if (!newWindow) {
    console.error('Failed to open new window.');
    insightsService.trackEvent(
      { name: 'App-Store-Util-Open-Window-Fail' },
      SeverityLevel.Information,
      {
        eventId: 'app-store-request',
        decodedToken: getDecodedAccessToken(token),
      },
    );
    return null;
  }

  const appStoreForm = newWindow.document.createElement('form');
  appStoreForm.method = 'POST';
  appStoreForm.action = url;

  try {
    const dataParametersInput = newWindow.document.createElement('input');
    dataParametersInput.type = 'hidden';
    dataParametersInput.name = 'DataParameters';
    dataParametersInput.value = params;

    appStoreForm.appendChild(dataParametersInput);
    newWindow.document.body.appendChild(appStoreForm);
  } catch (error) {
    console.error('Error constructing form:', error);
    insightsService.trackEvent(
      { name: 'App-Store-Util-Form-Construct-Fail' },
      SeverityLevel.Information,
      {
        eventId: 'app-store-request',
        decodedToken: getDecodedAccessToken(token),
        error,
      },
    );
    return null;
  }

  return { form: appStoreForm, window: newWindow };
}
