import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from './context.service';
import { map, Observable, of } from 'rxjs';
import {
  Octopus,
  Entity,
  AccessDeniedError,
  SearchAddress,
} from '@maximizer/core/shared/domain';

@Injectable()
export class AddressService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  get readDriver(): Octopus.RequestConfiguration {
    return Octopus.AddressReadDriver;
  }
  private getRequest(
    query:
      | Octopus.SearchQuery<Octopus.Address>
      | Octopus.LogicalQuery<Octopus.Address>,
  ): Octopus.AddressReadRequest {
    const fields: Octopus.Scope<Octopus.Address> = {
      Key: {
        Value: 1,
        ID: 1,
        Number: 1,
        EntityType: 1,
      },
      ParentKey: 1,
      Default: 1,
      Description: 1,
      AddressLine1: 1,
      AddressLine2: 1,
      City: 1,
      StateProvince: 1,
      Country: 1,
      ZipCode: 1,
      DisplayValue: 1,
      Location: {
        Latitude: 1,
        Longitude: 1,
      },
    };

    return {
      Address: {
        Scope: {
          Fields: fields,
        },
        Criteria: {
          SearchQuery: query,
        },
      },
      Globalization: Octopus.DefaultGlobalization,
      Configuration: this.readDriver,
    };
  }

  getQuery(
    params: SearchAddress,
  ):
    | Octopus.SearchQuery<Octopus.Address>
    | Octopus.LogicalQuery<Octopus.Address> {
    if (params.parentKey && params.isDefault) {
      const findDefaultQuery: Octopus.LogicalQuery<Octopus.Address> = {
        $AND: [
          {
            ParentKey: {
              $EQ: params.parentKey,
            },
          },
          {
            Default: {
              $EQ: params.isDefault,
            },
          },
        ],
      };
      return findDefaultQuery;
    } else if (params.parentKey && params.phraseSearchString) {
      const findDefaultQuery: Octopus.LogicalQuery<Octopus.Address> = {
        $AND: [
          {
            ParentKey: {
              $EQ: params.parentKey,
            },
          },
          {
            $PHRASE: params.phraseSearchString,
          },
        ],
      };
      return findDefaultQuery;
    } else if (params.parentKey) {
      return {
        ParentKey: {
          $EQ: params.parentKey,
        },
      };
    }
    return {
      Key: {
        $EQ: params.key,
      },
    };
  }

  read(params: SearchAddress): Observable<Entity[]> {
    if (!params.key && !params.parentKey) {
      return of([]);
    }
    const query:
      | Octopus.SearchQuery<Octopus.Address>
      | Octopus.LogicalQuery<Octopus.Address> = this.getQuery(params);

    const request = this.getRequest(query);
    request.Configuration = this.readDriver;

    return this.http
      .post<Octopus.EntityResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((result) => {
          if (result.Code === 0) {
            return result.Address?.Data as Entity[];
          }
          return [];
        }),
      );
  }

  save(keyField: string, address: Entity): Observable<string | null> {
    const request: Octopus.AddressWriteRequest = {
      Address: {
        Data: address,
      },
    };

    return this.http
      .post<Octopus.AddressWriteResponse>(
        `${this.context.api}${
          address[keyField] ? Octopus.Action.UPDATE : Octopus.Action.CREATE
        }`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((response) => {
          if (response.Code === Octopus.ResponseStatusCode.Successful) {
            return (response.Address?.Data.Key as string) ?? null;
          } else if (
            response.Code === Octopus.ResponseStatusCode.AccessDenied
          ) {
            throw new AccessDeniedError();
          }
          return null;
        }),
      );
  }

  create(addresses: Entity[]): Observable<boolean> {
    const request: { [name: string]: unknown } = {};

    if (addresses) {
      addresses.forEach(
        (address, index) => (request['Address.' + index] = { Data: address }),
      );
    }

    return this.http
      .post<Octopus.AddressWriteResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((response) => {
          if (response.Code === Octopus.ResponseStatusCode.Successful) {
            return true;
          }
          return false;
        }),
      );
  }

  delete(addressKeys: string[]): Observable<boolean> {
    const request: { [name: string]: unknown } = {};

    if (addressKeys) {
      addressKeys.forEach(
        (key, index) => (request['Address.' + index] = { Data: { Key: key } }),
      );
    }

    return this.http
      .post<Octopus.Response>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }

  private getRequestHeaders(): { [header: string]: string } {
    return {
      'max-insights-entity': 'Address',
    };
  }
}
