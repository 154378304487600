@if (loading.spinner) {
  <maximizer-loader type="legacy"></maximizer-loader>
}

<div class="flex mt-3">
  @if (loading.skeleton || loading.sync) {
    <div class="flex flex-col w-full mx-2">
      <maximizer-outlook-skeleton
        containerClasses="h-10 w-full rounded-md bg-neutral-20 px-10 mt-2"
      ></maximizer-outlook-skeleton>
    </div>
  } @else if (showSaveEmailData) {
    <div class="flex flex-col">
      <!-- Save and Send Toggle -->
      <span
        kendoTooltip
        [tooltipTemplate]="linkToAppStoreTooltip"
        [tooltipWidth]="250"
        [callout]="false"
        position="top"
        [closable]="true"
        [showOn]="outlookSyncInstalled ? 'none' : 'click'"
      >
        <kendo-switch
          #switch
          id="outlook-main-ui-compose-saveToEmail-sendSaveToggle"
          [disabled]="!outlookSyncInstalled"
          [(ngModel)]="saveToEmailSwitch"
          (valueChange)="handleSwitchValueChanged($event)"
          size="small"
          title=""
        ></kendo-switch>
      </span>
    </div>

    <ng-template #linkToAppStoreTooltip>
      <maximizer-outlook-sync-install
        (closeTooltips)="closeTooltips()"
      ></maximizer-outlook-sync-install>
    </ng-template>

    <div class="flex flex-col w-full ml-2">
      <kendo-label for="switch" class="w-full">
        {{ 'outlook.compose.multiselect.save-to' | translate }}
      </kendo-label>
      <!-- Recipients List -->
      <kendo-multiselecttree
        #multiselecttree
        id="outlook-main-ui-compose-saveToEmail-recipients"
        kendoMultiSelectTreeExpandable
        kendoMultiSelectTreeSummaryTag
        class="w-full mt-2 compose-entry-multiselect"
        [disabled]="!saveToEmailSwitch || !outlookSyncInstalled"
        [placeholder]="
          'outlook.compose.multiselect.recipients-emptystate' | translate
        "
        [clearButton]="false"
        [value]="emailsToSaveInMaximizer"
        [popupSettings]="{
          height: 120,
          width: 220,
          popupClass: 'overflow-x-hidden',
        }"
        [checkableSettings]="{ checkChildren: true, checkOnClick: true }"
        [isNodeExpanded]="expandParentNode"
        [itemDisabled]="isCheckboxDisabled || !outlookSyncInstalled"
        (valueChange)="handleMultiselectTreeChange($event)"
        [kendoMultiSelectTreeHierarchyBinding]="emailListForMultiselect"
        textField="displayName"
        valueField="localId"
        childrenField="items"
      >
        <ng-template
          kendoMultiSelectTreeNodeTemplate
          let-dataItem
          let-index="index"
        >
          <div
            class="max-[360px]:max-w-[140px] max-[420px]:max-w-[240px] text-ellipsis overflow-x-hidden cursor-pointer"
          >
            @if (parentItems.includes(dataItem.localId)) {
              {{ dataItem.displayName }}
            } @else {
              <maximizer-outlook-entry-list-item
                [dataItem]="dataItem"
              ></maximizer-outlook-entry-list-item>
            }
          </div>
        </ng-template>
        <ng-template
          kendoMultiSelectTreeGroupTagTemplate
          let-dataItems
          class="flex w-full"
        >
          {{
            'outlook.compose.multiselect.recipients-number'
              | translate
                : {
                    amount: countSelectedItemsIgnoringParent(dataItems),
                    total: saveEmailToEntriesTotal,
                  }
          }}
        </ng-template>
      </kendo-multiselecttree>
    </div>
  } @else if (isOutlookLegacyDesktop) {
    <!-- Message for outlook desktop legacy users -->
    <div class="flex flex-col">
      <div class="rounded-sm p-3 mb-2 bg-yellow-5">
        <p class="text-body">
          {{ 'outlook.compose.classic-unavailable' | translate }}
        </p>
      </div>
      <p class="text-body">
        {{
          (isEmailSyncFeatureFlagEnabled
            ? 'outlook.compose.classic-available-alternative'
            : 'outlook.compose.email-sync-upcoming'
          ) | translate
        }}
      </p>
    </div>
  } @else if (loading.earlyAccess && !isEmailSyncFeatureFlagEnabled) {
    <maximizer-outlook-skeleton
      class="pb-2"
      containerClasses="h-6 w-full rounded-md bg-neutral-20"
      fullWidth="full"
    ></maximizer-outlook-skeleton>
  } @else if (showEarlyAccessRequest && !isEmailSyncFeatureFlagEnabled) {
    <maximizer-outlook-sync-early-access
      (showEarlyAccessRequest)="showEarlyAccessRequest = $event"
    ></maximizer-outlook-sync-early-access>
  } @else if (!showEarlyAccessRequest && !isEmailSyncFeatureFlagEnabled) {
    <p class="text-body">
      {{ 'outlook.sync.earlyAccess.comingSoon' | translate }}
    </p>
  }
</div>

<maximizer-outlook-notification #notification [closable]="true">
</maximizer-outlook-notification>
