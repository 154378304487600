import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  NotificationRef,
  NotificationService,
} from '@progress/kendo-angular-notification';
import {
  OutlookNotificationAction,
  OutlookNotificationResource,
  OutlookNotificationType,
} from './notification.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'maximizer-outlook-notification',
  template: `<ng-template #template>
    <div class="flex">
      <span
        *ngIf="icon"
        class="mr-2 fa-icons icon-solid icon-12 mt-0.5"
        [class]="'icon-' + icon"
      ></span>
      <span>
        {{ content }}
        {{ type === 'error' ? resource?.errorSubtitle : '' }}
      </span>
    </div>
  </ng-template>`,
  standalone: false,
})
export class OutlookNotificationComponent {
  @Input()
  icon?: string;

  @Input()
  horizontal: 'left' | 'center' | 'right' = 'center';

  @Input()
  vertical: 'top' | 'bottom' = 'top';

  @Input()
  animation?: 'slide' | 'fade';

  @Input()
  hideAfter = 4000;

  @Input()
  closable?: boolean;

  @Input()
  appendTo?: ViewContainerRef;

  @Input()
  entryId: string | undefined;

  @Input()
  set resourceId(id: string | undefined) {
    if (!id) {
      this.resource = this.fallbackNotifications;
      return;
    }
    this.resource = this.translate.instant(id) as OutlookNotificationResource;
    if (!this.resource) {
      this.resource = this.fallbackNotifications;
    }
  }

  resource: OutlookNotificationResource | null = null;
  type: OutlookNotificationType = 'none';

  @ViewChild('template', { read: TemplateRef })
  template: TemplateRef<unknown> | undefined;

  lastNotification?: NotificationRef | null;
  content = '';

  private _lastTimeout?: number;
  private readonly fallbackNotifications = {
    saveSuccess: 'Saved successfully',
    saveError: 'Failed to save.',
    loadError: 'Failed to load.',
    createError: 'Failed to create.',
    createSuccess: 'Created successfully',
    addError: 'Failed to add.',
    addSuccess: 'Added successfully',
    errorSubtitle: 'Please try again later',
  };

  constructor(
    private readonly service: NotificationService,
    private readonly translate: TranslateService,
  ) {}

  show(
    action: OutlookNotificationAction,
    type: OutlookNotificationType,
    toLowerCase = true,
  ): NotificationRef | null {
    if (this.template) {
      this.lastNotification?.hide();
      clearInterval(this._lastTimeout);

      this.getEntryIdTranslation(toLowerCase);

      this.type = type;
      this.content = this.getNotificationMessage(action);

      this.lastNotification = this.service.show({
        content: this.template,
        appendTo: this.appendTo,
        animation: { type: this.animation, duration: 500 },
        position: { horizontal: this.horizontal, vertical: this.vertical },
        type: { style: type, icon: false },
        closable: this.closable,
        width: 300,
      });

      this.hidePreviousNotification();
      return this.lastNotification;
    }
    return null;
  }

  showMessage(
    type: OutlookNotificationType,
    message: string,
    translate = false,
  ): NotificationRef | null {
    if (this.template) {
      this.lastNotification?.hide();
      clearInterval(this._lastTimeout);

      this.type = type;
      this.content = message;
      if (translate) {
        this.content = this.translate.instant(message);
      }
      if (type == 'success') {
        this.icon = 'circle-check';
      } else {
        this.icon = 'circle-exclamation';
      }

      this.lastNotification = this.service.show({
        content: this.template,
        appendTo: this.appendTo,
        animation: { type: this.animation, duration: 500 },
        position: { horizontal: this.horizontal, vertical: this.vertical },
        type: { style: type, icon: false },
        closable: this.closable,
        width: 300,
      });

      this.hidePreviousNotification();
      return this.lastNotification;
    }
    return null;
  }

  private hidePreviousNotification() {
    this._lastTimeout = setTimeout(() => {
      if (this.lastNotification) {
        this.lastNotification.hide();
      }
    }, this.hideAfter);
  }

  private getEntryIdTranslation(toLowerCase: boolean) {
    if (!this.entryId) {
      this.resource = this.fallbackNotifications;
      return;
    }
    const notificationTemplate = this.translate.instant('outlook.notification');
    let entry = this.translate.instant(
      'outlook.entries.' + this.entryId?.toLowerCase(),
    );
    this.resource = {} as OutlookNotificationResource;

    Object.entries<string>(notificationTemplate).forEach(([key, value]) => {
      if (this.resource == null) return;

      if (toLowerCase) entry = entry.toLocaleLowerCase();
      const translation = value.replace('{{type}}', entry);
      this.resource[key as keyof OutlookNotificationResource] = translation;
    });

    if (!this.resource) {
      this.resource = this.fallbackNotifications;
    }
  }
  private getNotificationMessage(action: OutlookNotificationAction): string {
    if (this.type == 'error') {
      this.icon = 'circle-exclamation';
      switch (action) {
        case 'load':
          return (
            this.resource?.loadError ?? this.fallbackNotifications.loadError
          );
        case 'save':
          return (
            this.resource?.saveError ?? this.fallbackNotifications.saveError
          );
        case 'create':
          return (
            this.resource?.createError ?? this.fallbackNotifications.createError
          );
        case 'add':
          return this.resource?.addError ?? this.fallbackNotifications.addError;
        default:
          return (
            this.resource?.errorSubtitle ??
            this.fallbackNotifications.errorSubtitle
          );
      }
    }

    this.icon = 'circle-check';
    switch (action) {
      case 'add':
        return (
          this.resource?.addSuccess ?? this.fallbackNotifications.addSuccess
        );
      case 'create':
        return (
          this.resource?.createSuccess ??
          this.fallbackNotifications.createSuccess
        );
      default:
        return (
          this.resource?.saveSuccess ?? this.fallbackNotifications.saveSuccess
        );
    }
  }
}
