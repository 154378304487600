<maximizer-loader type="legacy" *ngIf="loading"></maximizer-loader>

<div class="w-full h-full bg-white flex flex-col px-6">
  <div class="mt-auto mx-auto mb-8 pr-6">
    <img
      class="w-[180px]"
      [src]="cdn + '/assets/images/outlook/MaximizerLogo_Text.png'"
      alt="Maximizer logo"
    />
  </div>
  <form
    *ngIf="currentStep === 1 && emailForm"
    class="mb-auto mx-auto flex flex-col"
    [formGroup]="emailForm.group"
    (ngSubmit)="proceed()"
  >
    <span class="text-body">{{
      'outlook.auth.login.email-label' | translate
    }}</span>
    <kendo-formfield showErrors="initial">
      <kendo-textbox
        id="outlook-auth-ui-loginEmail-email"
        [maxlength]="255"
        #email
        formControlName="email"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="emailForm.controls.email.errors"
        [fieldTranslation]="'outlook.auth.login.email'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <span class="text-body mt-4">{{
      'outlook.auth.login.email-description' | translate
    }}</span>
    <button
      kendoButton
      id="outlook-auth-ui-loginEmail-step1-proceed"
      type="submit"
      class="w-[200px] mt-8 mx-auto"
      themeColor="primary"
      (click)="proceed()"
    >
      {{ 'outlook.auth.login.proceed-btn' | translate }}
    </button>
    <button
      kendoButton
      id="outlook-auth-ui-loginEmail-step1-back"
      (click)="goBack()"
      class="w-[200px] mt-2 mx-auto"
      themeColor="primary"
      fillMode="flat"
    >
      {{ 'outlook.auth.login.goback-btn' | translate }}
    </button>
  </form>

  <div
    *ngIf="currentStep === 2 && dbForm"
    class="mb-auto mx-auto flex flex-col"
  >
    <span class="text-body">{{
      'outlook.auth.login.database' | translate
    }}</span>
    <kendo-dropdownlist
      id="outlook-auth-ui-loginEmail-database"
      [data]="dropdownDbs"
      textField="text"
      valueField="tenantId"
      [(ngModel)]="selectedDatabase"
    ></kendo-dropdownlist>

    <span class="text-body mt-4">{{
      'outlook.auth.login.database-description' | translate
    }}</span>
    <button
      kendoButton
      id="outlook-auth-ui-loginEmail-step2-proceed"
      class="w-[200px] mt-8 mx-auto"
      themeColor="primary"
      (click)="navigateToOAuth2()"
    >
      {{ 'outlook.auth.login.proceed-btn' | translate }}
    </button>
    <button
      kendoButton
      id="outlook-auth-ui-loginEmail-step2-back"
      (click)="goBack()"
      class="w-[200px] mx-auto mt-2"
      themeColor="primary"
      fillMode="flat"
    >
      {{ 'outlook.auth.login.goback-btn' | translate }}
    </button>
  </div>
</div>
