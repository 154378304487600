import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageService {
  private readonly storage = sessionStorage;

  get isEnabled(): boolean {
    return this.storage !== undefined && this.storage !== null;
  }

  set(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  get(key: string): string | null {
    return this.storage.getItem(key);
  }

  remove(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }
}
