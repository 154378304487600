import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GlobalStore } from '@maximizer/core/shared/data-access';
import { UserRights } from '@maximizer/core/shared/domain';
import { InsightsService } from '@maximizer/core/shared/insights';
import {
  OutlookAbEntryDetails,
  OutlookLeadSearch,
} from '@maximizer/outlook/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';

@Component({
  selector: 'maximizer-entry-summary',
  templateUrl: './entry-summary.component.html',
  standalone: false,
})
export class EntrySummaryComponent implements OnInit {
  @Input() abentry?: OutlookAbEntryDetails;
  @Input() lead?: OutlookLeadSearch;
  @Input() id = '';
  @Input() type = '';

  @Input() deepLink?: string;
  @Input() oppLink?: string;

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  loadingTasks = true;
  loadingNotes = true;
  loadingAppointments = true;
  loadingOpportunities = true;
  loading = true;
  userRights?: UserRights;

  constructor(
    private readonly insights: InsightsService,
    public globalStore: GlobalStore,
  ) {}

  ngOnInit(): void {
    if (this.globalStore.session()?.user) {
      this.userRights = this.globalStore.session()?.user.rights;
    }
  }

  showNotification(event: string) {
    this.insights.trackException({
      exception: new Error('Failed to load ' + event),
    });
    if (this.notification.lastNotification) return;
    this.notification.show('load', 'error');
  }

  setLoading(event: string) {
    switch (event) {
      case 'task':
        this.loadingTasks = false;
        break;
      case 'note':
        this.loadingNotes = false;
        break;
      case 'appointment':
        this.loadingAppointments = false;
        break;
      case 'opportunity':
        this.loadingOpportunities = false;
        break;
      default:
        break;
    }
    if (this.abentry) {
      this.loading =
        this.loadingTasks ||
        this.loadingNotes ||
        this.loadingAppointments ||
        this.loadingOpportunities;
    } else {
      this.loading =
        this.loadingTasks || this.loadingNotes || this.loadingAppointments;
    }
  }
}
