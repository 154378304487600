import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ContextService } from './context.service';
import { Octopus, NotificationSetup } from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import { EXPORT_NOTIFICATION, WEBHOOK_ID } from '../data-access.module';

@Injectable()
export class NotificationSetupService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
    @Inject(EXPORT_NOTIFICATION)
    private readonly exportRowsNotification: number,
    @Inject(WEBHOOK_ID) private readonly webhookRecipientId: number,
  ) {}

  createExportNotification(
    recipient: string,
    entityType: Octopus.EntityType,
  ): Observable<boolean> {
    const name =
      entityType === Octopus.EntityType.AbEntry
        ? 'AddressBook export alert'
        : 'Opportunities export alert';
    const query: NotificationSetup = {
      key: null,
      name: name,
      tag: this.getExportNotificationTag(entityType),
      entityType: entityType,
      recipientUid: recipient,
    };

    const request = this.getExportNotificationRequest(query);

    return this.http
      .post<Octopus.NotificationSetupWriteResponse>(
        this.context.api + Octopus.Action.CREATE,
        request,
      )
      .pipe(map(this.mapCreateNotificationResponse));
  }

  private mapCreateNotificationResponse(
    result: Octopus.NotificationSetupWriteResponse,
  ): boolean {
    if (
      result.Code === Octopus.ResponseStatusCode.Successful &&
      result.NotificationSetup?.Data
    ) {
      return true;
    }
    return false;
  }

  createFollowNotification(
    parentKey: string,
    recipient: string,
    entityType: Octopus.EntityType,
  ): Observable<string | null> {
    const name = 'Follow Opportunity';
    const query: NotificationSetup = {
      key: null,
      name: name,
      tag: this.getFollowNotificationTag(),
      entityType: entityType,
      parentKey: parentKey,
      recipientUid: recipient,
    };

    const request = this.getFollowNotificationRequest(query);

    return this.http
      .post<Octopus.NotificationSetupWriteResponse>(
        this.context.api + Octopus.Action.CREATE,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.NotificationSetup?.Data
          ) {
            return result.NotificationSetup.Data.Key;
          }
          return null;
        }),
      );
  }

  removeNotification(key: string): Observable<boolean> {
    const request: Octopus.NotificationSetupWriteRequest = {
      NotificationSetup: {
        Data: {
          Key: key,
        },
      },
      Configuration: Octopus.NotificationSetupReadDriver,
    };

    return this.http
      .post<Octopus.NotificationSetupWriteResponse>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            return true;
          }
          return false;
        }),
      );
  }

  readNotificationList(
    criteria: Octopus.Criteria<Octopus.NotificationSetup>,
  ): Observable<NotificationSetup[]> {
    const request: Octopus.NotificationSetupReadRequest = {
      NotificationSetup: {
        Criteria: criteria,
        Scope: {
          Fields: {
            Key: 1,
            Name: 1,
            Tag: 1,
          },
        },
      },
      Configuration: Octopus.NotificationSetupReadDriver,
    };

    return this.http
      .post<Octopus.NotificationSetupResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.NotificationExportConfigurationMapper();
            return result.NotificationSetup.Data.map((notification) =>
              mapper.from(notification),
            );
          }
          return [];
        }),
      );
  }

  readExportNotificationList(userKey: string): Observable<NotificationSetup[]> {
    const criteria: Octopus.Criteria<Octopus.NotificationSetup> = {
      SearchQuery: {
        $AND: [
          {
            Type: {
              $EQ: Octopus.NotificationType.NotificationService,
            },
          },
          {
            EntityType: {
              $EQ: Octopus.EntityType.Export,
            },
          },
          {
            Recipients: {
              $IN: [userKey],
            },
          },
        ],
      },
    };

    return this.readNotificationList(criteria);
  }

  readFollowUpNotificationList(key: string): Observable<NotificationSetup[]> {
    const criteria: Octopus.Criteria<Octopus.NotificationSetup> = {
      SearchQuery: {
        $AND: [
          {
            ParentKey: {
              $EQ: key,
            },
          },
          {
            Recipients: {
              $IN: ['$CURRENTUSER()'],
            },
          },
        ],
      },
    };

    return this.readNotificationList(criteria);
  }

  private getExportNotificationRequest(
    query: NotificationSetup,
  ): Octopus.NotificationSetupWriteRequest {
    const request = this.getRequest(query);
    request.NotificationSetup.Data = {
      ...request.NotificationSetup.Data,
      ...{
        EntityType: Octopus.EntityType.Export,
        Trigger: Octopus.NotificationTrigger.Read,
        Tag: query.tag,
        Criteria: {
          $AND: [
            {
              Rows: {
                $GE: this.exportRowsNotification,
              },
            },
            {
              EntityType: {
                $EQ: query.entityType,
              },
            },
          ],
        },
      },
    };
    return request;
  }

  private getFollowNotificationRequest(
    query: NotificationSetup,
  ): Octopus.NotificationSetupWriteRequest {
    const request = this.getRequest(query);
    request.NotificationSetup.Data = {
      ...request.NotificationSetup.Data,
      ...{
        ParentKey: query.parentKey,
        EntityType: query.entityType,
        Trigger: Octopus.NotificationTrigger.Update,
        Tag: query.tag,
        Criteria: {
          '/Opportunity/Key': {
            $EQ: query.parentKey,
          },
        },
      },
    };
    return {
      ...request,
    };
  }
  private getRequest(
    query: NotificationSetup,
  ): Octopus.NotificationSetupWriteRequest {
    return {
      NotificationSetup: {
        Data: {
          Key: null,
          Name: query.name,
          Type: Octopus.NotificationType.NotificationService,
          Status: Octopus.NotificationStatus.Enabled,
          NotifySource: false,
          Targets: [
            {
              Key: {
                ID: Octopus.InteractionCode.EMAIL,
              },
              Data: `<?xml version="1.0" encoding="UTF-8"?><data><ns_target_id>${this.webhookRecipientId}</ns_target_id></data>`,
            },
          ],
          Recipients: [
            {
              Key: {
                UID: query.recipientUid,
              },
            },
          ],
          SecAccess: {
            Write: [
              {
                Key: {
                  Uid: '*',
                },
              },
            ],
            Read: [
              {
                Key: {
                  Uid: '*',
                },
              },
            ],
          },
        },
      },
      Configuration: Octopus.NotificationSetupReadDriver,
    };
  }

  getExportNotificationTag(entityType: Octopus.EntityType): string {
    if (entityType === Octopus.EntityType.Opportunity) {
      return Octopus.NotificationSetupTag.ExportAlertAbEntry;
    }
    return Octopus.NotificationSetupTag.ExportAlertOpportunity;
  }

  getFollowNotificationTag(): string {
    return Octopus.NotificationSetupTag.FollowOpportunity;
  }
}
