import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, delay, finalize, map, Observable, of } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import {
  GlobalServicesLoginDetails,
  OAuth2RedirectUri,
  OAuth2Token,
} from '@maximizer/core/shared/domain';
import { EncryptionService } from './encryption.service';
import {
  GLOBAL_SERVICES_KEY,
  GLOBAL_SERVICES_URL,
  OAUTH2_CLIENT_ID,
  OAUTH2_CLIENT_ID_EMEA,
} from '../data-access.module';
import { ContextService } from './context.service';

export const REFRESH_TOKEN_DELAY = 2000;
export const REFRESH_TOKEN_INPROGRESS_DIFFERENCE = 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class OAuth2Service {
  private readonly storageName_Token = ':Mx-Id';
  private readonly storageName_RefreshInProgress = ':Mx-IdProgress';
  private readonly storageName_UserId = ':Mx-UserId';
  private readonly storageName_UserEmail = ':Mx-UserEmail';
  private readonly storageName_LoginDetails = ':Mx-GlobalDetails';

  oAuth2Token?: OAuth2Token;
  loginDetails?: GlobalServicesLoginDetails;
  encryptPassword?: string;
  storagePartitionKey = '';

  mxUserId?: string;
  mxUserEmail?: string;
  get mxWorkspaceId(): string {
    return this.loginDetails?.tenant.workspaces[0].id ?? '';
  }

  get requiredPropertiesPopulated(): boolean {
    return !!(
      this.mxUserId &&
      this.oAuth2Token &&
      this.mxUserEmail &&
      this.loginDetails
    );
  }

  constructor(
    @Inject(GLOBAL_SERVICES_KEY) private readonly globalServicesKey: string,
    @Inject(GLOBAL_SERVICES_URL) private readonly globalServicesUrl: string,
    @Inject(OAUTH2_CLIENT_ID) private readonly clientId: string,
    @Inject(OAUTH2_CLIENT_ID_EMEA) private readonly clientIdEmea: string,
    private readonly http: HttpClient,
    private readonly encryption: EncryptionService,
    private readonly context: ContextService,
  ) {}

  getLoginDetails(
    email: string,
  ): Observable<GlobalServicesLoginDetails[] | null> {
    const url = `${this.globalServicesUrl}/v1/users/${encodeURIComponent(email)}/loginDetails`;
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': this.globalServicesKey,
    });
    return this.http
      .get<GlobalServicesLoginDetails[]>(url, {
        headers,
      })
      .pipe(
        map((result) => {
          return result ?? [];
        }),
      );
  }

  getToken(
    code: string,
    redirect_uri: string,
    verifier: string,
    login_hint: string,
    storeLocal = true,
  ): Observable<OAuth2Token | null> {
    const oauthUrl = this.getOAuthUrl();
    if (!oauthUrl) return of(null);
    const clientId = this.getRegionClientId(oauthUrl);
    const url = `${oauthUrl}/token`;
    const body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('client_id', clientId)
      .set('redirect_uri', redirect_uri)
      .set('code', code)
      .set('code_verifier', verifier)
      .set('login_hint', login_hint);

    return this.requestToken(url, body, storeLocal);
  }

  refreshToken(
    refresh_token: string | undefined = undefined,
    storeLocal = true,
  ): Observable<OAuth2Token | null> {
    this.getStorageToken();
    if (!this.oAuth2Token?.refresh_token) return of(null);

    const tokenIsRefreshed = this.verifyIfRefreshIsInProgress();
    if (tokenIsRefreshed) {
      return of(tokenIsRefreshed);
    }

    refresh_token = this.oAuth2Token?.refresh_token;
    const oauthUrl = this.getOAuthUrl();
    if (!oauthUrl) return of(null);
    const loginHint = this.getLoginHint();
    if (!loginHint) return of(null);
    const clientId = this.getRegionClientId(oauthUrl);
    const url = `${oauthUrl}/token`;
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('client_id', clientId)
      .set('refresh_token', refresh_token)
      .set('login_hint', loginHint);

    this.setRefreshInProgress();
    return this.requestToken(url, body, storeLocal);
  }

  private requestToken(
    url: string,
    body: HttpParams,
    storeLocal: boolean,
  ): Observable<OAuth2Token | null> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.http.post<OAuth2Token>(url, body, { headers }).pipe(
      catchError((error) => {
        console.error('Failed to request token', error);
        return of(null);
      }),
      map((result) => {
        if (result) {
          result.expires_at = new Date(
            new Date().getTime() + result.expires_in * 1000,
          );
          if (storeLocal) this.setStorageToken(result);
          this.context.refresh_token = result.refresh_token;
          this.context.token = result.access_token;
          this.oAuth2Token = result;
          return result;
        }

        this.getStorageToken();
        if (
          this.oAuth2Token &&
          this.context.refresh_token !== this.oAuth2Token.refresh_token
        ) {
          return this.oAuth2Token;
        }
        return null;
      }),
      finalize(() => {
        this.clearRefreshInProgress();
      }),
    );
  }

  getOAuthLoginUrl(
    loginDetails: GlobalServicesLoginDetails,
    redirect_uri: string,
  ): OAuth2RedirectUri | null {
    this.loginDetails = loginDetails;
    const state = uuidV4();
    const original_code = uuidV4();
    const code_challenge = this.encryption.oauthHashCodeVerifier(original_code);

    const oauthUrl = this.getOAuthUrl();
    const loginHint = this.getLoginHint();
    if (!oauthUrl || !loginHint) return null;
    const clientId = this.getRegionClientId(oauthUrl);

    let url = `${oauthUrl}/Authorize?response_type=code`;
    url = url + '&client_id=' + clientId;
    url = url + '&redirect_uri=' + redirect_uri;
    url = url + '&state=' + state;
    url = url + '&code_challenge=' + code_challenge;
    url = url + '&code_challenge_method=SHA256';
    url = url + '&login_hint=' + encodeURIComponent(loginHint);

    return {
      url,
      state,
      code_challenge,
      original_code,
    };
  }

  populateFromStorage(): boolean {
    const storageUser = this.getStorageMxUserId();
    const storageUserEmail = this.getStorageMxUserEmail();
    const storageLogin = this.getStorageLoginDetails();
    const storageToken = this.getStorageToken();

    if (!storageUser || !storageUserEmail || !storageLogin || !storageToken) {
      return false;
    }
    return true;
  }

  setRefreshInProgress(): void {
    localStorage.setItem(
      this.storagePartitionKey + this.storageName_RefreshInProgress,
      new Date().toUTCString(),
    );
  }

  clearRefreshInProgress(): void {
    localStorage.removeItem(
      this.storagePartitionKey + this.storageName_RefreshInProgress,
    );
  }

  getRefreshInProgress(): Date | null {
    const date = localStorage.getItem(
      this.storagePartitionKey + this.storageName_RefreshInProgress,
    );
    return date ? new Date(date) : null;
  }

  setStorageMxUserEmail(email: string): void {
    this.mxUserEmail = email;
    localStorage.setItem(
      this.storagePartitionKey + this.storageName_UserEmail,
      this.mxUserEmail,
    );
  }

  getStorageMxUserEmail(): string | null {
    const userEmail = localStorage.getItem(
      this.storagePartitionKey + this.storageName_UserEmail,
    );
    if (!userEmail) return null;
    this.mxUserEmail = userEmail;
    return this.mxUserEmail;
  }

  setStorageMxUserId(userId: string): void {
    this.mxUserId = userId;
    localStorage.setItem(
      this.storagePartitionKey + this.storageName_UserId,
      this.mxUserId,
    );
  }

  getStorageMxUserId(): string | null {
    const userId = localStorage.getItem(
      this.storagePartitionKey + this.storageName_UserId,
    );
    if (!userId) return null;
    this.mxUserId = userId;
    return this.mxUserId;
  }

  setStorageLoginDetails(loginDetails: GlobalServicesLoginDetails): void {
    this.loginDetails = loginDetails;
    localStorage.setItem(
      this.storagePartitionKey + this.storageName_LoginDetails,
      JSON.stringify(loginDetails),
    );
  }

  getStorageLoginDetails(): GlobalServicesLoginDetails | undefined {
    const loginDetails = localStorage.getItem(
      this.storagePartitionKey + this.storageName_LoginDetails,
    );

    if (loginDetails) {
      this.loginDetails = JSON.parse(loginDetails);
      return this.loginDetails;
    } else {
      return undefined;
    }
  }

  setStorageToken(auth: OAuth2Token): boolean {
    if (!this.mxUserId || !this.encryptPassword) return false;
    try {
      this.oAuth2Token = auth;
      const authObj = JSON.stringify(auth);
      const authObjPlusSalt =
        authObj + '#' + this.storagePartitionKey + this.mxUserId;

      const tokenPlusSalt = this.encryption.encrypt(
        authObjPlusSalt,
        this.encryptPassword,
      );
      localStorage.setItem(
        this.storagePartitionKey + this.storageName_Token,
        tokenPlusSalt,
      );
      return true;
    } catch (error) {
      console.error('Error on store auth', error);
      return false;
    }
  }

  getStorageToken(): OAuth2Token | undefined {
    if (!this.mxUserId || !this.encryptPassword) return;

    const encryptedToken = localStorage.getItem(
      this.storagePartitionKey + this.storageName_Token,
    );
    if (!encryptedToken) return;

    const tokenPlusSalt = this.encryption.decrypt(
      encryptedToken,
      this.encryptPassword ?? '',
    );
    if (tokenPlusSalt === '') return undefined;

    const salt = tokenPlusSalt.lastIndexOf(
      '#' + this.storagePartitionKey + this.mxUserId,
    );
    const decryptedObj = tokenPlusSalt.substring(0, salt);
    this.oAuth2Token = JSON.parse(decryptedObj);

    return this.oAuth2Token;
  }

  clearAuth(): boolean {
    try {
      localStorage.removeItem(
        this.storagePartitionKey + this.storageName_Token,
      );
      localStorage.removeItem(
        this.storagePartitionKey + this.storageName_UserId,
      );
      localStorage.removeItem(
        this.storagePartitionKey + this.storageName_UserEmail,
      );
      localStorage.removeItem(
        this.storagePartitionKey + this.storageName_LoginDetails,
      );

      this.clearRefreshInProgress();

      this.oAuth2Token = undefined;
      this.mxUserId = undefined;
      this.encryptPassword = undefined;
      this.loginDetails = undefined;
      this.encryptPassword = undefined;
      this.storagePartitionKey = '';

      this.context.clearAuthentication();

      return true;
    } catch (error) {
      console.error('Error on clear', error);
      return false;
    }
  }

  populateContextOAuthData(): boolean {
    if (!this.loginDetails || !this.oAuth2Token) return false;
    const firstWorkspace = this.loginDetails.tenant.workspaces[0];

    this.context.token = this.oAuth2Token.access_token ?? '';
    this.context.refresh_token = this.oAuth2Token.refresh_token ?? '';
    this.context.alias = this.loginDetails.tenant.alias;
    this.context.api = firstWorkspace.urls.api
      ? firstWorkspace.urls.api + '/Data.svc'
      : '';
    this.context.website = firstWorkspace.urls.web ?? '';
    this.context.tenantId = this.loginDetails.tenant.id;
    return true;
  }

  private getLoginHint(): string | null {
    if (!this.loginDetails) return null;
    if (this.loginDetails.tenant.workspaces.length === 0) return null;
    const database = this.loginDetails.tenant.workspaces[0].database;
    return 'd ' + database;
  }

  private getOAuthUrl(): string | null {
    if (!this.loginDetails) return null;
    if (this.loginDetails.tenant.workspaces.length === 0) return null;
    const oauthUrl = this.loginDetails.tenant.workspaces[0].urls.oauth;
    if (!oauthUrl) return null;
    return oauthUrl + '/OAuth2';
  }

  // Get regional clientId for NorthAmerica or EMEA (Production only)
  private getRegionClientId(oauthUrl: string): string {
    const firstCharacters = oauthUrl.substring(0, 10);
    if (
      (firstCharacters.includes('uk') || firstCharacters.includes('emea')) &&
      this.clientIdEmea
    ) {
      return this.clientIdEmea;
    }
    return this.clientId;
  }

  private verifyIfRefreshIsInProgress(): OAuth2Token | null {
    const refreshInProgress = this.getRefreshInProgress();
    if (refreshInProgress) {
      const now = new Date();
      const progressBiggerThan = now.getTime() - refreshInProgress.getTime();
      if (REFRESH_TOKEN_INPROGRESS_DIFFERENCE >= progressBiggerThan) {
        delay(REFRESH_TOKEN_DELAY);
        return this.getStorageToken() ?? null;
      }
    }
    return null;
  }
}
