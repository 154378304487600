import { Component } from '@angular/core';
import { OutlookMsalService } from '@maximizer/outlook/shared/data-access';

@Component({
  selector: 'maximizer-outlook-msal-mismatch-error',
  template: `<div
    class="flex flex-col gap-2 justify-around bg-vertical3-5 border border-vertical3-90 p-2 mt-2 mx-2"
  >
    <div class="flex justify-around gap-2 items-center">
      <span
        class="fa-icons icon-20 icon-solid icon-circle-exclamation text-error"
      ></span>
      <p class="text-body large text-error">
        {{ 'outlook.auth.msal.mismatchError' | translate }}
      </p>
    </div>
    <div class="flex justify-end">
      <button
        kendoButton
        id="outlook-auth-msal-mismatchError-switch"
        fillMode="flat"
        themeColor="primary"
        (click)="switchAccount()"
      >
        {{ 'outlook.buttons.switch' | translate }}
      </button>
    </div>
  </div>`,
  standalone: false,
})
export class MsalMismatchErrorComponent {
  constructor(private readonly msalService: OutlookMsalService) {}

  switchAccount(): void {
    this.msalService.switchAccount();
  }
}
