import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '@maximizer/core/shared/data-access';

@Pipe({ name: 'currencyFormat' })
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private locale: LocaleService) {}

  transform(value?: number | null): string {
    return this.locale.formatCurrency(value);
  }
}
