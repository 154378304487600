<div class="flex flex-col gap-2 bg-marketing-mint py-2 px-4 mb-3 rounded">
  <div class="flex justify-between">
    <p class="text-body bold">
      {{ 'outlook.sync.earlyAccess.title' | translate }}
    </p>
    <button
      kendoButton
      fillMode="flat"
      iconClass="fa-icons icon-xmark icon-14"
      [attr.aria-label]="'outlook.buttons.close' | translate"
      (click)="hideEarlyAccessRequest()"
      class="small"
    ></button>
  </div>
  <p class="text-body">
    {{ 'outlook.sync.earlyAccess.message' | translate }}
  </p>
  <div class="flex justify-end">
    <button
      kendoButton
      id="outlook-entry-ui-entryPage-install"
      fillMode="flat"
      themeColor="primary"
      class="text-button"
      (click)="sendEarlyAccessRequest()"
    >
      {{ 'outlook.sync.earlyAccess.request' | translate }}
    </button>
  </div>
</div>

<maximizer-outlook-notification #notification [closable]="true">
</maximizer-outlook-notification>
