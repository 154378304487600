import { Pipe, PipeTransform } from '@angular/core';
import { InteractionType, Octopus } from '@maximizer/core/shared/domain';
import { InteractionIcons } from '../icons';
@Pipe({
  name: 'interactionIcon',
  standalone: false,
})
export class InteractionIconPipe implements PipeTransform {
  transform(interaction: InteractionType, isRecurring?: boolean): string {
    if (isRecurring) {
      return Octopus.DefaultInteractions.isAppointment(interaction.type)
        ? InteractionIcons[Octopus.RecurringInteractions.Appointment]
        : InteractionIcons[Octopus.RecurringInteractions.Task];
    }
    return Octopus.DefaultInteractions.isDefaultInteraction(interaction.type)
      ? InteractionIcons[interaction.type]
      : InteractionIcons[interaction.icon];
  }
}
