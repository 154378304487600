import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { UserSyncConfiguration } from '../models';

type OutlookState = {
  inMaximizerIds: string[];
  versionValidForNewSync: boolean;
  hasActiveAccount: boolean;
  graphMailAccount: string;
  doesGraphAccountMatchInbox: boolean;
  hideMismatchWarning: boolean;
  syncConfiguration: UserSyncConfiguration | undefined;
};

const initialState: OutlookState = {
  inMaximizerIds: [],
  versionValidForNewSync: false,
  hasActiveAccount: false,
  graphMailAccount: '',
  doesGraphAccountMatchInbox: true,
  hideMismatchWarning: false,
  syncConfiguration: undefined,
};

export const OutlookStore = signalStore(
  withDevtools('outlook'),
  withState({
    ...initialState,
    inMaximizerIds: JSON.parse(localStorage.getItem('inMaximizerIds') || '[]'),
  }),
  withMethods((store) => ({
    updateInMaximizerIdList(inMaximizerIds: string[]) {
      patchState(store, () => ({ inMaximizerIds }));
      localStorage.setItem('inMaximizerIds', JSON.stringify(inMaximizerIds));
    },
    updateVersion(valid: boolean) {
      patchState(store, () => ({ versionValidForNewSync: valid }));
    },
    setHasActiveAccount(active: boolean) {
      patchState(store, () => ({ hasActiveAccount: active }));
    },
    setDoesActiveAccountMatch(match: boolean) {
      patchState(store, () => ({ doesGraphAccountMatchInbox: match }));
    },
    setHideMismatchWarning(hide: boolean) {
      patchState(store, () => ({ hideMismatchWarning: hide }));
    },
    setGraphMailAccount(name: string) {
      patchState(store, () => ({ graphMailAccount: name }));
    },
    updateSyncConfiguration(
      syncConfiguration: UserSyncConfiguration | undefined,
    ): void {
      patchState(store, () => ({ syncConfiguration }));
    },
  })),
);

export type OutlookStore = InstanceType<typeof OutlookStore>;
