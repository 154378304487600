import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { TranslateModule } from '@ngx-translate/core';
import { OutlookSharedDataAccessModule } from '@maximizer/outlook/shared/data-access';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import {
  UiModule,
  defaultTranslateConfiguration,
} from '@maximizer/core/shared/ui';
import { OutlookSharedUiModule } from '@maximizer/outlook/shared/ui';
import { DataAccessModule } from '@maximizer/core/shared/data-access';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { SyncSettingsComponent } from './settings/settings.component';
import { SyncInstallComponent } from './install/install.component';
import { SyncEarlyAccessComponent } from './early-access/early-access.component';

@NgModule({
  declarations: [
    SyncSettingsComponent,
    SyncInstallComponent,
    SyncEarlyAccessComponent,
  ],
  exports: [
    SyncSettingsComponent,
    SyncInstallComponent,
    SyncEarlyAccessComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    LabelModule,
    ButtonsModule,
    ReactiveFormsModule,
    DropDownsModule,
    FormsModule,
    InputsModule,
    DataAccessModule,
    UiModule,
    OutlookSharedDomainModule,
    OutlookSharedDataAccessModule,
    OutlookSharedUiModule,
    TooltipsModule,
    DialogsModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class OutlookSyncUiModule {}
