import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { DomainModule } from '@maximizer/core/shared/domain';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import { OutlookService } from './services/outlook/outlook.service';
import {
  AbEntryService,
  LeadService,
  OutlookSyncService,
  OnboardingService,
  OutlookMsalService,
  IdentityService,
} from './services';
import { OnboardingStore, OutlookStore } from './state';

export const INTEGRATION_URL = new InjectionToken<string>(
  'integrationEntrypoint',
);
export const INTEGRATION_SUBSCRIPTION_KEY = new InjectionToken<string>(
  'integrationSubscriptionKey',
);
export const APP_STORE_OAUTH_URL = new InjectionToken<string>(
  'appStoreOAuthUrl',
);
export const APP_STORE_CONFIGURATION_URL = new InjectionToken<string>(
  'appStoreConfigurationUrl',
);
export const INTEGRATION_WEBHOOK_URL = new InjectionToken<string>(
  'integrationWebhookUrl',
);
export const WEBHOOK_CREATE_EARLY_ACCESS = new InjectionToken<string>(
  'createEarlyAccess',
);
export const WEBHOOK_READ_EARLY_ACCESS = new InjectionToken<string>(
  'readEarlyAccess',
);
export const GRAPH_API_SCOPES = new InjectionToken<string[]>('graphApiScopes');
export const GRAPH_API_URI = new InjectionToken<string>('graphApiUri');

export interface OutlookSharedDataAccessConfig {
  url: string;
  subscriptionKey: string;
  appStoreOAuthUrl: string;
  appStoreConfigurationUrl: string;
  integrationWebhookUrl: string;
  createEarlyAccess: string;
  readEarlyAccess: string;
  graphApiScopes: string[];
  graphApiUri: string;
}

@NgModule({
  imports: [CommonModule, DomainModule, OutlookSharedDomainModule],
})
export class OutlookSharedDataAccessModule {
  static forRoot(
    config: OutlookSharedDataAccessConfig,
  ): ModuleWithProviders<OutlookSharedDataAccessModule> {
    return {
      ngModule: OutlookSharedDataAccessModule,
      providers: [
        AbEntryService,
        LeadService,
        OutlookService,
        OutlookSyncService,
        OnboardingStore,
        OutlookStore,
        OnboardingService,
        OutlookMsalService,
        IdentityService,
        { provide: INTEGRATION_URL, useValue: config.url },
        {
          provide: INTEGRATION_SUBSCRIPTION_KEY,
          useValue: config.subscriptionKey,
        },
        { provide: APP_STORE_OAUTH_URL, useValue: config.appStoreOAuthUrl },
        {
          provide: APP_STORE_CONFIGURATION_URL,
          useValue: config.appStoreConfigurationUrl,
        },
        {
          provide: INTEGRATION_WEBHOOK_URL,
          useValue: config.integrationWebhookUrl,
        },
        {
          provide: WEBHOOK_CREATE_EARLY_ACCESS,
          useValue: config.createEarlyAccess,
        },
        {
          provide: WEBHOOK_READ_EARLY_ACCESS,
          useValue: config.readEarlyAccess,
        },
        { provide: GRAPH_API_SCOPES, useValue: config.graphApiScopes },
        { provide: GRAPH_API_URI, useValue: config.graphApiUri },
      ],
    };
  }
}
