import { Injectable, inject } from '@angular/core';
import { GlobalStore, OAuth2Service } from '@maximizer/core/shared/data-access';
import { InsightsService } from '@maximizer/core/shared/insights';
import { catchError, filter, first, interval, timeout } from 'rxjs';

@Injectable()
export class IdentityService {
  private readonly globalStore = inject(GlobalStore);
  private readonly oauth2Service = inject(OAuth2Service);
  private readonly insightsService = inject(InsightsService);

  populateMaximizerIdentity(): void {
    interval(500)
      .pipe(
        filter(
          () =>
            !!this.globalStore.session() &&
            !!this.oauth2Service.getStorageLoginDetails(),
        ),
        first(),
        timeout(8000),
        catchError(() => {
          console.error(
            'Failed to initialize MaximizerIdentity due to timeout',
          );
          this.insightsService.trackException({
            error: new Error(
              'Failed to initialize MaximizerIdentity due to timeout',
            ),
          });
          return [];
        }),
      )
      .subscribe(() => {
        const session = this.globalStore.session();
        const loginDetails = this.oauth2Service.getStorageLoginDetails();

        window.maximizerIdentity = {
          alias: (session?.alias ?? '').toLowerCase(),
          application: 'outlook',
          customer_id: (loginDetails?.tenant.id ?? '').toLowerCase(),
          database_id: (session?.database ?? '').toLowerCase(),
          platform_type: 'cloud',
          user_login: (session?.user.id ?? '').toLowerCase(),
          workspace_id: (
            loginDetails?.tenant.workspaces[0].id ?? ''
          ).toLowerCase(),
        };

        this.readyMaximizerIdentify();
      });
  }

  private readyMaximizerIdentify(): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'maximizer_identity_ready',
      });
    } else {
      console.warn('dataLayer is not available.');
    }
  }
}
