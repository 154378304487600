<maximizer-outlook-menu
  [pageTitle]="'outlook.case.create' | translate"
></maximizer-outlook-menu>

<div class="flex flex-col h-[calc(100%-32px)] scrollbar">
  @if (isLoading.spinner) {
    <maximizer-loader type="legacy"></maximizer-loader>
  }

  <form
    class="flex flex-col p-4 text-caption space-y-2"
    [formGroup]="form.group"
  >
    <kendo-formfield>
      <kendo-label [text]="'outlook.case.subject' | translate">*</kendo-label>
      <kendo-combobox
        formControlName="subject"
        [data]="subject"
        valueField="id"
        textField="name"
        [allowCustom]="true"
        [maxlength]="127"
        [valuePrimitive]="true"
      >
      </kendo-combobox>
      <kendo-formerror>
        @if (form.controls.subject.hasError('maxlength')) {
          {{
            'outlook.forms.maxlength-error'
              | translate
                : {
                    count:
                      form.controls.subject.errors?.['maxlength']
                        ?.requiredLength,
                  }
          }}
        }

        @if (form.controls.subject.hasError('required')) {
          {{ 'outlook.forms.requiredField' | translate }}
        }
      </kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.case.description' | translate"
        >*</kendo-label
      >
      <kendo-textarea
        formControlName="description"
        [maxlength]="9215"
      ></kendo-textarea>
      <kendo-formerror>
        @if (form.controls.description.hasError('maxlength')) {
          {{
            'outlook.forms.maxlength-error'
              | translate
                : {
                    count:
                      form.controls.description.errors?.['maxlength']
                        ?.requiredLength,
                  }
          }}
        }

        @if (form.controls.description.hasError('required')) {
          {{ 'outlook.forms.requiredField' | translate }}
        }
      </kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.case.products' | translate">*</kendo-label>
      <kendo-multiselecttree
        formControlName="product"
        [data]="product"
        [checkAll]="true"
        valueField="id"
        textField="name"
        [valuePrimitive]="true"
      ></kendo-multiselecttree>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.case.status' | translate">*</kendo-label>
      <kendo-dropdownlist
        formControlName="status"
        [data]="status"
        valueField="id"
        textField="name"
        [valuePrimitive]="true"
      ></kendo-dropdownlist>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.case.company' | translate">*</kendo-label>
      <kendo-textbox
        formControlName="abEntryName"
        #abEntryName
        [readonly]="true"
        class="bg-neutral-10"
      ></kendo-textbox>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.case.contact' | translate"></kendo-label>
      <kendo-autocomplete
        #contact
        [filterable]="true"
        (filterChange)="contactFilterChange($event)"
        (valueChange)="contactValueChange($event)"
        formControlName="contactName"
        valueField="name"
        [loading]="isLoading.autoComplete"
        [data]="contactList"
      >
      </kendo-autocomplete>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.case.categories' | translate"></kendo-label>
      <kendo-multiselecttree
        formControlName="category"
        [data]="category"
        [checkAll]="true"
        valueField="id"
        textField="name"
        [valuePrimitive]="true"
      ></kendo-multiselecttree>
    </kendo-formfield>
  </form>

  <div
    class="bg-neutral-5 z-40 mt-auto flex space-x-2 p-2 w-full bottom-0 sticky"
  >
    <button
      kendoButton
      id="outlook-case-ui-addCase-save"
      class="uppercase rounded ml-auto"
      themeColor="primary"
      [disabled]="!form.valid || isLoading.spinner"
      (click)="save()"
    >
      {{ 'outlook.buttons.save' | translate }}
    </button>
    <button
      kendoButton
      id="outlook-case-ui-addCase-cancel"
      class="uppercase rounded"
      themeColor="primary"
      fillMode="flat"
      (click)="cancel()"
      [disabled]="isLoading.spinner"
    >
      {{ 'outlook.buttons.cancel' | translate }}
    </button>
  </div>
</div>

<maximizer-outlook-notification #notification></maximizer-outlook-notification>
