import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfigurationService,
  ContextService,
  DocumentService,
  FeatureService,
  GlobalStore,
  OAuth2Service,
  SessionService,
} from '@maximizer/core/shared/data-access';
import {
  CDN_URL,
  CreateDocument,
  Session,
  DocumentType,
  GlobalServicesLoginDetails,
  UserRights,
  LeadStatus,
  ConfigurationSettingCodes,
} from '@maximizer/core/shared/domain';
import { InsightsService } from '@maximizer/core/shared/insights';
import { InteractionService } from '@maximizer/outlook/interaction-log/data-access';
import { OpportunityService } from '@maximizer/outlook/opportunity/data-access';
import {
  AbEntryService,
  EmailSubscription,
  LeadService,
  OutlookService,
  OutlookSyncService,
  OutlookSyncEntityType,
  INTEGRATION_WEBHOOK_URL,
  WEBHOOK_READ_EARLY_ACCESS,
  OutlookStore,
  OnboardingStore,
  getPermissionString,
  PermissionMessage,
  StepGuideId,
  OutlookInsightsEvents,
  InitOnboardingAction,
  ExitOnboardingAction,
  OnboardingStepNumber,
  OnboardingService,
} from '@maximizer/outlook/shared/data-access';
import {
  OutlookLeadSearch,
  OutlookAbEntryDetails,
  OpportunityRevenueSum,
  OutlookEmail,
  EntryType,
  EmailEntryType,
} from '@maximizer/outlook/shared/domain';
import {
  MenuItem,
  OutlookNotificationComponent,
} from '@maximizer/outlook/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  Subscription,
  catchError,
  finalize,
  firstValueFrom,
  forkJoin,
  of,
  map,
  switchMap,
} from 'rxjs';
import { DisposableComponent } from '@maximizer/core/shared/ui';
import { DropDownButtonComponent } from '@progress/kendo-angular-buttons';
import { HttpClient } from '@angular/common/http';
import {
  PopoverContainerDirective,
  Position,
} from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'maximizer-entry-page',
  templateUrl: './entry-page.component.html',
  standalone: false,
})
export class EntryPageComponent
  extends DisposableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @HostBinding('class.max-outlook') hostClass = true;

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.closeTooltips();
    if (this.dropdownButton?.toggle) {
      this.dropdownButton.toggle(false);
    }
  }

  @ViewChild('dropdownButton', { static: false })
  dropdownButton!: DropDownButtonComponent;
  @ViewChild('notification')
  notification!: OutlookNotificationComponent;
  @ViewChild('createNote2Container')
  createNote2Container!: PopoverContainerDirective;
  @ViewChild('createNote2Anchor')
  createNote2Anchor!: Element;
  @ViewChild('entryPageContainer') entryPageContainer!: ElementRef;

  @Input() type!: EmailEntryType;
  @Input()
  set id(id: string) {
    this._id = decodeURI(id);
  }
  get id(): string {
    return this._id;
  }

  get emailIsSaved(): boolean {
    return (!this.loading.getEmail && this.outlookEmail?.emailSaved) ?? false;
  }

  private readonly onboardingSettingCodes: ConfigurationSettingCodes = {
    code3: 'OutlookOnboarding',
    code4: 'Settings',
  };
  private _id = '';

  pageTitle = '';
  deepLink = '';
  oppLink = '';
  companyDeepLink = '';
  entryType = '';
  abentry?: OutlookAbEntryDetails;
  lead?: OutlookLeadSearch;
  leadStatus = LeadStatus;
  opportunities?: OpportunityRevenueSum;
  resourceName = '';
  lastContactDays: number | null = null;
  lastContactMessage: string | null = null;
  disabled = false;
  showSaveEmail = false;
  subscription?: Subscription;
  outlookEmail?: OutlookEmail;
  refreshTimelineEmitter = new EventEmitter<void>();
  showAutoSaveEmailToggle = false;
  autoSaveDisabled = false;
  autoSave = false;
  syncId?: string;
  outlookSyncInstalled = false;
  logInDetails?: GlobalServicesLoginDetails | null;
  userRights?: UserRights;
  showEarlyAccessRequest = true;
  exitOnboarding = false;
  initOnboardingDialog = false;
  emailSaveUserRights = true;
  popoverPosition: Position = 'bottom';
  OnboardingStep = OnboardingStepNumber;
  StepGuideId = StepGuideId;

  loading = {
    earlyAccess: false,
    sync: false,
    skeleton: false,
    saveEmail: false,
    getEmail: true,
  };

  moreActions: MenuItem[] = [
    {
      id: 'add-call',
      tag: 'AddCall',
      text: this.translate.instant('outlook.entryPage.actions.addCallLog'),
      icon: 'maximizer-icons icon-add-call icon-14',
      click: () => this.actionNavigate('call'),
      disabled: false,
      title: 'outlook.callLog.callLogs',
    },
    {
      id: 'add-appointment',
      tag: 'AddAppointment',
      text: this.translate.instant('outlook.entryPage.actions.addAppointment'),
      icon: 'maximizer-icons icon-add-appointment icon-14',
      click: () => this.actionNavigate('appointment'),
      disabled: false,
      title: 'outlook.entries.appointment',
    },
  ];

  constructor(
    @Inject(CDN_URL) public cdn: string,
    @Inject(INTEGRATION_WEBHOOK_URL) private readonly webhookUrl: string,
    @Inject(WEBHOOK_READ_EARLY_ACCESS) private readonly readEarlyAccess: string,
    private readonly translate: TranslateService,
    private readonly abentryService: AbEntryService,
    private readonly leadService: LeadService,
    private readonly sessionService: SessionService,
    private readonly contextService: ContextService,
    private readonly interactionService: InteractionService,
    private readonly opportunityService: OpportunityService,
    private readonly outlookService: OutlookService,
    private readonly documentService: DocumentService,
    private readonly activateRoute: ActivatedRoute,
    private readonly outlookSyncService: OutlookSyncService,
    private readonly insightsService: InsightsService,
    private readonly router: Router,
    private readonly oAuthService: OAuth2Service,
    private readonly http: HttpClient,
    private readonly configurationService: ConfigurationService,
    private readonly onboardingService: OnboardingService,
    public feature: FeatureService,
    public globalStore: GlobalStore,
    public outlookStore: OutlookStore,
    public onboardingStore: OnboardingStore,
  ) {
    super();
    this.showAutoSaveEmailToggle = feature.isFeatureOn(
      'microsoft-outlook-sync-emails-shown',
      false,
    );
  }

  ngOnInit() {
    this.setUserRights();

    if (!this.type) {
      this.entryNotFound();
      return;
    }

    this.showSaveEmail = !this.outlookService.isCompose;
    this.activateRoute.paramMap.subscribe((o) => {
      this.id = o.get('id') ?? '';
    });
    this.decomposeId();
    this.loadData();
    this.logInDetails = this.oAuthService.getStorageLoginDetails();

    if (!this.showAutoSaveEmailToggle) {
      this.setEarlyAccessStatus();
    }
  }

  ngAfterViewInit() {
    this.notification.resourceId = this.entryType ?? 'contact';
    this.pageTitle = this.translate.instant(
      'outlook.entries.' + (this.entryType ?? 'contact'),
    );

    if (this.onboardingStore.inProgress()) {
      const containerHeight =
        this.entryPageContainer.nativeElement.offsetHeight;
      if (containerHeight < 450) {
        this.popoverPosition = 'top';
      }
    }
  }

  override ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  setUserRights(): void {
    if (this.globalStore.session()?.user) {
      this.userRights = this.globalStore.session()?.user.rights;

      if (!this.userRights?.interactionLog?.insert) {
        const menuItem = this.moreActions.find(
          (item) => item.id === 'add-call',
        );
        if (menuItem) {
          menuItem.disabled = true;
        }
      }

      if (
        !this.userRights?.notes.insert &&
        this.onboardingStore.isOnboardingVisible()
      ) {
        this.onboardingStore.updateCanNavigate(StepGuideId.CreateNote2, true);
      }

      this.emailSaveUserRights = this.getSaveEmailPermission(this.type);
    }
  }

  private decomposeId() {
    try {
      const indexQuestion = this.id.indexOf('?');
      if (indexQuestion > -1) {
        this.id = this.id.substring(0, indexQuestion);
      }
      const decomposedId = window.atob(this.id);
      const decomposedItems = decomposedId.split('\t');
      this.entryType = decomposedItems[0]?.toLocaleLowerCase();
    } catch (error) {
      this.insightsService.trackException({ error: error as Error });
      this.entryNotFound();
    }
  }

  actionNavigate(action: 'call' | 'appointment') {
    if (action === 'call' && this.userRights?.interactionLog?.insert) {
      this.router.navigate([`/${this.type}/${this.id}/add-call-log`]);
    } else if (action === 'appointment') {
      this.router.navigate([`/${this.type}/${this.id}/add-appointment`]);
    }
  }

  private entryNotFound() {
    this.notification.resourceId = this.entryType ?? 'contact';
    this.notification?.show('load', 'error');
    this.disabled = true;
    this.router.navigate(['/home']);
  }

  private loadData() {
    this.loading.skeleton = true;

    const opportunity =
      this.type === 'abentry' && this.userRights?.opportunities.read
        ? this.opportunityService.getOpenAndWonSum(this.id)
        : of(null);

    forkJoin({
      session: this.sessionService.getInfo(),
      entry: this.loadLeadOrAbEntryService(),
      lastContactDate: this.interactionService.getLastContact(
        this.id,
        this.type,
      ),
      opportunityDetails: opportunity,
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
          this.disabled = true;
        }),
        finalize(() => {
          this.loading.skeleton = false;
          this.disabled = !this.lead && !this.abentry;

          if (this.onboardingStore.inProgress()) {
            this.initializeOnboardingState();
          }
        }),
      )
      .subscribe(async (data) => {
        if (!data) return;

        if (data.session) {
          this.populateDeepLinks(data.session);
          if (
            this.showAutoSaveEmailToggle &&
            this.outlookStore.versionValidForNewSync()
          ) {
            this.getOutlookSyncSettings(data.session);
          }
        }

        if (this.type === 'lead') {
          await this.loadLead(
            data.entry as OutlookLeadSearch,
            data.lastContactDate,
          );
        }

        if (this.type === 'abentry') {
          await this.loadAbEntry(
            data.entry as OutlookAbEntryDetails,
            data.session,
          );
        }

        if (data.opportunityDetails) {
          this.opportunities = data.opportunityDetails;
        }
      });
  }

  populateDeepLinks(session: Session): void {
    const domain = this.extractDomain();
    const accountLink = domain?.replace('1', 'w') + session.alias;
    this.deepLink = `${accountLink}?ss=KEY(${this.id})`;
    this.oppLink = `${accountLink}?ssopp=KEY(${this.id})`;
  }

  getOutlookSyncSettings(session: Session): void {
    this.loading.sync = true;

    this.outlookSyncService
      .getConfiguration(session.user.id, session.workspace)
      .pipe(
        switchMap((config) => {
          if (!config) {
            this.outlookSyncInstalled = false;
            this.autoSaveDisabled = true;
            return of(null);
          }

          this.outlookSyncInstalled = config.enabled;
          this.syncId = config?.id;
          this.autoSaveDisabled = !config?.emailsSync.enabled;

          return this.outlookSyncService.getEmailSubscriptionByKey(
            this.id,
            this.syncId,
          );
        }),
        map((emailSubscription) => {
          if (emailSubscription) {
            this.autoSave = emailSubscription.enabled;
          }
        }),
        finalize(() => {
          this.loading.sync = false;
        }),
      )
      .subscribe();
  }

  private loadLeadOrAbEntryService(): Observable<
    OutlookAbEntryDetails | OutlookLeadSearch | null
  > {
    if (this.type === 'lead') {
      return this.leadService.getByKey(this.id ?? '');
    }
    if (this.type === 'abentry') {
      return this.abentryService.getAllDetailsByKey(this.id ?? '');
    }
    return of(null);
  }

  private async loadAbEntry(abentry: OutlookAbEntryDetails, session: Session) {
    if (abentry.type === 'Company') {
      abentry.name = abentry.companyName;
      abentry.companyName = '';
    }
    this.abentry = abentry;
    this.daysAgo(abentry.lastContactDateValue);
    this.lastContactMessage = this.getDaysAgoTranslation();

    if (abentry.parentKey) {
      const domain = this.extractDomain();
      const accountLink = domain?.replace('1', 'w') + session.alias;
      this.companyDeepLink = `${accountLink}?ss=KEY(${abentry.parentKey})`;
    }
    try {
      this.outlookEmail = {
        id: abentry.key ?? this.id,
        emailAddress: abentry.emails ? abentry.emails[0].value : '',
        displayName: abentry.name,
        type: 'abentry',
        abentryType: abentry.type.toLocaleLowerCase() as
          | 'company'
          | 'contact'
          | 'individual'
          | undefined,
      };

      if (!this.showSaveEmail) return;
      this.outlookEmail.emailSaved =
        await this.outlookService.checkSavedEmailByKey(this.id);
    } catch (error) {
      this.insightsService.trackException(
        { exception: error as Error },
        { message: 'Error on load abentry' },
      );
    } finally {
      this.loading.getEmail = false;
    }
  }

  private async loadLead(
    lead: OutlookLeadSearch,
    lastContactDate: Date | null,
  ) {
    try {
      this.lead = lead;
      this.daysAgo(lastContactDate);
      this.lastContactMessage = this.getDaysAgoTranslation();
      this.deepLink = this.deepLink?.replace('?ss=KEY', '?sslead=KEY');

      this.outlookEmail = {
        id: this.id,
        emailAddress: lead.email ?? '',
        displayName: lead.name,
        type: 'lead',
        abentryType: undefined,
      };

      if (!this.showSaveEmail) return;
      const isSaved = await this.outlookService.checkSavedEmailByKey(this.id);
      this.outlookEmail.emailSaved = isSaved;
    } catch (error) {
      this.insightsService.trackException(
        { exception: error as Error },
        { message: 'Error on load lead' },
      );
    } finally {
      this.loading.getEmail = false;
    }
  }

  private extractDomain(): string {
    const urlArray = this.contextService.website.split('/');
    const domainIndex = urlArray.findIndex((o) =>
      o.toLocaleLowerCase().includes('maximizer'),
    );
    if (domainIndex == -1) return '';
    let domain = '';
    for (let i = 0; i <= domainIndex; i++) {
      domain += urlArray[i] + '/';
    }
    return domain;
  }

  daysAgo(value: Date | undefined | null): void {
    if (!value) {
      this.lastContactDays = null;
      return;
    }
    const currentDate = new Date();
    const targetDate = new Date(value);
    const diffInTime = currentDate.getTime() - targetDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
    if (isNaN(diffInDays)) {
      this.lastContactDays = null;
    } else {
      this.lastContactDays = diffInDays;
    }
  }

  getDaysAgoTranslation(): string {
    if (this.lastContactDays == null) return 'outlook.entryPage.notContacted';
    if (this.lastContactDays < 0) return '';
    if (this.lastContactDays == 0) return 'outlook.entryPage.today';
    return 'outlook.entryPage.daysago';
  }

  async saveEmailForEntry(): Promise<void> {
    if (this.outlookEmail?.id === undefined || !this.emailSaveUserRights) {
      return;
    }

    try {
      this.loading.saveEmail = true;

      const emlFile = await this.outlookService.getReadEmlFile();
      if (!emlFile) throw new Error('Unable to get eml file');

      const documentId = await this.createEmailInMaximizer(emlFile, this.id);
      if (!documentId) {
        this.notification.showMessage(
          'error',
          'outlook.email-notification.fail-entry',
          true,
        );
        return;
      }

      this.outlookEmail.emailSaved = true;
      this.notification.showMessage(
        'success',
        'outlook.email-notification.success-entry',
        true,
      );

      await this.outlookService.saveKeysInOutlook([this.outlookEmail], false);
      this.refreshTimelineEmitter.emit();
    } catch (error) {
      console.error('Error', error);
      this.notification.showMessage(
        'error',
        'outlook.email-notification.fail-entry',
        true,
      );
    } finally {
      this.loading.saveEmail = false;
    }
  }

  private async createEmailInMaximizer(
    emlFile: File,
    parentKey: string,
  ): Promise<string | null> {
    const attachment$ = this.documentService.createAttachment(
      emlFile,
      'application/octet-stream',
    );
    const attachmentId = await firstValueFrom(attachment$);

    const item = this.outlookService.mailboxItem;
    if (!item) return null;

    const document: CreateDocument = {
      name: item.subject,
      binaryDataId: attachmentId ?? '',
      dateTime: item.dateTimeCreated,
      extension: '.eml',
      type: DocumentType.Emails,
      size: emlFile.size,
      parentKey: parentKey,
    };

    const document$ = this.documentService.createDocument(document);
    return firstValueFrom(document$);
  }

  onAutoSaveEmailChange(isEnabled: boolean): void {
    if (!this.syncId) {
      return;
    }

    this.loading.sync = true;

    const entityType: OutlookSyncEntityType =
      this.type === 'abentry'
        ? OutlookSyncEntityType.AbEntry
        : OutlookSyncEntityType.Lead;

    const emailSubscription: EmailSubscription = {
      maximizerEntityType: entityType,
      maximizerKey: this.id,
      enabled: isEnabled,
    };

    this.outlookSyncService
      .updateEmailAutoSaveSubscription(this.syncId, emailSubscription)
      .pipe(
        catchError((error) => {
          this.handleEmailAutoSaveError(isEnabled, error);
          return of(null);
        }),
        finalize(() => {
          this.loading.sync = false;
        }),
      )
      .subscribe((response) => {
        if (response === null) {
          return;
        }

        this.autoSave = isEnabled;

        if (isEnabled) {
          this.notification.showMessage(
            'success',
            'outlook.entryPage.autoSave.notification',
            true,
          );
        }
      });
  }

  private handleEmailAutoSaveError(isEnabled: boolean, error: Error): void {
    this.autoSave = !isEnabled;
    this.notification.showMessage(
      'error',
      'outlook.entryPage.autoSave.error',
      true,
    );

    console.error('Error updating auto-save subscription:', error);
    this.insightsService.trackException(
      { error },
      { message: 'Failed updating auto-save subscription' },
    );
  }

  private setEarlyAccessStatus(): void {
    const hideEarlyAccess = sessionStorage.getItem('hideEarlyAccess');
    if (hideEarlyAccess === 'true') {
      this.showEarlyAccessRequest = false;
      return;
    }

    const userEmail = this.oAuthService.getStorageMxUserEmail();
    if (!userEmail) {
      return;
    }

    this.loading.earlyAccess = true;
    this.http
      .get(`${this.webhookUrl}/${this.readEarlyAccess}?email=${userEmail}`)
      .pipe(
        catchError((error) => {
          console.error('Error reading early access requests:', error);
          this.insightsService.trackException(
            { exception: error as Error },
            {
              message: `Error reading early access requests: ${error.message}`,
            },
          );
          return of(null);
        }),
        finalize(() => {
          this.loading.earlyAccess = false;
        }),
      )
      .subscribe((result) => {
        if (result && Object.keys(result).length > 0) {
          this.showEarlyAccessRequest = false;
        }
      });
  }

  getSaveEmailPermission(type: EmailEntryType): boolean {
    if (
      (type === EntryType.AbEntry && !this.userRights?.addressBook.update) ||
      (type === EntryType.Lead && !this.userRights?.lead.update) ||
      !this.userRights?.documents?.insert
    ) {
      return false;
    }
    return true;
  }

  getSaveEmailPermissionMessage(
    type: EmailEntryType,
    message: PermissionMessage,
  ): string {
    if (type === EntryType.AbEntry && !this.userRights?.addressBook.update) {
      return getPermissionString(this.translate, 'abentry', 'edit', message);
    } else if (type === EntryType.Lead && !this.userRights?.lead.update) {
      return getPermissionString(this.translate, 'lead', 'edit', message);
    } else if (!this.userRights?.documents?.insert) {
      return getPermissionString(
        this.translate,
        'document',
        'create',
        'default',
      );
    } else {
      return getPermissionString(
        this.translate,
        undefined,
        undefined,
        'generic',
      );
    }
  }

  // Onboarding
  initializeOnboardingState(): void {
    setTimeout(() => {
      if (!this.userRights?.notes.insert) {
        this.onboardingStore.updateCanNavigate(StepGuideId.CreateNote2, true);
      }

      this.showOnboardingGuide();
    }, 500);
  }

  showOnboardingGuide(): void {
    const currentStep = this.onboardingStore.currentStep();

    if (currentStep === OnboardingStepNumber.Step3) {
      this.onboardingStore.setCurrentGuide(StepGuideId.CreateNote2);
      this.createNote2Container?.show(this.createNote2Anchor);
    } else if (currentStep === OnboardingStepNumber.Step4) {
      this.initOnboardingDialog = true;
    }
  }

  nextOnboardingStep(): void {
    this.createNote2Container?.hide();
    this.initOnboardingDialog = true;
  }

  handleOnboardingInitAction(action: InitOnboardingAction): void {
    if (action === 'close') {
      this.saveCompletedOnboardingSetting();
      this.initOnboardingDialog = false;
      this.onboardingStore.setOnboardingInProgress(false);
      this.logOnboardingEventToInsights('Completed-Onboarding');
      this.onboardingService.reset();
    }
  }

  exitOnboardingGuide(): void {
    this.createNote2Container?.hide();
    this.onboardingStore.setOnboardingInProgress(false);
    this.exitOnboarding = true;
  }

  exitOnboardingAction(action: ExitOnboardingAction): void {
    if (action === 'cancel') {
      this.exitOnboarding = false;
      this.onboardingStore.setOnboardingInProgress(true);
      this.createNote2Container.show(this.createNote2Anchor);
    } else {
      this.exitOnboarding = false;
      this.onboardingStore.setOnboardingInProgress(false);
      this.logOnboardingEventToInsights(
        'Exit-Onboarding',
        StepGuideId.CreateNote2,
      );
    }
  }

  saveCompletedOnboardingSetting(): void {
    const completedSetting = JSON.stringify({ completed: true });
    const onboardingStateLocalStorage = localStorage.getItem('onboardingState');

    if (onboardingStateLocalStorage) {
      const onboardingStore = JSON.parse(onboardingStateLocalStorage);
      const configurationId = onboardingStore.id;

      this.configurationService
        .updateSetting(
          configurationId,
          this.onboardingSettingCodes,
          completedSetting,
        )
        .pipe(
          catchError((error) => {
            console.error(error);
            return of(null);
          }),
        )
        .subscribe(() => {
          this.onboardingStore.hasCompleted(true);
        });
    }
  }

  private logOnboardingEventToInsights(name: string, guideId?: string) {
    this.onboardingService.trackOnboardingEvent(
      name,
      OutlookInsightsEvents.Onboarding,
      this.contextService.token,
      this.globalStore.session()?.alias ?? '',
      guideId ?? '',
    );
  }
}
