import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ContextService,
  OAuth2Service,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import { GlobalServicesLoginDetails } from '@maximizer/core/shared/domain';
import { InsightsService } from '@maximizer/core/shared/insights';
import {
  InsightsLogDetails,
  OutlookInsightsEvents,
  logCustomEventDetailsToInsights,
} from '@maximizer/outlook/shared/data-access';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'maximizer-outlook-authorize',
  template: '',
})
export class OutlookAuthorizeComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;

  constructor(
    private readonly oauthService: OAuth2Service,
    private readonly sessionStorage: SessionStorageService,
    private readonly route: ActivatedRoute,
    private readonly insightsService: InsightsService,
    private readonly contextService: ContextService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const errorParam = params['error'];
      if (errorParam) {
        this.handleError('OnInit-Auth-Error', errorParam);
        return;
      }

      const code = params['code'];
      const state = params['state'];
      const loginHint = params['login_hint'];
      const userId = params['user_id'];

      const sessionAuth = this.sessionStorage.get('auth_code');
      const url = this.sessionStorage.get('url');

      if (!sessionAuth || !url) return;

      const parsedJson = JSON.parse(sessionAuth);
      const auth_code = parsedJson as {
        url: string;
        state: string;
        code_challenge: string;
        original_code: string;
      };

      if (auth_code.state !== state) return;

      const loginDetailsObj = this.sessionStorage.get('login_details');

      if (!loginDetailsObj) return;

      const loginDetails = JSON.parse(
        loginDetailsObj,
      ) as GlobalServicesLoginDetails;

      this.oauthService.loginDetails = loginDetails;

      this.oauthService
        .getToken(
          code,
          url + '/authorize',
          auth_code.original_code,
          loginHint,
          userId,
        )
        .pipe(
          catchError((error) => {
            console.error('Error on login', error);
            this.logAuthDetailsToInsights('OAuth-Login-Fail', error);
            return of(null);
          }),
        )
        .subscribe((data) => {
          if (data === null) {
            this.handleError(
              'OAuth-Login-Null',
              'Null return from get token OAuth request',
            );
            return;
          }

          this.logAuthDetailsToInsights('OAuth-Login-Success');

          const messageObject = {
            messageType: 'success',
            auth: data,
            db: loginDetails,
          };
          this.sendMessageToParent(messageObject);
        });
    });
  }

  private handleError(name: string, error: string) {
    const messageObject = { messageType: 'dialogClosed' };
    this.sendMessageToParent(messageObject);
    this.logAuthDetailsToInsights(name, error);
  }

  private logAuthDetailsToInsights(name: string, error?: string): void {
    const event: InsightsLogDetails = {
      insights: this.insightsService,
      name: name,
      eventId: OutlookInsightsEvents.OutlookAuth,
      token: this.contextService.token,
      alias: this.oauthService.loginDetails?.tenant.alias,
      error: error,
    };

    logCustomEventDetailsToInsights(event);
  }

  private sendMessageToParent(message: object) {
    const jsonMessage = JSON.stringify(message);
    if (typeof Office !== 'undefined' && Office?.context?.ui) {
      Office.context.ui.messageParent(jsonMessage);
    } else if (window.opener) {
      const origin = window.location.origin;
      window.opener.postMessage({ type: 'outlook', auth: jsonMessage }, origin);
    }
  }
}
