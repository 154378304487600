import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailProfile, Octopus } from '@maximizer/core/shared/domain';
import { Observable, map, of } from 'rxjs';
import { ContextService } from './context.service';

@Injectable()
export class EmailProfileService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}
  getEmailProfile(emailAddress: string): Observable<EmailProfile[]> {
    const readRequest: Octopus.EmailProfileReadRequest = {
      EmailProfile: {
        Criteria: {
          SearchQuery: {
            EmailAddress: {
              $EQ: emailAddress,
            },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            EmailAddress: 1,
            EmailType: {
              Key: 1,
              DisplayValue: 1,
            },
            Comment: {
              Key: 1,
              DisplayValue: 1,
            },
            EnrollDate: 1,
            ExpiryDate: 1,
          },
        },
      },
    };
    return this.http
      .post<Octopus.EmailProfileResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        readRequest,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.EmailProfileMapper();
            return mapper.from(result.EmailProfile.Data);
          }
          return [];
        }),
      );
  }
  create(emailProfiles?: EmailProfile[]): Observable<boolean> {
    const request: { [name: string]: unknown } = {};

    if (emailProfiles) {
      emailProfiles.forEach(
        (emailProfile, index) =>
          (request['EmailProfile.' + index] = { Data: emailProfile }),
      );
    }

    return this.http
      .post<Octopus.EmailProfileResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }
  update(emailProfiles?: EmailProfile[]): Observable<boolean> {
    if (!emailProfiles || emailProfiles.length === 0) {
      return of(true);
    }
    const request: { [name: string]: unknown } = {};

    if (emailProfiles) {
      emailProfiles.forEach(
        (emailProfile, index) =>
          (request['EmailProfile.' + index] = { Data: emailProfile }),
      );
    }

    return this.http
      .post<Octopus.ActivityResponse>(
        `${this.context.api}${Octopus.Action.UPDATE}`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }
  delete(emailProfileKeys: string[]): Observable<boolean> {
    if (!emailProfileKeys || emailProfileKeys.length === 0) {
      return of(true);
    }
    const request: { [name: string]: unknown } = {};

    if (emailProfileKeys) {
      emailProfileKeys.forEach(
        (key, index) =>
          (request['EmailProfile.' + index] = { Data: { Key: key } }),
      );
    }

    return this.http
      .post<Octopus.Response>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }
  private getRequestHeaders(): { [header: string]: string } {
    return {
      'mx-insights-entity': 'EmailProfile',
    };
  }
}
