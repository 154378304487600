import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OutlookService } from '@maximizer/outlook/shared/data-access';

@Component({
  selector: 'maximizer-root',
  templateUrl: './app.component.html',
  standalone: false,
})
export class AppComponent implements OnInit {
  constructor(
    private readonly outlookService: OutlookService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.outlookService?.mailbox?.addHandlerAsync(
      Office.EventType.ItemChanged,
      this.handleItemChanged.bind(this),
    );
  }

  async handleItemChanged() {
    this.router.navigate(['/home']);
  }
}
