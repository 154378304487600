export const environment = {
  production: true,
  cdn: 'https://cdn-maximizer-dev.azureedge.net',
  launchDarklyClientId: '6363e7cc09aacd34e3a76bab',
  smartFill: 'https://dev-scrapper.maximizer-api.com',
  instrumentationKey: '7b177ff5-05e9-4875-bdde-48dd076f9055',
  product: 'outlook',
  oauth: {
    globalServicesKey: '7cd561c1ce0c4e92aed8e105c2fa7391',
    globalServicesUrl: 'https://api-stg.maximizer.com/cloud',
    oauth2ClientId: 't9mpo4uipgs7h4ba20vl',
    oauth2ClientIdEmea: '',
  },
  integration: {
    url: 'https://api-stg.maximizer.com/integration',
    subscriptionKey: 'b4f3e659a0884caaa88b09a0c9c6a1c1',
    webhookUrl: 'https://api-dev.maximizer.com/integrations/test',
    webhookId: {
      createEarlyAccess: 'outlook/create-early-access-request',
      readEarlyAccess: 'outlook/read-early-access-request',
    },
  },
  appStoreOAuthUrl:
    'https://appstorestaging.maximizer.com/appstore/midwareolsync2/index',
  appStoreConfigurationUrl:
    'https://appstorestaging.maximizer.com/appstore/midwareolsync2/configurationhandler',
  msalConfig: {
    auth: {
      clientId: '95c881fe-05cc-4b6e-8091-f66e596dd1f9',
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: 'https://dev-outlook365.maxlabcloud.ca',
      postLogoutRedirectUri: '/',
    },
  },
  graphApiConfig: {
    scopes: ['Mail.ReadWrite', 'User.Read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
};
