import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ContextService } from '@maximizer/core/shared/data-access';
import { Octopus } from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import {
  TaskComposeSummaryMapper,
  TaskComposeSummaryResponse,
  TaskFieldOption,
  TaskFieldOptionMapper,
  TaskForm,
  TaskFormMapper,
  TaskSummary,
  TaskSummaryCard,
  TaskSummaryMapper,
  TaskSummaryResponse,
} from '@maximizer/outlook/shared/domain';
import { insightsHeader } from '@maximizer/core/shared/insights';

@Injectable()
export class TaskService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  getFieldOption(): Observable<TaskFieldOption> {
    const request: Octopus.TaskFieldOptionsRequest = {
      Compatibility: { TaskObject: '2.0' },
      Task: {
        FieldOptions: {
          Category: [
            {
              Key: 1,
              DisplayValue: 1,
            },
          ],
          Result: [
            {
              Key: 1,
              DisplayValue: 1,
            },
          ],
          Priority: [
            {
              Key: 1,
              DisplayValue: 1,
            },
          ],
        },
        Options: {
          ShowInactive: false,
        },
      },
    };

    return this.http
      .post<Octopus.TaskFieldOptionsResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new TaskFieldOptionMapper();
            return mapper.from(result);
          }
          return { categories: [], results: [], priorities: [] };
        }),
      );
  }

  getComposeSummary(
    key: string,
    type: string,
  ): Observable<TaskSummaryCard | null> {
    if (type != 'lead' && type != 'abentry') throw new Error('Not implemented');
    const dateTime = new Date().toISOString();
    const upcomingObject = this.getSummaryQuery(key, dateTime, true, type);
    const fields = this.getSummaryScope();
    const request = {
      Compatibility: {
        TaskObject: '2.0',
      },
      Task: {
        Scope: {
          Fields: fields,
        },
        Criteria: {
          SearchQuery: upcomingObject,
          Top: 1,
        },
        OrderBy: {
          Fields: [{ DateTime: 'ASC' }],
        },
      },
      Globalization: Octopus.DefaultGlobalization,
    };

    return this.http
      .post<TaskComposeSummaryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new TaskComposeSummaryMapper();
            return mapper.from(result);
          }

          return null;
        }),
      );
  }

  getSummary(key: string, type: string): Observable<TaskSummary> {
    if (type != 'lead' && type != 'abentry') throw new Error('Not implemented');
    const dateTime = new Date().toISOString();
    const overdueObject = this.getSummaryQuery(key, dateTime, false, type);
    const upcomingObject = this.getSummaryQuery(key, dateTime, true, type);

    const fields = this.getSummaryScope();
    const request = {
      Compatibility: {
        TaskObject: '2.0',
      },
      'Task.Overdue': {
        Scope: {
          Fields: fields,
        },
        Criteria: {
          SearchQuery: overdueObject,
          Top: 1,
        },
        OrderBy: {
          Fields: [{ DateTime: 'DESC' }],
        },
      },
      'Task.OverdueCount': {
        Scope: {
          Fields: {
            '$COUNT()': 1,
          },
        },
        Criteria: {
          SearchQuery: overdueObject,
        },
      },

      'Task.Upcoming': {
        Scope: {
          Fields: fields,
        },
        Criteria: {
          SearchQuery: upcomingObject,
          Top: 1,
        },
        OrderBy: {
          Fields: [{ DateTime: 'ASC' }],
        },
      },
      'Task.UpcomingCount': {
        Scope: {
          Fields: {
            '$COUNT()': 1,
          },
        },
        Criteria: {
          SearchQuery: upcomingObject,
        },
      },
      Globalization: Octopus.DefaultGlobalization,
    };

    return this.http
      .post<TaskSummaryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
        {
          headers: { [insightsHeader('entity')]: 'TasksSummary' },
        },
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new TaskSummaryMapper();
            return mapper.from(result);
          }

          return {
            overdueTaskAmount: 0,
            upcomingTaskAmount: 0,
            users: [],
          };
        }),
      );
  }

  private getSummaryQuery(
    key: string,
    datetime: string,
    isGreater: boolean,
    type: string,
  ): Octopus.LogicalQuery<Octopus.Task> {
    const query: Octopus.LogicalQuery<Octopus.Task> = {
      $AND: [],
    };

    query.$AND?.push({ Completed: { $EQ: false } });

    if (type == 'lead') {
      query.$AND?.push({ LeadKey: { $EQ: key } });
    }
    if (type == 'abentry') {
      query.$AND?.push({ AbEntryKey: { $EQ: key } });
    }

    if (isGreater) {
      query.$AND?.push({ DateTime: { $GT: datetime } });
    } else {
      query.$AND?.push({ DateTime: { $LT: datetime } });
    }

    return query;
  }

  private getSummaryScope(): Octopus.Scope<Octopus.Task> {
    return {
      Key: 1,
      Creator: { Uid: 1, Value: 1 },
      AssignedTo: { Key: 1, DisplayValue: 1 },
      DateTime: 1,
      CreationDate: 1,
      Priority: 1,
      Completed: 1,
      Activity: 1,
      AbEntryKey: { ID: 1, Number: 1, Value: 1 },
      AbEntry: { Type: 1 },
      LeadKey: { ID: 1, Number: 1, Value: 1 },
      With: { OpportunityObject: { Key: { Id: 1, Value: 1 } } },
      Result: 1,
      Category: 1,
    };
  }

  create(taskForm: TaskForm): Observable<string | null> {
    const mapper = new TaskFormMapper();
    const data = mapper.from(taskForm);
    const request: Octopus.TaskCreateRequest = {
      Task: {
        Data: data,
      },
    };
    return this.http
      .post<Octopus.TaskCreateResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            return result.Task.Data.Key;
          }
          throw new HttpErrorResponse({ error: 'Unsuccessful request' });
        }),
      );
  }
}
