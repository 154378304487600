import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Octopus, SystemInfo } from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import { ContextService } from './context.service';

@Injectable()
export class SystemService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  getInfo(): Observable<SystemInfo | undefined> {
    return this.http
      .post<Octopus.SystemInfoResponse>(
        `${this.context.api}${Octopus.Action.SYSTEM_INFO}`,
        '',
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.Data
          ) {
            const mapper = new Octopus.SystemInfoMapper();
            return mapper.from(result.Data);
          }
          return undefined;
        }),
      );
  }

  /**
   * New OutlookSync API requires v2024M7 and up for authentication
   * Supported API Version; Major 2 Minor 45
   */
  validateDbVersionForNewSync(systemInfo: SystemInfo): boolean {
    if (!systemInfo) {
      return false;
    } else {
      return (
        systemInfo.VersionMajor > 2 ||
        (systemInfo.VersionMajor === 2 && systemInfo.VersionMinor >= 45)
      );
    }
  }
}
