import { EntityFields } from './entity';
import { ReadRequest, Request, RequestConfiguration } from './request';
import { DataResponse, Response } from './response';

export enum FavoriteListFields {
  Name = 'Name',
  EntityType = 'EntityType',
  Description = 'Description',
}

export interface FavoriteList {
  [EntityFields.Key]: string;
  [FavoriteListFields.Name]: string;
  [FavoriteListFields.EntityType]: number;
  [FavoriteListFields.Description]: string;
  [EntityFields.Creator]: string;
  [EntityFields.CreationDate]: string;
}

export interface FavoriteListReadRequest extends Request {
  FavoriteList: ReadRequest<FavoriteList>;
}

export interface FavoriteListResponse extends Response {
  FavoriteList: DataResponse<FavoriteList>;
}

export const FavoriteListReadDriver: RequestConfiguration = {
  Drivers: {
    IFavoriteListSearcher: 'Maximizer.Model.Access.Sql.FavoriteListSearcher',
  },
};
