<maximizer-outlook-menu
  pageType="none"
  [showOnlyTitle]="true"
  [pageTitle]="'outlook.auth.menu-title' | translate"
  [forceWindowRefresh]="true"
></maximizer-outlook-menu>

<div class="w-full h-full bg-neutral-10 flex scrollbar">
  <div class="container text-center flex flex-col px-3">
    <p class="h6">{{ 'outlook.auth.title' | translate }}</p>
    <p class="text-body mt-4 text-justify">
      {{ 'outlook.auth.description' | translate }}
    </p>

    <button
      kendoButton
      id="outlook-auth-ui-login-login"
      class="mt-6 w-[200px] row mx-auto"
      themeColor="primary"
      (click)="openDialog()"
    >
      {{ 'outlook.auth.login-btn' | translate }}
    </button>

    <button
      kendoButton
      id="outlook-auth-ui-login-signup"
      (click)="openLink('signup')"
      class="mt-2 w-[200px] row mx-auto hover:bg-neutral-20"
      fillMode="flat"
      themeColor="primary"
    >
      {{ 'outlook.auth.signup-btn' | translate }}
    </button>

    <p class="text-body mt-4">{{ 'outlook.auth.subtitle' | translate }}</p>
    <div class="flex mt-4 justify-center items-center">
      <button
        kendoButton
        id="outlook-auth-ui-login-policy"
        (click)="openLink('policy')"
        themeColor="primary"
        fillMode="link"
        class="text-body my-auto text-inserv-100"
      >
        {{ 'outlook.auth.policy' | translate }}
      </button>
      <span>|</span>
      <button
        kendoButton
        id="outlook-auth-ui-login-terms"
        (click)="openLink('terms')"
        themeColor="primary"
        fillMode="link"
        class="text-body my-auto text-inserv-100"
      >
        {{ 'outlook.auth.terms' | translate }}
      </button>
    </div>
    <p class="caption mt-4">
      {{ 'outlook.menu.appVersion' | translate }} {{ appVersion }}
    </p>
  </div>
</div>

<maximizer-outlook-notification #notification [closable]="true">
</maximizer-outlook-notification>
