/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FeatureService,
  GlobalStore,
  OAuth2Service,
  SessionStorageService,
  ContextService,
} from '@maximizer/core/shared/data-access';
import {
  CDN_URL,
  GlobalServicesLoginDetails,
} from '@maximizer/core/shared/domain';
import {
  INTEGRATION_SUBSCRIPTION_KEY,
  INTEGRATION_URL,
  OutlookService,
  OutlookSyncService,
  APP_STORE_OAUTH_URL,
  getDecodedAccessToken,
  constructAppStoreParams,
  constructAppStoreForm,
  INTEGRATION_WEBHOOK_URL,
  WEBHOOK_CREATE_EARLY_ACCESS,
  WEBHOOK_READ_EARLY_ACCESS,
  InsightsLogDetails,
  logCustomEventDetailsToInsights,
  OutlookInsightsEvents,
} from '@maximizer/outlook/shared/data-access';
import { TranslateService } from '@ngx-translate/core';
import { OutlookEmail } from '@maximizer/outlook/shared/domain';
import { SaveToMultiselect } from './save-to-email.model';
import { catchError, finalize, of } from 'rxjs';
import { InsightsService } from '@maximizer/core/shared/insights';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { DisposableComponent } from '@maximizer/core/shared/ui';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'maximizer-outlook-compose-save-to-email',
  templateUrl: './save-to-email.component.html',
})
export class OutlookComposeSaveToEmailComponent
  extends DisposableComponent
  implements OnInit
{
  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  private refreshTokenIntervalPopulated:
    | ReturnType<typeof setInterval>
    | undefined;

  private readonly storageName_saveToSwitch = 'saveToSwitch';
  private readonly storageName_emailsToSave = 'emailsToSaveInMaximizer';
  private readonly onSendHandler_token = 'Mx-Id';
  private readonly onSendHandler_entries = 'Mx-Keys';
  private readonly onSendHandler_api = 'Mx-Api';

  get showSaveEmailData() {
    return (
      this.isEmailSyncFeatureFlagEnabled &&
      !this.isOutlookLegacyDesktop &&
      !this.loading.sync &&
      !this.loading.skeleton
    );
  }

  logInDetails?: GlobalServicesLoginDetails | null;
  emailsToSaveInMaximizer: OutlookEmail[] = [];
  outlookEmails: OutlookEmail[] = [];
  emailsToIgnore: OutlookEmail[] = [];
  saveToEmailSwitch = false;
  saveEmailToEntriesTotal = 0;
  internetMessageId: string | null = null;
  composeId: string | null = null;
  savedTokenLocally = false;
  isEmailSyncFeatureFlagEnabled = false;
  isOutlookLegacyDesktop = false;
  configurationId: string | undefined = undefined;
  outlookSyncInstalled = false;
  showEarlyAccessRequest = true;
  isFirstLoad = true;

  loading = {
    spinner: false,
    skeleton: false,
    sync: false,
    earlyAccess: false,
  };

  parentItems = ['to', 'cc', 'bcc'];
  emailListForMultiselect: SaveToMultiselect[] = [
    {
      id: 1,
      localId: 'to',
      translation: 'outlook.compose.multiselect.to',
      displayName: '',
      items: [],
    },
    {
      id: 2,
      localId: 'cc',
      translation: 'outlook.compose.multiselect.cc',
      displayName: '',
      items: [],
    },
    {
      id: 3,
      localId: 'bcc',
      translation: 'outlook.compose.multiselect.bcc',
      displayName: '',
      items: [],
    },
  ];

  constructor(
    @Inject(CDN_URL) public readonly cdn: string,
    @Inject(INTEGRATION_URL) private readonly integrationApi: string,
    @Inject(INTEGRATION_SUBSCRIPTION_KEY)
    private readonly integrationKey: string,
    @Inject(APP_STORE_OAUTH_URL) private readonly appStoreUrl: string,
    @Inject(INTEGRATION_WEBHOOK_URL) private readonly webhookUrl: string,
    @Inject(WEBHOOK_CREATE_EARLY_ACCESS)
    private readonly createEarlyAccess: string,
    @Inject(WEBHOOK_READ_EARLY_ACCESS) private readonly readEarlyAccess: string,
    private readonly translate: TranslateService,
    private readonly storage: SessionStorageService,
    private readonly outlookService: OutlookService,
    private readonly oauth2Service: OAuth2Service,
    private readonly outlookSyncService: OutlookSyncService,
    private readonly insightsService: InsightsService,
    private readonly contextService: ContextService,
    private readonly oAuthService: OAuth2Service,
    private readonly http: HttpClient,
    public globalStore: GlobalStore,
    public feature: FeatureService,
  ) {
    super();
    this.isEmailSyncFeatureFlagEnabled = feature.isFeatureOn(
      'microsoft-outlook-sync-emails-shown',
      false,
    );
    this.isOutlookLegacyDesktop = this.outlookService.isOutlookLegacyDesktop();
  }

  ngOnInit(): void {
    this.logInDetails = this.oAuthService.getStorageLoginDetails();

    if (!this.isEmailSyncFeatureFlagEnabled) {
      this.setEarlyAccessStatus();
    }

    if (this.isOutlookLegacyDesktop || !this.isEmailSyncFeatureFlagEnabled) {
      return;
    }

    for (const entry of this.emailListForMultiselect) {
      entry.displayName = this.translate.instant(entry.translation);
    }

    if (!this.oauth2Service.mxUserId) {
      return;
    }

    try {
      this.loading.sync = true;
      this.outlookSyncService
        .getConfiguration(
          this.oauth2Service.mxUserId,
          this.oauth2Service.mxWorkspaceId,
        )
        .pipe(
          catchError(async () => {
            return null;
          }),
        )
        .subscribe(async (result) => {
          if (!result?.enabled) {
            this.loading.sync = false;
            this.outlookSyncInstalled = false;
            return;
          }

          this.outlookSyncInstalled = result.enabled;
          this.configurationId = result.id;
          await this.populateOnSendProperties(result.id);
          this.loading.sync = false;
        });
    } catch (error) {
      console.error('Error', error);
    } finally {
      this.loading.skeleton = false;
      this.loading.spinner = false;
    }
  }

  /**
   * This method is called every time there's a change in the email addresses in Outlook
   * @param emails Added emails from Outlook (To, Cc, Bcc)
   * @param maximizerEntries Related entries based on the emails
   */
  async initialize(
    emails: OutlookEmail[],
    maximizerEntries: OutlookEmail[],
  ): Promise<void> {
    if (!this.loading.sync && !this.outlookSyncInstalled) {
      return;
    }

    try {
      this.loading.skeleton = true;
      this.outlookEmails = emails;
      const classifiedEmails = this.classifyMaximizerAndOutlookEmails(
        emails,
        maximizerEntries,
      );
      this.sortEmailsWithOutlookOrder(classifiedEmails);

      const resultWithLocalId: OutlookEmail[] = [];
      for (let i = 0; i < classifiedEmails.length; i++) {
        const result: OutlookEmail = { ...classifiedEmails[i] };
        result.localId = result.emailAddress + i;
        resultWithLocalId.push(result);
      }
      this.populateMultiselectComponent(resultWithLocalId);
      if (this.isFirstLoad) {
        this.isFirstLoad = false;
        return await this.handleFirstLoad();
      }
      return await this.handleLaterLoads();
    } catch (error) {
      this.emailsToSaveInMaximizer = [];
      await this.checkFirstSectionItems_To();
    } finally {
      this.saveToEmailSwitch = this.emailsToSaveInMaximizer.length > 0;
      this.loading.skeleton = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  expandParentNode(_: unknown): boolean {
    return true;
  }

  resetComponentProperties(): void {
    this.loading.skeleton = false;
    this.emailsToSaveInMaximizer = [];
    this.outlookEmails = [];

    this.saveToEmailSwitch = false;
    this.saveEmailToEntriesTotal = 0;
    this.emailListForMultiselect.forEach((o) => (o.items = []));

    this.outlookService
      .getComposeEmailId()
      .then((mailboxItemId) => {
        this.storage.remove(
          this.storageName_emailsToSave + '-' + mailboxItemId,
        );
        this.storage.remove(
          this.storageName_saveToSwitch + '-' + mailboxItemId,
        );
      })
      .catch(() => {
        //ignore
      });
  }

  /**
   * Populate the properties that will be sent to the API with OnSendHandler.js
   */
  private async populateOnSendProperties(
    configurationSyncId: string,
  ): Promise<void> {
    if (this.refreshTokenIntervalPopulated) return;
    this.composeId = await this.outlookService.getComposeEmailId();
    if (!this.composeId) return;
    this.internetMessageId = await this.outlookService.getInternetMessageId(
      this.composeId,
    );

    await this.verifyRefreshTokenAndSave();

    if (
      !this.internetMessageId ||
      !this.integrationApi ||
      !this.integrationKey ||
      !configurationSyncId
    ) {
      return;
    }

    const apiConfig = {
      emailId: this.composeId,
      url: this.integrationApi,
      key: this.integrationKey,
      internetId: this.internetMessageId,
      syncId: configurationSyncId,
    };
    await this.saveToOutlook(this.onSendHandler_api, apiConfig, true);
    await this.savePropertiesInOutlook();

    if (this.refreshTokenIntervalPopulated) return;
    this.refreshTokenIntervalPopulated = setInterval(async () => {
      await this.verifyRefreshTokenAndSave();
      await this.savePropertiesInOutlook();
    }, 60 * 1000);
  }

  private async handleFirstLoad(): Promise<void> {
    const exists = await this.checkIfMultiselectExistsInStorage();
    if (!exists) {
      this.emailsToSaveInMaximizer = [];
      await this.checkFirstSectionItems_To();
    } else {
      const children = this.emailListForMultiselect
        .flatMap((o) => o.items)
        .filter((o) => exists.includes(o.id ?? ''));

      this.handleMultiselectTreeChange(children);

      this.saveToEmailSwitch = await this.getSwitchInSession();
    }
  }

  private async handleLaterLoads(): Promise<void> {
    const emailList = this.getAllEmails();
    const emailsToSave = [...this.emailsToSaveInMaximizer];
    const ignored = [...this.emailsToIgnore];
    const emailsInToWithoutIgnored = emailList.filter(
      (o) =>
        o.id && o.position === 'to' && !ignored.find((ig) => ig.id === o.id),
    );

    const result = emailsToSave
      .concat(emailsInToWithoutIgnored)
      .filter((save) => emailList.find((o) => o.id === save.id));

    this.emailsToSaveInMaximizer = [];
    await this.handleMultiselectTreeChange(result);
    this.saveEmailToEntriesTotal = emailList.length;
  }

  private getAllEmails() {
    return this.emailListForMultiselect.flatMap((o) => o.items);
  }

  private async checkFirstSectionItems_To() {
    const outlookEmail = this
      .emailListForMultiselect[0] as unknown as OutlookEmail;
    const enableFirstTime: OutlookEmail[] = [
      ...this.emailListForMultiselect[0].items.filter((o) => o.id),
    ];
    enableFirstTime.push(outlookEmail);
    await this.handleMultiselectTreeChange(enableFirstTime);
  }

  private sortEmailsWithOutlookOrder(array: OutlookEmail[]): OutlookEmail[] {
    const map = this.outlookEmails.map((o) => o.emailAddress + o.position);
    return array.sort(
      (a, b) =>
        map.indexOf(a.emailAddress + a.position) -
        map.indexOf(b.emailAddress + b.position),
    );
  }
  private classifyMaximizerAndOutlookEmails(
    emails: OutlookEmail[],
    maximizerEntries: OutlookEmail[],
  ) {
    if (emails.length === 0) return [];
    if (maximizerEntries.length === 0) return emails;

    const resultArray: OutlookEmail[] = [];
    emails.forEach((o) => {
      const emailsInMaximizer = [
        ...maximizerEntries.filter((m) => m.emailAddress === o.emailAddress),
      ];
      if (emailsInMaximizer.length > 0) {
        for (const item of emailsInMaximizer) {
          const email = { ...item };
          email.position = o.position;
          resultArray.push(email);
        }
      } else {
        resultArray.push(o);
      }
    });
    return resultArray;
  }

  private populateMultiselectComponent(emailsInput: OutlookEmail[]) {
    const multiselectDropdownEmails: {
      to: OutlookEmail[];
      cc: OutlookEmail[];
      bcc: OutlookEmail[];
    } = { to: [], cc: [], bcc: [] };

    emailsInput.forEach((item) => {
      switch (item.position) {
        case 'to':
          multiselectDropdownEmails.to.push(item);
          break;
        case 'cc':
          multiselectDropdownEmails.cc.push(item);
          break;
        case 'bcc':
          multiselectDropdownEmails.bcc.push(item);
          break;
        default:
          break;
      }
    });

    this.emailListForMultiselect[0].items = multiselectDropdownEmails.to;
    this.emailListForMultiselect[1].items = multiselectDropdownEmails.cc;
    this.emailListForMultiselect[2].items = multiselectDropdownEmails.bcc;

    this.emailListForMultiselect = [...this.emailListForMultiselect];
    this.saveEmailToEntriesTotal =
      multiselectDropdownEmails.to.length +
      multiselectDropdownEmails.cc.length +
      multiselectDropdownEmails.bcc.length;
  }

  private async checkIfMultiselectExistsInStorage(): Promise<string[] | null> {
    try {
      const mailboxItemId = await this.outlookService.getComposeEmailId();
      if (!mailboxItemId) return null;

      const json = this.storage.get(
        this.storageName_emailsToSave + '-' + mailboxItemId,
      );
      if (!json) return null;
      const result = JSON.parse(json);
      if (!result) return null;
      return result;
    } catch (error: any) {
      this.insightsService.trackException({ error });
      return null;
    }
  }

  async handleMultiselectTreeChange(emails: OutlookEmail[]) {
    const incomingData = emails.filter(
      (o) => !this.parentItems.includes(o.localId ?? ''),
    );

    const savedData = this.emailsToSaveInMaximizer.filter(
      (o) => !this.parentItems.includes(o.localId ?? ''),
    );

    const removedEmails = savedData.filter(
      (o) => !incomingData.find((p) => p.localId === o.localId),
    );

    let result: OutlookEmail[] = [];
    if (removedEmails.length > 0) {
      result = this.handleRemovedItems(removedEmails);
    } else {
      result = this.handleNewItems(incomingData, savedData);
    }

    // Don't change the order or merge it in same for!
    result = this.checkParentItemsForMatch(emails, result);
    result = this.uncheckParentItemsForMatch(result);

    this.emailsToSaveInMaximizer = [...result];
    this.emailsToIgnore = this.getAllEmails().filter(
      (o) =>
        !this.emailsToSaveInMaximizer.find(
          (save) => o.localId === save.localId,
        ),
    );
    await this.saveEntriesForHandler(result);
    await this.saveCheckboxesInSession(result);
  }

  private async saveCheckboxesInSession(result: OutlookEmail[]) {
    try {
      const mailboxItemId = await this.outlookService.getComposeEmailId();
      if (!mailboxItemId) return;
      this.storage.set(
        'emailsToSaveInMaximizer-' + mailboxItemId,
        JSON.stringify(result.map((o: OutlookEmail) => o.id)),
      );
    } catch (error: any) {
      this.insightsService.trackException(
        { error },
        { message: 'Failed to store session saveTo emails' },
      );
    }
  }

  private handleRemovedItems(removedEmails: OutlookEmail[]) {
    const result: OutlookEmail[] = [];
    for (const email of this.emailsToSaveInMaximizer) {
      if (removedEmails.find((o) => o.id === email.id)) {
        continue;
      }
      result.push(email);
    }

    return result;
  }

  private handleNewItems(
    incomingData: OutlookEmail[],
    savedData: OutlookEmail[],
  ) {
    let output: OutlookEmail[] = [];
    output = output.concat(this.emailsToSaveInMaximizer);
    let emailsToSelect: OutlookEmail[] = [];
    for (const parent of this.emailListForMultiselect) {
      emailsToSelect = emailsToSelect.concat(parent.items);
    }
    const addedEmails = incomingData.filter(
      (o) => !savedData.find((p) => p.localId === o.localId),
    );
    for (const email of addedEmails) {
      const allOccurrences = emailsToSelect.filter(
        (o) =>
          o.id === email.id && !output.find((r) => r.localId === o.localId),
      );
      if (allOccurrences.length) {
        output = output.concat(allOccurrences);
      }
    }
    return output.filter((o) => !this.parentItems.includes(o.localId ?? ''));
  }

  private checkParentItemsForMatch(
    emails: OutlookEmail[],
    output: OutlookEmail[],
  ): OutlookEmail[] {
    const savedParents = this.emailsToSaveInMaximizer.filter((o) =>
      this.parentItems.includes(o.localId ?? ''),
    );
    const incomingParents = emails.filter((o) =>
      this.parentItems.includes(o.localId ?? ''),
    );
    const addedParents = incomingParents.filter(
      (o) => !savedParents.find((p) => p.localId === o.localId),
    );

    output = output.concat(addedParents);

    // Check parent if all items were selected
    for (const parent of this.emailListForMultiselect) {
      if (output.find((o) => o.localId === parent.localId)) continue; // Parent already added
      const itemsMatchWithParent = output.filter(
        (o) => o.position === parent.localId,
      ); // Find matches
      if (
        itemsMatchWithParent.length !== parent.items.filter((o) => o.id).length
      ) {
        continue;
      }
      output.push(parent as unknown as OutlookEmail);
    }
    return output;
  }

  private uncheckParentItemsForMatch(result: OutlookEmail[]) {
    for (const parent of this.emailListForMultiselect) {
      const exists = result.find((o) => o.localId === parent.localId);

      if (!exists) continue;

      const itemsMatchWithParent = result.filter(
        (o) => o.position === parent.localId,
      );
      if (
        parent.items.filter((o) => o.id).length > 0 &&
        itemsMatchWithParent.length === parent.items.filter((o) => o.id).length
      )
        continue;
      result = result.filter((o) => o.localId !== exists.localId);
    }
    return result;
  }

  isCheckboxDisabled(dataItem: any) {
    if (dataItem.items !== undefined) {
      return dataItem.items.every((o: OutlookEmail) => o.type === undefined);
    }

    if (dataItem.type === undefined) return true;
    return false;
  }

  countSelectedItemsIgnoringParent(event?: SaveToMultiselect[]) {
    const finalItems = event?.filter((e) => e.items === undefined) ?? [];
    return finalItems.length;
  }

  async handleSwitchValueChanged(event: boolean) {
    this.loading.spinner = true;
    try {
      if (event) {
        await this.saveEntriesForHandler(this.emailsToSaveInMaximizer);
      } else {
        await this.saveEntriesForHandler([]);
      }

      await this.savePropertiesInOutlook();
      await this.saveSwitchInSession(event);
    } catch (error: any) {
      this.insightsService.trackException(
        { error },
        {
          message: 'Failed to handle switch value change',
          event,
          emailsToSave: this.emailsToSaveInMaximizer,
        },
      );
    } finally {
      this.loading.spinner = false;
    }
  }

  private async getSwitchInSession(): Promise<boolean> {
    try {
      const mailboxItemId = await this.outlookService.getComposeEmailId();
      if (!mailboxItemId) return true;
      const json = this.storage.get(
        this.storageName_saveToSwitch + '-' + mailboxItemId,
      );
      if (!json) return true;
      return JSON.parse(json);
    } catch (error: any) {
      this.insightsService.trackException(
        { error },
        { message: 'Failed to get session saveTo emails' },
      );
      return true;
    }
  }

  private async saveSwitchInSession(saveToSwitch: boolean) {
    try {
      const mailboxItemId = await this.outlookService.getComposeEmailId();
      if (!mailboxItemId) return;
      this.storage.set(
        this.storageName_saveToSwitch + '-' + mailboxItemId,
        JSON.stringify(saveToSwitch),
      );
    } catch (error) {
      console.error('Failed to store session saveTo emails', error);
    }
  }

  private async saveEntriesForHandler(result: OutlookEmail[]) {
    try {
      const keys =
        result
          .filter(
            (o) =>
              o.id !== undefined && !this.parentItems.includes(o.localId ?? ''),
          )
          .map((o) => o.id ?? '') ?? [];
      if (keys.length === 0) {
        await this.saveToOutlook(this.onSendHandler_entries, [], true);
        return;
      }

      const uniqueKeys = Array.from(new Set(keys));
      const emailId = await this.outlookService.getComposeEmailId();
      if (!emailId) {
        this.insightsService.trackException(
          { error: new Error('Email composeId is null') },
          { mailbox: this.outlookService.mailbox },
        );
        return;
      }
      const uniqueEntries = uniqueKeys.map((o) => {
        const exists = result.find((r) => r.id === o);
        if (exists) {
          return {
            MaximizerEntityType: exists.type === 'abentry' ? 1 : 2,
            MaximizerKey: o,
          };
        }
        return { MaximizerEntityType: 1, MaximizerKey: o };
      });
      await this.saveToOutlook(this.onSendHandler_entries, uniqueEntries, true);
    } catch (error: any) {
      this.insightsService.trackException(
        { error },
        {
          message: 'Failed to save email',
          mailbox: this.outlookService.mailbox,
        },
      );
    } finally {
      await this.savePropertiesInOutlook();
    }
  }

  private async verifyRefreshTokenAndSave() {
    try {
      if (!this.composeId) {
        this.insightsService.trackException(
          { error: new Error('Email composeId is null') },
          { mailbox: this.outlookService.mailbox },
        );
        return;
      }
      const expires: string =
        this.oauth2Service.oAuth2Token?.expires_at.toString() ?? '';
      const expiresAtWithGap: Date = new Date(expires);
      expiresAtWithGap.setMinutes(expiresAtWithGap.getMinutes() - 1);

      if (!this.composeId || !this.oauth2Service.oAuth2Token?.access_token) {
        this.insightsService.trackException(
          { error: new Error('Email composeId is null') },
          { mailbox: this.outlookService.mailbox },
        );
        return;
      }

      if (
        expiresAtWithGap <= new Date() &&
        this.oauth2Service.oAuth2Token?.refresh_token
      ) {
        this.oauth2Service
          .refreshToken()
          .pipe(
            catchError(async (error) => {
              this.insightsService.trackException(
                { error },
                {
                  message: 'Failed to refresh token',
                  oauth: this.oauth2Service.oAuth2Token,
                },
              );
              return null;
            }),
            finalize(() => {
              this.loading.skeleton = false;
            }),
          )
          .subscribe(async (o) => {
            if (!o?.access_token) return;
            await this.saveToken(o.access_token);
            this.savedTokenLocally = true;
            await this.savePropertiesInOutlook();
          });
      } else {
        if (this.savedTokenLocally) return;
        await this.saveToken(this.oauth2Service.oAuth2Token?.access_token);
        await this.savePropertiesInOutlook();
        this.savedTokenLocally = true;
      }
    } catch (error: any) {
      this.insightsService.trackException(
        { error },
        {
          message: 'Failed to save token',
          mailbox: this.outlookService.mailbox,
          oauth: this.oauth2Service.oAuth2Token,
        },
      );
    }
  }

  private async saveToken(token: string) {
    let encryptedText = token;
    const salt = this.outlookService.storagePartitionKey;
    const shift = 24;

    for (let i = 0; i < 14; i++) {
      encryptedText = encryptedText
        .split('')
        .map((char, index) => {
          const code = char.charCodeAt(0);
          const saltShift = salt.charCodeAt(index % salt.length);
          if (code >= 65 && code <= 90) {
            return String.fromCharCode(
              ((code - 65 + shift + saltShift) % 26) + 65,
            );
          } else if (code >= 97 && code <= 122) {
            return String.fromCharCode(
              ((code - 97 + shift + saltShift) % 26) + 97,
            );
          } else {
            return char;
          }
        })
        .join('');
    }
    this.saveToOutlook(this.onSendHandler_token, encryptedText);
  }

  private async saveToOutlook(key: string, valueObj: any, stringify = false) {
    if (!this.outlookService.customProperties) {
      return;
    }
    let value = valueObj;
    if (stringify) {
      value = JSON.stringify(valueObj);
    }

    this.outlookService.customProperties.set(key, value);
  }

  private async savePropertiesInOutlook() {
    if (!this.outlookService.customProperties) {
      return;
    }

    this.outlookService.customProperties.saveAsync(
      (asyncResult: Office.AsyncResult<void>) => {
        if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
          this.insightsService.trackException(
            { error: new Error('Keys CustomProps not saved') },
            { asyncResult },
          );
        }
      },
    );
  }

  buildAppStoreFormRequest(): void {
    const { alias, token, tenantId } = this.contextService;
    const session = this.globalStore.session();

    if (!session || !tenantId || !this.logInDetails) {
      return;
    }

    const urlParams = constructAppStoreParams(
      session,
      alias,
      token,
      tenantId,
      this.logInDetails,
      true,
      this.insightsService,
    );

    if (!urlParams) {
      this.handleAppStoreFromError(
        'Compose-AppStore-Params',
        'Missing app store form parameters',
      );
      return;
    }

    const formDetails = constructAppStoreForm(
      token,
      urlParams,
      this.appStoreUrl,
      this.insightsService,
    );
    if (!formDetails) {
      this.handleAppStoreFromError(
        'Compose-AppStore-Missing-Form-Details-Error',
        'App store form details incomplete.',
      );
      return;
    }

    this.handleAppStoreFormSubmission(formDetails.form, formDetails.window);
  }

  handleAppStoreFormSubmission(
    appStoreForm: HTMLFormElement,
    newWindow: Window,
  ): void {
    try {
      newWindow.document.body.appendChild(appStoreForm);
      appStoreForm.submit();
      newWindow.focus();
    } catch (error) {
      const typedError = error as Error;
      this.handleAppStoreFromError(
        'Compose-AppStore-Form-Submit-Error',
        typedError.message,
      );
    } finally {
      this.closeTooltips();

      const event: InsightsLogDetails = {
        insights: this.insightsService,
        name: 'Compose-AppStore-Form-Success',
        eventId: OutlookInsightsEvents.AppStoreRequest,
        token: this.contextService.token,
      };

      logCustomEventDetailsToInsights(event);
      newWindow.document.body.removeChild(appStoreForm);
    }
  }

  handleAppStoreFromError(name: string, error: string) {
    const event: InsightsLogDetails = {
      insights: this.insightsService,
      name: name,
      eventId: OutlookInsightsEvents.AppStoreRequest,
      token: this.contextService.token,
      alias: this.globalStore.session()?.alias,
      error: error,
    };

    logCustomEventDetailsToInsights(event);
    this.notification.showMessage('error', 'outlook.errors.appStore', true);
    console.error(`An error occurred opening the App Store page: ${error}`);
  }

  setEarlyAccessStatus(): void {
    const hideEarlyAccess = sessionStorage.getItem('hideEarlyAccess');
    if (hideEarlyAccess === 'true') {
      this.showEarlyAccessRequest = false;
      return;
    }

    const userEmail = this.oAuthService.getStorageMxUserEmail();
    if (!userEmail) {
      return;
    }

    this.loading.earlyAccess = true;
    this.http
      .get(`${this.webhookUrl}/${this.readEarlyAccess}?email=${userEmail}`)
      .pipe(
        catchError((error) => {
          console.error('Error reading early access requests:', error);
          this.insightsService.trackException(
            { exception: error as Error },
            {
              message: `Error reading early access requests: ${error.message}`,
            },
          );
          return of(null);
        }),
        finalize(() => {
          this.loading.earlyAccess = false;
        }),
      )
      .subscribe((result) => {
        if (result && Object.keys(result).length > 0) {
          this.showEarlyAccessRequest = false;
        }
      });
  }

  hideEarlyAccessRequest(): void {
    this.showEarlyAccessRequest = false;
    sessionStorage.setItem('hideEarlyAccess', 'true');
  }

  sendEarlyAccessRequest(): void {
    this.loading.earlyAccess = true;

    const params = {
      userId: this.globalStore.session()?.user.id,
      databaseId: this.globalStore.session()?.database,
      databaseAlias: this.logInDetails?.tenant.alias,
      email: this.oAuthService.getStorageMxUserEmail(),
    };

    this.http
      .post(`${this.webhookUrl}/${this.createEarlyAccess}`, params)
      .pipe(
        catchError((error) => {
          console.error('Error sending early access request:', error);
          this.insightsService.trackException(
            { exception: error as Error },
            { message: `Error sending early access request: ${error.message}` },
          );
          this.notification.showMessage(
            'error',
            'outlook.earlyAccess.error',
            true,
          );
          return of(null);
        }),
        finalize(() => {
          this.loading.earlyAccess = false;
        }),
      )
      .subscribe(() => {
        this.showEarlyAccessRequest = false;
        sessionStorage.setItem('hideEarlyAccess', 'true');
        this.notification.showMessage(
          'success',
          'outlook.earlyAccess.notification',
          true,
        );
      });
  }
}
