import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

type OnboardingState = {
  onboarding: boolean;
  collapsed: boolean;
};

const initialState: OnboardingState = {
  onboarding: false,
  collapsed: false,
};

export const OnboardingStore = signalStore(
  withDevtools('onboarding'),
  withState(initialState),
  withMethods((store) => ({
    showOnboarding(onboarding: boolean) {
      patchState(store, () => ({ onboarding }));
    },
    isCollapsed(collapsed: boolean) {
      patchState(store, () => ({ collapsed }));
    },
  })),
);

export type OnboardingStore = InstanceType<typeof OnboardingStore>;
