import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SessionService } from '@maximizer/core/shared/data-access';
import { PipelineService } from '@maximizer/core/settings/data-access';
import { TranslateService } from '@ngx-translate/core';
import {
  OpportunityModuleConfiguration,
  OpportunityStatusLabel,
} from './add-opportunity-actions';

import { AbEntrySearchService } from '@maximizer/core/global-search/data-access';
import { ListItem, Octopus } from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
  StringValidator,
} from '@maximizer/core/shared/ui';
import { OpportunityService } from '@maximizer/outlook/opportunity/data-access';
import { forkJoin, finalize, map, Observable, catchError } from 'rxjs';
import { Validators } from '@angular/forms';
import { AbEntryService } from '@maximizer/outlook/shared/data-access';
import { OpportunityForm } from '@maximizer/outlook/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'maximizer-add-opportunity',
  templateUrl: './add-opportunity.component.html',
  standalone: false,
})
export class AddOpportunityComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;
  constructor(
    private readonly session: SessionService,
    private readonly abEntryService: AbEntryService,
    private readonly abEntrySearchService: AbEntrySearchService,
    private readonly pipelineService: PipelineService,
    private readonly opportunityService: OpportunityService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    this.configuration = {
      process: [this._pipelineObj],
      stages: [this._defaultStage],
      statuses: [],
      abentries: [],
    };

    this.form = FormWrapperBuilder.group<OpportunityForm>(
      {
        key: null,
        objective: [
          null,
          {
            validators: Validators.compose([
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(253),
              StringValidator.NotEmpty(),
            ]),
          },
        ],
        abEntryKey: [this._abEntryKey, [Validators.required]],
        abEntryName: null,
        companyName: null,
        companyKey: null,
        description: null,
        status: this.opportunityStatus.InProgress,
        processId: null,
        stageId: null,
        cost: null,
        actualRevenue: null,
        forecastRevenue: null,
        closeDate: null,
      },

      this.save,
      () => {
        this.form?.control.setErrors(null);
        if (this.form?.valid) {
          this.canSave = true;
        } else {
          this.canSave = false;
        }
      },
    );

    this.abEntryList = this.configuration.abentries.slice();
  }

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  private _abEntryKey = '';

  abEntryList: ListItem<string>[] = [];
  autocompleteLoading = false;
  previousAutocompleteInput = '';

  private readonly _defaultStage: ListItem<string> = {
    id: '-1',
    name: this.translate.instant('outlook.opportunity.stageDefault'),
  };
  private readonly _defaultProcess: ListItem<string> = {
    id: '-1',
    name: this.translate.instant('outlook.opportunity.processDefault'),
  };
  private readonly _selectedStage: ListItem<string> = this._defaultStage;

  private readonly _pipelineObj = {
    id: this._defaultProcess.id,
    name: this._defaultProcess.name,
    stages: [
      {
        id: this._selectedStage.id,
        name: this._selectedStage.name,
        probability: 0,
        targetAge: 0,
        processId: this._defaultProcess.id,
      },
    ],
  };

  form: FormGroupWrapper<OpportunityForm>;
  configuration: OpportunityModuleConfiguration;
  loading = true;
  canSave = false;

  fullFormat = 'dd MMMM yyyy';
  opportunityStatus = Octopus.OpportunityStatus;
  private _previousStatus: number = this.opportunityStatus.InProgress;

  @Input()
  set id(id: string) {
    this._abEntryKey = decodeURI(id);
  }
  get id() {
    return this._abEntryKey ?? '';
  }

  ngOnInit(): void {
    if (this._abEntryKey) this.loadAbEntry();
  }

  handleProcessChange(process: string) {
    const processObj = this.configuration?.process.find((o) => o.id == process);

    if (processObj == null || processObj?.id == this._defaultProcess.id) {
      this.configuration.stages = [this._selectedStage];
      this.form?.patch({
        processId: this._defaultProcess.id,
        stageId: this._selectedStage.id,
      });
      return;
    }

    if (!processObj.stages) {
      this.form?.patch({
        processId: processObj.id,
        stageId: null,
      });
      return;
    }

    const sortedStages = [...processObj.stages].sort(
      (a, b) => a.probability - b.probability,
    );

    const firstStage = [...processObj.stages].reduce(
      (min, current) => (current.probability < min.probability ? current : min),
      processObj.stages[0],
    );

    this.configuration.stages = sortedStages.map((o) => ({
      id: o.id ?? '',
      name: o.name,
    }));
    this.form?.patch({
      processId: processObj.id,
      stageId: firstStage.id,
    });
  }

  handlePrimaryContactValueChange(input: string) {
    const contactData = this.configuration.abentries.find(
      (o) => o.name == input,
    );
    this.form.patch({
      abEntryKey: contactData?.id,
      abEntryName: contactData?.name,
    });
  }

  handlePrimaryContactFilterChange(inputText: string) {
    if (this.previousAutocompleteInput != inputText) {
      this.previousAutocompleteInput = inputText;
    }
    if (inputText.length < 3) {
      this.autocompleteLoading = false;
      return;
    }
    this.autocompleteLoading = true;

    setTimeout(() => {
      if (this.previousAutocompleteInput == inputText) {
        this.abEntrySearchService
          .search(inputText)
          .pipe(finalize(() => (this.autocompleteLoading = false)))
          .subscribe((data) => {
            if (data.length > 0) {
              this.configuration.abentries = data.map((ab) => ({
                id: ab.key ?? '',
                name: ab.name,
              }));
            }

            this.abEntryList = this.configuration.abentries.filter(
              (s) =>
                s.name.toLowerCase().indexOf(inputText.toLowerCase()) !== -1,
            );
          });
      }
    }, 1000);
  }

  handleStatusChange(status: number) {
    if (status == this.opportunityStatus.Won) {
      this.form.patch({
        actualRevenue: this.form.value.forecastRevenue,
        forecastRevenue: null,
      });
    } else if (this._previousStatus == this.opportunityStatus.Won) {
      this.form.patch({
        forecastRevenue: this.form.value.actualRevenue,
        actualRevenue: null,
      });
    }
    this._previousStatus = status;
  }

  save(): void {
    if (!this.form) return;

    if (this.form.value.processId == this._defaultProcess.id)
      this.form.value.processId = null;

    if (this.form.value.stageId == this._selectedStage.id)
      this.form.value.stageId = null;

    const validation = this.form.validate();

    if (!validation.valid) return;
    this.loading = true;
    this.opportunityService
      .save(this.form.value)
      .pipe(
        catchError(async () => {
          this.notification.show('create', 'error');
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((result) => {
        if (result == null) return;

        this.notification.show('create', 'success');
        this.router.navigate(['../'], { relativeTo: this.route });
      });
  }

  cancel(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private initStatuses(): Observable<ListItem<number>[]> {
    return this.translate.get('outlook.opportunity.statusValues').pipe(
      map((labels: OpportunityStatusLabel) => {
        return [
          { id: Octopus.OpportunityStatus.New, name: labels.new },
          { id: Octopus.OpportunityStatus.InProgress, name: labels.inProgress },
          { id: Octopus.OpportunityStatus.Won, name: labels.won },
          { id: Octopus.OpportunityStatus.Lost, name: labels.lost },
          { id: Octopus.OpportunityStatus.Abandoned, name: labels.abandoned },
          { id: Octopus.OpportunityStatus.Suspended, name: labels.suspended },
        ];
      }),
    );
  }

  private loadAbEntry(): void {
    this.configuration = {
      abentries: [],
      process: [this._pipelineObj],
      stages: [this._selectedStage],
      statuses: [],
    };
    forkJoin({
      oppProcess: this.pipelineService.getPipelines(),
      currentUser: this.session.getCurrentUser(),
      statuses: this.initStatuses(),
      abentry: this.abEntryService.getByKey(this._abEntryKey),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('save', 'error');
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (data == null) return;
        if (data.oppProcess.length <= 0) return;

        const sortedProcess = [...data.oppProcess].sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        const sortedStatuses = [...data.statuses].sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        sortedProcess.unshift(this._pipelineObj);

        this.configuration = {
          process: sortedProcess,
          stages: [this._selectedStage],
          statuses: sortedStatuses,
          abentries: [{ id: this._abEntryKey, name: data.abentry?.name ?? '' }],
        };

        this.abEntryList = [
          { id: this._abEntryKey, name: data.abentry?.name ?? '' },
        ];

        this.form?.patch({
          processId: this._pipelineObj.id,
          stageId: this._selectedStage.id,
          companyName: data.abentry?.companyName ?? '',
          companyKey: data.abentry?.parentKey ?? '',
          abEntryKey: this._abEntryKey,
          abEntryName: data.abentry?.name,
        });
      });
  }
}
