import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'maximizer-outlook-onboarding-remind-later',
  template: `
    <kendo-dialog [width]="256">
      <div class="py-2">
        <p class="text-body">
          {{ 'outlook.onboarding.later.message' | translate }}
        </p>
        <div class="flex justify-end">
          <button
            kendoButton
            themeColor="primary"
            class="uppercase"
            (click)="this.showRemindLater.emit(false)"
          >
            {{ 'outlook.buttons.ok' | translate }}
          </button>
        </div>
      </div>
    </kendo-dialog>
  `,
  standalone: false,
})
export class RemindLaterComponent {
  @Output() showRemindLater = new EventEmitter<boolean>();
}
