/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  ViewContainerRef,
} from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Directive({
  selector: '[kendoTooltip]',
  standalone: false,
})
export class KendoTooltipPopupDirective implements OnChanges {
  @Input() popupTarget?: ElementRef;
  @Input() isOpen?: boolean;

  tooltip: TooltipDirective;

  constructor(
    private readonly element: ElementRef,
    private readonly view: ViewContainerRef,
  ) {
    this.tooltip = this.view.injector.get(TooltipDirective);
  }

  ngOnChanges(): void {
    if (this.popupTarget) {
      (this.tooltip as any).popupService.container = this.popupTarget;
    }

    if (this.isOpen !== undefined) {
      if (this.isOpen) {
        this.tooltip.show(this.element);
      } else {
        this.tooltip.hide();
      }
    }
  }
}
