import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import {
  UiModule,
  defaultTranslateConfiguration,
} from '@maximizer/core/shared/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CaseCardComponent } from './case-card/case-card.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { AddCaseComponent } from './add-case/add-case.component';
import { OutlookMenuUiModule } from '@maximizer/outlook/menu/ui';
import { LabelModule } from '@progress/kendo-angular-label';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { OutlookSharedUiModule } from '@maximizer/outlook/shared/ui';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

@NgModule({
  declarations: [CaseCardComponent, CaseCardComponent, AddCaseComponent],
  exports: [CaseCardComponent, AddCaseComponent],
  imports: [
    CommonModule,
    UiModule,
    ButtonsModule,
    OutlookMenuUiModule,
    LabelModule,
    FormFieldModule,
    InputsModule,
    DropDownsModule,
    OutlookSharedUiModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipsModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class OutlookCaseUiModule {}
