import { Address } from './address';
import { Email } from './email';
import { PhoneNumber } from './phone-number';
import { Security } from './security';

export interface User {
  key: string;
  id: string;
  name: string;
  roles: UserRoles;
  rights: UserRights;
  phone1: PhoneNumber;
  phone2: PhoneNumber;
  phone3: PhoneNumber;
  phone4: PhoneNumber;
  email1: Email;
  email2: Email;
  email3: Email;
  displayName: string;
  mrMs: string;
  initial: string;
  firstName: string;
  lastName: string;
  companyName: string;
  position: string;
  department: string;
  division: string;
  webSite: string;
  address: Address;
  enabled?: boolean;
  webAccess?: boolean;
  serviceAccess?: boolean;
  creationDate?: string;
  lastLoginDate?: string;
  lastModifyDate?: string;
}
export interface CreateUser {
  key: string | null;
  id: string;
  accessType: number[];
  password: string;
  roles?: UserRoles;
  rights?: {
    addressBook: Security;
    accountSetup: Security;
    userDefinedFieldSetup: Security;
    userDefinedFields: Security;
    customerService: Security;
    modifyPrivateEntries: boolean;
  };
  email1: Email;
}
export interface UserRoles {
  administrator: boolean;
  salesManager: boolean;
  salesRepresentative: boolean;
  customerServiceManager: boolean;
  customerServiceRepresentative: boolean;
}

export interface UserRights {
  addressBook: Security;
  accountSetup: Security;
  targets: Security;
  opportunities: Security;
  userDefinedFieldSetup: Security;
  userDefinedFields: Security;
  privateEntries: boolean;
  globalEdit: boolean;
  modifyKeyFields: boolean;
  modifySystemTables: boolean;
  import: boolean;
  export: boolean;
  interactionLog: Security;
  notes: Security;
  documents: Security;
  customerService: Security;
  modifyPrivateEntries: boolean;
  lead: Security;
}

export interface UserForm {
  DisplayName: string;
  MrMs: string;
  Initial: string;
  FirstName: string;
  LastName: string;
  CompanyName: string;
  Position: string;
  Department: string;
  Division: string;
  WebSite: string;
  Address: string;
  City: string;
  Country: string;
  StateProvince: string;
  ZipCode: string;
  Phones: Array<PhoneNumber>;
  Emails: Array<Email>;
}

export class UserFormComparer {
  static areEqual(originalObj?: UserForm, changedObj?: UserForm): boolean {
    if (!originalObj || !changedObj) { return false; }

    // Compare top-level properties
    if (
      originalObj.DisplayName !== changedObj.DisplayName ||
      originalObj.MrMs !== changedObj.MrMs ||
      originalObj.Initial !== changedObj.Initial ||
      originalObj.FirstName !== changedObj.FirstName ||
      originalObj.LastName !== changedObj.LastName ||
      originalObj.CompanyName !== changedObj.CompanyName ||
      originalObj.Position !== changedObj.Position ||
      originalObj.Department !== changedObj.Department ||
      originalObj.Division !== changedObj.Division ||
      originalObj.WebSite !== changedObj.WebSite ||
      originalObj.Address !== changedObj.Address ||
      originalObj.City !== changedObj.City ||
      originalObj.Country !== changedObj.Country ||
      originalObj.StateProvince !== changedObj.StateProvince ||
      originalObj.ZipCode !== changedObj.ZipCode
    ) {
      return false;
    }

    // Compare Phones arrays
    if (originalObj.Phones.length !== changedObj.Phones.length) {
      return false;
    }
    for (let i = 0; i < originalObj.Phones.length; i++) {
      const phone1 = originalObj.Phones[i];
      const phone2 = changedObj.Phones[i];

      if (
        phone1.phoneNumber !== phone2.phoneNumber ||
        phone1.extension !== phone2.extension
      ) {
        return false;
      }
    }

    // Compare Emails arrays
    if (originalObj.Emails.length !== changedObj.Emails.length) {
      return false;
    }
    for (let i = 0; i < originalObj.Emails.length; i++) {
      const email1 = originalObj.Emails[i];
      const email2 = changedObj.Emails[i];

      if (
        email1.email !== email2.email ||
        email1.description !== email2.description ||
        email1.validated !== email2.validated ||
        email1.default !== email2.default ||
        email1.isNew !== email2.isNew
      ) {
        return false;
      }
    }

    return true;
  }
}

export enum TrialUserStatus {
  Accepted = 'Accepted',
  Pending = 'Pending'
}

export interface TrialUser {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  status: TrialUserStatus;
  dateSent: string;
}

export interface TrialUserGrid extends TrialUser {
  resendAllowed: boolean;
  upgradeAllowed: boolean;
}

export interface InviteTrialUserForm {
  licenseType: string;
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
}
