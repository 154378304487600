import { HttpClient } from '@angular/common/http';
import {
  AccessDeniedError,
  DisplayEntity,
  Entity,
  Field,
  FieldSet,
  Key,
  ListItem,
  Octopus,
} from '@maximizer/core/shared/domain';
import { Observable, map, of, switchMap } from 'rxjs';
import { ContextService } from './context.service';
import { MetadataService } from './metadata.service';

export abstract class EntityService {
  readonly MetadataScope: Octopus.Scope<Octopus.Metadata> = {
    Key: {
      Value: 1,
    },
    Name: 1,
    Description: 1,
    Alias: 1,
    AppliesTo: 1,
    Type: 1,
    Assignable: 1,
    Inactive: 1,
    Sortable: 1,
    Queryable: 1,
    HasOption: 1,
    ReadOnly: 1,
    Nullable: 1,
    Attributes: 1,
    Folder: 1,
    Path: 1,
    Mandatory: 1,
    Formula: 1,
    SortValue: 1,
  };

  constructor(
    protected entity: Octopus.EntityCode,
    protected http: HttpClient,
    protected context: ContextService,
    protected metadata: MetadataService,
  ) {}

  abstract get readDriver(): Octopus.RequestConfiguration;

  abstract get writeDriver(): Octopus.RequestConfiguration;

  getKeyAndDisplayScope(
    metadata = true,
  ): Octopus.ScopeWithMetadata<Octopus.KeyAndDisplay> {
    return {
      $Metadata: metadata ? this.MetadataScope : undefined,
      Key: 1,
      DisplayValue: 1,
    };
  }

  getCollectionScope(
    metadata = true,
  ): Octopus.ScopeWithMetadata<Octopus.Collection<Octopus.KeyAndDisplay>> {
    return {
      $Metadata: metadata ? this.MetadataScope : undefined,
      Items: [
        {
          Key: 1,
          DisplayValue: 1,
        },
      ],
      DisplayValue: 1,
    };
  }

  getPartnerCompetitorScope(
    metadata = true,
  ): Octopus.ScopeWithMetadata<Octopus.Collection<Octopus.PartnerCompetitor>> {
    return {
      $Metadata: metadata ? this.MetadataScope : undefined,
      Items: [
        {
          Key: 1,
          DisplayValue: 1,
          Comment: 1,
        },
      ],
      DisplayValue: 1,
    };
  }

  getValueAndDisplayScope<T = unknown>(
    metadata = true,
  ): Octopus.ScopeWithMetadata<Octopus.ValueAndDisplay<T>> {
    return {
      $Metadata: metadata ? this.MetadataScope : undefined,
      Value: 1,
      DisplayValue: 1,
    };
  }

  getAddressKeyScope(
    metadata = true,
  ): Octopus.ScopeWithMetadata<Octopus.AddressKey> {
    return {
      $Metadata: metadata ? this.MetadataScope : undefined,
      Value: 1,
      Number: 1,
      ID: 1,
      EntityType: 1,
    };
  }

  getCurrencyScope(
    metadata = true,
  ): Octopus.ScopeWithMetadata<Octopus.CurrencyValue> {
    return {
      $Metadata: metadata ? this.MetadataScope : undefined,
      Value: 1,
      CurrencyCode: 1,
      DisplayValue: 1,
    };
  }

  getPhoneScope(
    metadata = true,
  ): Octopus.ScopeWithMetadata<Octopus.EntityPhone> {
    return {
      $Metadata: metadata ? this.MetadataScope : undefined,
      Description: this.getValueAndDisplayScope<string>(metadata),
      Number: this.getValueAndDisplayScope<string>(metadata),
      Extension: this.getValueAndDisplayScope<string>(metadata),
      DisplayValue: 1,
    };
  }

  getEmailScope(
    metadata = true,
  ): Octopus.ScopeWithMetadata<Octopus.EntityEmail> {
    return {
      $Metadata: metadata ? this.MetadataScope : undefined,
      Default: this.getValueAndDisplayScope<boolean>(metadata),
      Description: this.getValueAndDisplayScope<string>(metadata),
      Address: this.getValueAndDisplayScope<string>(metadata),
      DisplayValue: 1,
    };
  }

  getAddressScope(
    metadata = true,
  ): Octopus.ScopeWithMetadata<Octopus.Address<Octopus.EntityAddress>> {
    return {
      $Metadata: metadata ? this.MetadataScope : undefined,
      Key: this.getAddressKeyScope(metadata),
      Description: this.getValueAndDisplayScope<string>(metadata),
      AddressLine1: this.getValueAndDisplayScope<string>(metadata),
      AddressLine2: this.getValueAndDisplayScope<string>(metadata),
      City: this.getValueAndDisplayScope<string>(metadata),
      Country: this.getValueAndDisplayScope<string>(metadata),
      StateProvince: this.getValueAndDisplayScope<string>(metadata),
      ZipCode: this.getValueAndDisplayScope<string>(metadata),
      Default: this.getValueAndDisplayScope<boolean>(metadata),
      DisplayValue: 1,
    };
  }

  getSecurityAccessScope(
    metadata = true,
  ): Octopus.ScopeWithMetadata<Octopus.EntitySecurityAccess> {
    return {
      $Metadata: metadata ? this.MetadataScope : undefined,
      Read: this.getCollectionScope(metadata),
      Write: this.getCollectionScope(metadata),
    };
  }

  getScopeForFields(fields: Field[]): Octopus.Scope<Entity> {
    const scope: Octopus.Scope<Entity> = {};
    fields.forEach((field) => {
      switch (field.metadata?.type) {
        case Octopus.FieldType.CurrencyField:
          scope[field.id] = this.getCurrencyScope(false);
          break;
        case Octopus.FieldType.AbEntryObject:
        case Octopus.FieldType.AbEntryKey:
        case Octopus.FieldType.CampaignObject:
        case Octopus.FieldType.SalesTeamObject:
        case Octopus.FieldType.SalesProcessObject:
        case Octopus.FieldType.SalesProcessSetupObject:
        case Octopus.FieldType.SalesStageObject:
        case Octopus.FieldType.SalesStageSetupObject:
        case Octopus.FieldType.TerritoryObject:
        case Octopus.FieldType.SecAccessField:
          scope[field.id] = this.getKeyAndDisplayScope(false);
          break;
        case Octopus.FieldType.EnumFieldOfPartnerCompetitor:
          scope[field.id] = this.getPartnerCompetitorScope(false);
          break;
        default:
          scope[field.id] = this.getValueAndDisplayScope(false);
          break;
      }
    });
    return scope;
  }

  protected getTypesScope(metadata = true): Octopus.TypeScope {
    return {
      AbEntryObject: this.getKeyAndDisplayScope(metadata),
      'EnumField<StringItem>': this.getCollectionScope(metadata),
      'EnumField<UidKey>': this.getCollectionScope(metadata),
      SecAccess2LvlField: this.getSecurityAccessScope(metadata),
      '*': this.getValueAndDisplayScope(metadata),
    };
  }

  getTypesScopeForValidation(): Octopus.TypeScope {
    return {
      SalesProcessSetupObject: {
        Description: 1,
      },
      SalesStageSetupObject: {
        Description: 1,
      },
      '*': 1,
    };
  }

  getEntityCodeAndDriver(key: Key): {
    code: Octopus.EntityCode;
    configuration: Octopus.RequestConfiguration;
  } | null {
    switch (key.type) {
      case Octopus.KeyType.AbEntry:
      case Octopus.KeyType.Company:
      case Octopus.KeyType.Contact:
      case Octopus.KeyType.Individual:
        return {
          code: Octopus.AbEntryEntityCode,
          configuration: Octopus.AbEntryReadDriver,
        };

      case Octopus.KeyType.Appointment:
        return {
          code: Octopus.AppointmentEntityCode,
          configuration: Octopus.AppointmentReadDriver,
        };

      case Octopus.KeyType.CSCase:
        return {
          code: Octopus.CaseEntityCode,
          configuration: Octopus.CaseReadDriver,
        };

      case Octopus.KeyType.Opportunity:
        return {
          code: Octopus.OpportunityEntityCode,
          configuration: Octopus.OpportunityReadDriver,
        };

      case Octopus.KeyType.Task:
        return {
          code: Octopus.TaskEntityCode,
          configuration: Octopus.TaskReadDriver,
        };

      default:
        return null;
    }
  }

  read(key: string, types: Octopus.TypeScope): Observable<Entity | null> {
    const request: Octopus.EntityRequest = this.getReadRequest(key, types);

    return this.http
      .post<Octopus.EntityResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((response) => {
          if (
            response.Code === Octopus.ResponseStatusCode.Successful &&
            response[this.entity]?.Data?.length
          ) {
            return response[this.entity]?.Data[0] ?? null;
          }
          return null;
        }),
      );
  }

  forDisplay(key: string): Observable<DisplayEntity | null> {
    const types = this.getTypesScope(false);

    return this.read(key, types).pipe(
      map((entity) => {
        if (entity) {
          return Object.entries(entity).reduce((values, [id, value]) => {
            values[id] =
              ((value as Entity)?.[
                Octopus.EntityFields.DisplayValue
              ] as string) ?? '';
            return values;
          }, {} as DisplayEntity);
        }
        return null;
      }),
    );
  }

  forValidation(key: string): Observable<Entity | null> {
    const types = this.getTypesScopeForValidation();
    return this.read(key, types);
  }

  forEdit(key: string): Observable<{ value: Entity; fields: FieldSet }> {
    const types = this.getTypesScope();
    const request: Octopus.EntityRequest = this.getReadRequest(key, types);

    return this.getValueAndFields(request, this.entity);
  }
  defaultEntry(
    intent?: string,
  ): Observable<{ value: Entity; fields: FieldSet }> {
    const defaultEntry = this.entity + 'Default';
    const request: Octopus.EntityRequest = {
      [defaultEntry]: {
        Criteria: {
          SearchQuery: {
            DefaultEntryKey: { $EQ: Octopus.CurrentUser },
          },
        },
        Scope: {
          Fields: this.getFieldsForDefaultEntry(),
          Types: this.getTypesScope(),
        },
        Options: {
          All: true,
          Intent: intent ?? 'Create',
        },
      },
      Configuration: this.readDriver,
      Globalization: Octopus.DefaultGlobalization,
    };
    return this.getValueAndFields(request, defaultEntry);
  }

  validate(entity: Entity): Observable<{
    required: string[];
  }> {
    const request: Octopus.EntityWriteRequest = {
      [this.entity]: {
        Data: entity,
      },
      Configuration: this.writeDriver,
    };

    return this.http
      .post<Octopus.EntityValidationResponse>(
        `${this.context.api}${Octopus.Action.VALIDATE}`,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === Octopus.ResponseStatusCode.Successful) {
            const required: string[] = [];
            const validation = response[this.entity]?.Validation;
            if (validation) {
              Object.keys(validation).forEach((key) => {
                const rules = validation[key];
                if (rules?.Mandatory) {
                  required.push(key);
                } else if (rules instanceof Object) {
                  Object.keys(rules).forEach((childKey) => {
                    const rule = (
                      rules as { [key: string]: { Mandatory?: boolean } }
                    )[childKey];
                    if (rule?.Mandatory) {
                      required.push(key + '/' + childKey);
                    }
                  });
                }
              });
            }
            return { required };
          }
          return { required: [] };
        }),
      );
  }

  duplicateCheck(entity: Entity): Observable<Entity[]> {
    const request: Octopus.EntityWriteRequest = {
      [this.entity]: {
        Data: entity,
      },
      Configuration: {
        Drivers: {
          IAbEntryAccess: 'Maximizer.Model.Access.Sql.AbEntryAccess',
        },
      },
    };

    return this.http
      .post<Octopus.EntityDuplicateCheckResponse>(
        `${this.context.api}${Octopus.Action.DuplicateCheck}`,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === Octopus.ResponseStatusCode.Successful) {
            const responsData = response[this.entity];
            if (responsData?.Result?.HasDuplicates) {
              const entityData =
                (responsData.Data as unknown as Entity[]) ?? [];
              return entityData;
            }
          }
          return [];
        }),
      );
  }

  save(keyField: string, entity: Entity): Observable<string | null> {
    const request: Octopus.EntityWriteRequest = {
      [this.entity]: {
        Data: entity,
        Options: {
          IgnoreDefault: true,
        },
      },
      Configuration: this.writeDriver,
    };

    return this.http
      .post<Octopus.EntityValidationResponse>(
        `${this.context.api}${
          entity[keyField] ? Octopus.Action.UPDATE : Octopus.Action.CREATE
        }`,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === Octopus.ResponseStatusCode.Successful) {
            return (response[this.entity]?.Data[keyField] as string) ?? null;
          } else if (
            response.Code === Octopus.ResponseStatusCode.AccessDenied
          ) {
            throw new AccessDeniedError();
          }
          return null;
        }),
      );
  }

  getEntryAndContact(id: string): Observable<{
    entry: ListItem<string> | null;
    contact: ListItem<string> | null;
    partner?: string;
  } | null> {
    const key = new Key(id);
    const entity = this.getEntityCodeAndDriver(key);

    if (entity) {
      if (entity.code === Octopus.AbEntryEntityCode) {
        return this.getEntryAndContactFromAbEntry(id);
      }

      if (entity.code === Octopus.AppointmentEntityCode) {
        return this.getEntryAndContactFromAppointment(id);
      }

      return this.getEntryAndContactFrom(id, entity.code, entity.configuration);
    }

    return of(null);
  }

  private getReadRequest(
    key: string,
    types: Octopus.TypeScope,
  ): Octopus.EntityRequest {
    return {
      [this.entity]: {
        Criteria: {
          SearchQuery: {
            Key: { $EQ: key },
          },
        },
        Scope: {
          Fields: this.getFieldsForDefaultEntry(),
          Types: types,
        },
        Options: {
          All: true,
        },
      },
      Configuration: this.readDriver,
      Globalization: Octopus.DefaultGlobalization,
      Compatibility: {
        AbEntryKey: '2.0',
      },
    };
  }

  private getEntryAndContactFrom(
    id: string,
    code: Octopus.EntityCode,
    configuration: Octopus.RequestConfiguration,
  ) {
    type Entity = Pick<Octopus.Case, 'AbEntry' | 'Contact'> &
      Pick<Octopus.Opportunity, 'AbEntry' | 'Contact'> &
      Pick<Octopus.Task, 'AbEntry'>;
    type EntityResponse = Partial<{
      [key in Octopus.EntityCode]: Octopus.DataResponse<Entity>;
    }> &
      Octopus.Response;

    const request: Octopus.EntityRequest = {
      [code]: {
        Criteria: {
          SearchQuery: {
            Key: {
              $EQ: id,
            },
          },
        },
        Scope: {
          Fields: {},
          Types: {
            AbEntryObject: this.getKeyAndDisplayScope(false),
          },
        },
      },
      Configuration: configuration,
    };

    return this.http
      .post<EntityResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result[code]?.Data?.length
          ) {
            const data = result[code]?.Data[0];
            if (data) {
              return {
                entry: {
                  id: data.AbEntry.Key,
                  name: data.AbEntry.DisplayValue,
                } as ListItem<string>,
                contact: data.Contact
                  ? ({
                      id: data.Contact.Key,
                      name: data.Contact.DisplayValue,
                    } as ListItem<string>)
                  : null,
              };
            }
          }
          return null;
        }),
      );
  }

  private getEntryAndContactFromAbEntry(id: string): Observable<{
    entry: ListItem<string> | null;
    contact: ListItem<string> | null;
    partner?: string;
  } | null> {
    const request: Octopus.AbEntryReadRequest = {
      AbEntry: {
        Criteria: {
          SearchQuery: {
            Key: {
              $EQ: { Body: id, OrRelated: true },
            },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            DisplayValue: 1,
            Type: 1,
            PartnerKey: 1,
          },
        },
        Configuration: Octopus.AbEntryReadDriver,
      },
    };

    return this.http
      .post<Octopus.AbEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.AbEntry?.Data?.length
          ) {
            const contact = result.AbEntry.Data.find(
              (entry) =>
                entry.Type === Octopus.AbEntryType.Contact && entry.Key === id,
            );
            const companyOrIndividual = result.AbEntry.Data.find(
              (entry) => entry.Type !== Octopus.AbEntryType.Contact,
            );

            return {
              entry: companyOrIndividual
                ? ({
                    id: companyOrIndividual.Key,
                    name: companyOrIndividual.DisplayValue,
                  } as ListItem<string>)
                : null,
              partner: companyOrIndividual?.PartnerKey,
              contact: contact
                ? ({
                    id: contact.Key,
                    name: contact.DisplayValue,
                  } as ListItem<string>)
                : null,
            };
          }
          return null;
        }),
      );
  }

  private getEntryAndContactFromAppointment(id: string): Observable<{
    entry: ListItem<string> | null;
    contact: ListItem<string> | null;
  } | null> {
    const request: Octopus.AppointmentReadRequest = {
      Appointment: {
        Criteria: {
          SearchQuery: {
            Key: {
              $EQ: id,
            },
          },
        },
        Scope: {
          Fields: {
            AbEntries: [
              {
                Key: 1,
                DisplayValue: 1,
              },
            ],
          },
        },
        Configuration: Octopus.AppointmentReadDriver,
      },
    };

    return this.http
      .post<Octopus.AppointmentResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.Appointment?.Data?.length
          ) {
            const data = result.Appointment.Data[0];
            return data.AbEntries?.length === 1
              ? {
                  entry: {
                    id: data.AbEntries[0].Key,
                    name: data.AbEntries[0].DisplayValue,
                  } as ListItem<string>,
                  contact: null,
                }
              : null;
          }
          return null;
        }),
      );
  }

  private getValueAndFields(
    request: Octopus.EntityRequest,
    entity: string,
  ): Observable<{ value: Entity; fields: FieldSet }> {
    return this.http
      .post<Octopus.EntityResponseWithMetadata>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        switchMap((response) => {
          if (
            response.Code === Octopus.ResponseStatusCode.Successful &&
            response[entity]
          ) {
            const data = response[entity]?.Data;

            if (data?.length) {
              const value = data[0];
              const mapper = new Octopus.MetadataMapper(entity);
              const metadata = mapper.mapMetadata(value);
              const fields = mapper.mapFields(metadata);

              return of({ value, fields });
            }
          }

          return this.metadata
            .getFields(this.entity)
            .pipe(map((fields) => ({ value: {}, fields })));
        }),
      );
  }

  abstract getFieldsForDefaultEntry(): Octopus.Scope<Entity>;
}
