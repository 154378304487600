import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { CustomGridComponent } from './custom-grid.component';
import { UiLoaderModule } from '../loader/loader.module';

@NgModule({
  declarations: [CustomGridComponent],
  imports: [CommonModule, GridModule, LayoutModule, UiLoaderModule],
  exports: [CustomGridComponent],
})
export class UiCustomGridModule {}
