import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EntryType } from '@maximizer/core/shared/ui';
import {
  CDN_URL,
  InteractionListItem,
  User,
} from '@maximizer/core/shared/domain';
import { TimelineService } from '@maximizer/outlook/timeline/data-access';
import { catchError, finalize, forkJoin } from 'rxjs';
import {
  OutlookTimelineConfiguration,
  OutlookTimelineEvent,
  OutlookTimelineFilter,
} from '@maximizer/outlook/shared/domain';
import { OutlookTimelineModule } from './timeline.action';
import { SVGIcon, searchIcon } from '@progress/kendo-svg-icons';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@maximizer/core/shared/data-access';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';

@Component({
  selector: 'maximizer-outlook-timeline',
  templateUrl: './timeline.component.html',
  standalone: false,
})
export class TimelineComponent implements OnInit {
  @Input() type?: string;
  @Input() id?: string;
  @Input() entryType?: string;
  @Input() refreshEvent?: EventEmitter<void>;

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  loading = true;
  showFilterPopup = false;
  fullTimelineData: OutlookTimelineEvent[] = [];
  filteredTimelineData: OutlookTimelineEvent[] = [];
  filtersByType = 0;
  filtersByUser = 0;
  groupedTimeline: OutlookTimelineModule[] | null = [];
  importantNotes: OutlookTimelineEvent[] | null = null;
  searchIcon: SVGIcon = searchIcon;
  types?: InteractionListItem[];
  configuration: OutlookTimelineConfiguration | null = null;
  selectAllValue = false;
  users: User[] = [];
  form?: FormGroup;
  lastSearch?: string;

  constructor(
    @Inject(CDN_URL) public cdn: string,
    private readonly timelineService: TimelineService,
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.loadData();

    if (this.refreshEvent) {
      this.refreshEvent.subscribe(() => {
        this.loadData();
      });
    }
  }

  loadData(): void {
    let entryType: EntryType | null = null;
    let isCompany = false;
    if (!this.type) return;
    if (this.type === 'lead') entryType = EntryType.Lead;
    if (this.type === 'abentry') {
      entryType = EntryType.AddressBook;
      isCompany = window.atob(this.id ?? '').includes('Company');
    }
    if (!entryType) return;

    const filter: OutlookTimelineFilter = {
      interactions: [],
      notes: [],
    };

    this.loading = true;
    forkJoin({
      configuration: this.timelineService.getConfiguration(),
      users: this.userService.getActiveUsers(),
      timeline: this.timelineService.forEntry(
        this.id ?? '',
        entryType,
        filter,
        isCompany,
      ),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (!data) return;

        this.configuration = data.configuration;
        this.users = data.users;

        this.fullTimelineData = data.timeline;
        this.filteredTimelineData = this.fullTimelineData;

        this.updateTimelineValues(data.timeline);

        this.mapTypes();
        this.createForm();
      });
  }

  private createForm(): void {
    const formGroupConfig: { [key: string]: FormControl } = {};
    formGroupConfig['users'] = this.formBuilder.control([]);
    this.types?.forEach((type) => {
      formGroupConfig[type.key.toString()] = this.formBuilder.control(false);
    });

    this.form = this.formBuilder.group(formGroupConfig);
  }

  groupByYearAndMonth(events: OutlookTimelineEvent[]): OutlookTimelineModule[] {
    const groupedData: OutlookTimelineModule[] = [];
    events.forEach((item) => {
      if (!item.date?.value) return;
      let dateValue: Date;
      if (typeof item.date.value === 'string') {
        dateValue = new Date(item.date.value);
      } else {
        dateValue = item.date.value;
      }
      const year = dateValue.getFullYear();
      const month = dateValue.getMonth() + 1;

      const date = groupedData.find((o) => o.date == year + '-' + month + '-1');
      if (!date) {
        groupedData.push({
          date: year + '-' + month + '-1',
          events: [item],
        });
      } else {
        date.events.push(item);
      }
    });

    return groupedData ?? [];
  }

  filterByText(text: string): void {
    if (text.length == 0) {
      this.updateTimelineValues(this.filteredTimelineData);
      return;
    }
    const lowerText = text.toLocaleLowerCase();
    const filteredTimeline = this.filteredTimelineData.filter(
      (o) =>
        o.creator.display?.toLocaleLowerCase().includes(lowerText) ||
        o.description?.toLocaleLowerCase().includes(lowerText) ||
        o.subject?.toLocaleLowerCase().includes(lowerText),
    );

    this.updateTimelineValues(filteredTimeline);
  }

  toggleFilterPopup(forceToggle: boolean | undefined = undefined): void {
    if (forceToggle !== undefined) {
      this.showFilterPopup = forceToggle;
    } else {
      this.showFilterPopup = !this.showFilterPopup;
    }
  }

  mapTypes(): void {
    if (this.configuration) {
      this.types = [];
      const selectAll: InteractionListItem = {
        key: 'select-all',
        id: 'select-all',
        type: 'interaction',
        name: this.translate.instant('outlook.timeline.filter.selectAllTypes'),
        items: undefined,
        icon: '',
        tag: 'selectAll',
      };
      this.types?.push(selectAll);

      this.configuration.types.forEach((interaction) => {
        this.types?.push({
          key: 'type-' + interaction.type,
          id: interaction.type,
          type: 'interaction',
          name: interaction.display,
          items: undefined,
          icon: '',
          tag: this.formatInteractionTag(interaction.display),
        });
      });
    }
  }

  formatInteractionTag(name: string): string {
    if (!name) return '';
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  }

  toggleCheckbox(key: string, uncheck?: boolean): void {
    const formControl = this.form?.get(key);

    if (key === 'select-all') {
      this.selectAllValue = this.selectAllValue =
        uncheck ?? !this.selectAllValue;

      this.types?.forEach((type) => {
        const typeControl = this.form?.get(type.key);
        typeControl?.setValue(this.selectAllValue);
      });
      return;
    }

    if (!formControl) return;
    this.form?.get('select-all')?.setValue(false);
    formControl.setValue(uncheck ?? !formControl.value);
  }

  verifyCheckbox(key: string): boolean {
    return this.form?.get(key)?.value;
  }

  applyFilters(event?: Event) {
    event?.preventDefault();
    let timelineCopy: OutlookTimelineEvent[] =
      JSON.parse(JSON.stringify(this.fullTimelineData)) ?? [];
    timelineCopy = this.filterTimelineByType(timelineCopy);
    timelineCopy = this.filterTimelineByUser(timelineCopy);

    this.filteredTimelineData = timelineCopy;
    this.updateTimelineValues(timelineCopy);
    this.showFilterPopup = false;
  }

  onRemoveUserFilter(): void {
    this.form?.get('users')?.setValue([]);
    this.applyFilters();
    this.filtersByUser = 0;
  }

  onRemoveTypeFilter(): void {
    this.toggleCheckbox('select-all', false);
    this.applyFilters();
    this.filtersByType = 0;
  }

  private filterTimelineByType(
    timelineCopy: OutlookTimelineEvent[],
  ): OutlookTimelineEvent[] {
    if (!this.form || !this.types) return [];

    const formValues: { key: string; value: boolean; formKey: string }[] =
      Object.entries(this.form.value).map((item) => ({
        key: item[0].replace('type-', ''),
        value: (item[1] as boolean) ?? false,
        formKey: item[0],
      }));

    const filterBy: string[] = formValues
      .filter((o) => o.formKey.includes('type-') && o.value)
      .map((o) => o.key);

    if (filterBy.length === 0) {
      this.filtersByType = 0;
      return timelineCopy;
    } else {
      this.filtersByType = filterBy.length;
      return timelineCopy.filter((o) =>
        filterBy.includes(o.interactionType?.type ?? ''),
      );
    }
  }

  private filterTimelineByUser(
    filteredTimeline: OutlookTimelineEvent[],
  ): OutlookTimelineEvent[] {
    if (!this.form || !this.types) return [];

    const users: User[] = this.form.get('users')?.value ?? [];
    if (users.length == 0) {
      this.filtersByUser = 0;
      return filteredTimeline;
    }
    this.filtersByUser = users.length;
    return filteredTimeline.filter((o) =>
      users.find((u) => u.key == o.creator.value),
    );
  }

  private updateTimelineValues(filteredTimeline: OutlookTimelineEvent[]): void {
    const grouped = this.groupByYearAndMonth(filteredTimeline);
    this.groupedTimeline = grouped.length > 0 ? grouped : null;

    const important = filteredTimeline.filter((o) => o.important);
    this.importantNotes = important.length > 0 ? important : null;
  }
}
