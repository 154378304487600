import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'maximizer-outlook-onboarding-exit',
  template: `
    <kendo-dialog [width]="256">
      <div class="py-2 flex flex-col gap-2">
        <p class="text-body bold">
          {{ 'outlook.onboarding.exit.title' | translate }}
        </p>
        <p class="text-body">
          {{ 'outlook.onboarding.exit.message' | translate }}
        </p>
        <p class="text-body">
          {{ 'outlook.onboarding.exit.confirm' | translate }}
        </p>
        <div class="flex justify-end gap-2 pt-2">
          <button
            kendoButton
            id="outlook-onboarding-ui-exit-cancel"
            themeColor="secondary"
            class="uppercase"
            (click)="this.action.emit('cancel')"
          >
            {{ 'outlook.buttons.cancel' | translate }}
          </button>
          <button
            kendoButton
            id="outlook-onboarding-ui-exit-exit"
            themeColor="primary"
            class="uppercase"
            (click)="this.action.emit('exit')"
          >
            {{ 'outlook.buttons.exit' | translate }}
          </button>
        </div>
      </div>
    </kendo-dialog>
  `,
  standalone: false,
})
export class ExitComponent {
  @Output() action = new EventEmitter<'cancel' | 'exit'>();
}
