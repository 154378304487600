import { Component } from '@angular/core';
import { OutlookMsalService } from '@maximizer/outlook/shared/data-access';

@Component({
  selector: 'maximizer-outlook-msal-account-warning',
  template: `<div
    class="flex flex-col gap-2 justify-around bg-authorized-5 border border-authorized-90 p-2 mt-2 mx-2"
  >
    <div class="flex justify-around gap-2 items-center">
      <span
        class="fa-icons icon-20 icon-solid icon-circle-exclamation text-authorized-100"
      ></span>
      <p class="text-body large text-authorized-100">
        {{ 'outlook.auth.msal.accountWarning' | translate }}
      </p>
    </div>
    <div class="flex justify-end">
      <button
        kendoButton
        id="outlook-auth-msal-accountWarning-login"
        fillMode="flat"
        themeColor="primary"
        (click)="login()"
      >
        {{ 'outlook.buttons.signInMicrosoft' | translate }}
      </button>
    </div>
  </div>`,
  standalone: false,
})
export class MsalAccountWarningComponent {
  constructor(private readonly msalService: OutlookMsalService) {}

  login(): void {
    this.msalService.loginPopup();
  }
}
