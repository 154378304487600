import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Output,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { DisposableComponent } from './disposable.component';

@Component({
  selector: 'maximizer-embedded-component',
  template: ``,
  standalone: false,
})
export abstract class EmbeddedComponent<
  TActionArgs = unknown,
> extends DisposableComponent {
  @Output() actionCompleted = new EventEmitter<TActionArgs>();

  @HostBinding('class.max-ng') hostClass = true;

  constructor(public element: ElementRef) {
    super();

    this.element.nativeElement.doAction = (name: string, args: unknown) =>
      this.doAction(name, args);

    this.element.nativeElement.subscribeToAction = (
      name: string,
      args: unknown,
    ) => {
      return this.subscribeToAction(name, args);
    };
  }

  doAction(name: string, args: unknown): void {
    console.warn(
      'doAction method is not implemented for "%s" with arguments %o.',
      name,
      args,
    );
  }

  subscribeToAction(name: string, args: unknown): Observable<unknown> {
    console.warn(
      'subscribeToAction method is not implemented for "%s" with arguments %o.',
      name,
      args,
    );

    return of(undefined);
  }
}
