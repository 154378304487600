<kendo-dialog [width]="300" [height]="535">
  @if (loading) {
    <maximizer-loader type="legacy"></maximizer-loader>
  }

  <div class="scollbar narrow">
    <div class="flex justify-between items-center pb-2">
      <p class="subtitle-1">
        {{ 'outlook.sync.settings.title' | translate }}
      </p>
      <button
        id="outlook-sync-ui-settings-closeIcon"
        kendoButton
        fillMode="flat"
        iconClass="fa-icons icon-xmark icon-14"
        [attr.aria-label]="'outlook.buttons.close' | translate"
        (click)="openSyncSettings.emit(false)"
        class="small"
      ></button>
    </div>
    <p class="text-body">
      {{ 'outlook.sync.settings.subtitle1' | translate }}
    </p>
    <p class="text-body">
      {{ 'outlook.sync.settings.subtitle2' | translate }}
    </p>
    <form [formGroup]="form.group">
      <div class="settings-table scrollbar">
        <!-- Email -->
        <div class="flex flex-col gap-1">
          <div class="flex justify-between items-center pb-1">
            <div class="flex gap-2 items-baseline">
              <span
                class="fa-icons icon-16 icon-envelope text-neutral-90"
              ></span>
              <p class="subtitle-1">
                {{ 'outlook.sync.settings.email.title' | translate }}
              </p>
            </div>
            <kendo-switch
              id="outlook-sync-ui-settings-emailSwitch"
              formControlName="email"
              [disabled]="loading"
            ></kendo-switch>
          </div>
          <p class="caption text-neutral-70">
            {{ 'outlook.sync.settings.email.subtitle' | translate }}
          </p>
          <p class="text-body text-neutral-70 pt-1">
            {{ 'outlook.sync.settings.email.description' | translate }}
          </p>
        </div>
        <!-- Calendar -->
        <div class="flex flex-col gap-1">
          <div class="flex justify-between items-center pb-1">
            <div class="flex gap-2 items-baseline">
              <span
                class="fa-icons icon-16 icon-calendar-days text-neutral-90"
              ></span>
              <p class="subtitle-1">
                {{ 'outlook.sync.settings.calendar.title' | translate }}
              </p>
            </div>
            <kendo-switch
              id="outlook-sync-ui-settings-calendarSwitch"
              formControlName="appointment"
              [disabled]="loading"
            ></kendo-switch>
          </div>
          <p class="caption text-neutral-70">
            {{ 'outlook.sync.settings.calendar.subtitle' | translate }}
          </p>
          <p class="text-body text-neutral-70 pt-1">
            {{ 'outlook.sync.settings.calendar.description' | translate }}
          </p>
        </div>
        <!-- Contacts -->
        <div class="flex flex-col gap-1">
          <div class="flex justify-between items-center pb-1">
            <div class="flex gap-2 items-baseline">
              <span
                class="fa-icons icon-16 icon-address-book text-neutral-90"
              ></span>
              <p class="subtitle-1">
                {{ 'outlook.sync.settings.contacts.title' | translate }}
              </p>
            </div>
            <kendo-switch
              id="outlook-sync-ui-settings-contactSwitch"
              formControlName="contact"
              [disabled]="loading"
              [(ngModel)]="favoriteListEnabled"
              (valueChange)="onContactSyncChange()"
            ></kendo-switch>
          </div>

          <p class="caption text-neutral-70">
            {{ 'outlook.sync.settings.contacts.subtitle' | translate }}
          </p>
          <p class="text-body text-neutral-70 pt-1">
            {{ 'outlook.sync.settings.contacts.description' | translate }}
          </p>
          <kendo-label
            [text]="'outlook.sync.settings.contacts.label' | translate"
            class="text-body pt-1"
          >
            <kendo-dropdownlist
              id="outlook-sync-ui-settings-contactDropdown"
              formControlName="favoriteList"
              [data]="favoriteLists"
              valueField="id"
              textField="name"
              [valuePrimitive]="true"
            >
            </kendo-dropdownlist>
          </kendo-label>
        </div>
        <!-- Tasks -->
        <div class="flex flex-col gap-1">
          <div class="flex justify-between items-center pb-1">
            <div class="flex gap-2 items-baseline">
              <span
                class="fa-icons icon-16 icon-circle-check text-neutral-90"
              ></span>
              <p class="subtitle-1">
                {{ 'outlook.sync.settings.tasks.title' | translate }}
              </p>
            </div>
            <kendo-switch
              id="outlook-sync-ui-settings-tasksSwitch"
              formControlName="task"
              [disabled]="loading"
            ></kendo-switch>
          </div>
          <p class="caption text-neutral-70">
            {{ 'outlook.sync.settings.tasks.subtitle' | translate }}
          </p>
          <p class="text-body text-neutral-70 pt-1">
            {{ 'outlook.sync.settings.tasks.description' | translate }}
          </p>
        </div>
      </div>
      <div class="flex justify-between gap-2">
        <button
          id="outlook-sync-ui-settings-syncHistory"
          kendoButton
          fillMode="flat"
          themeColor="primary"
          (click)="openOutlookSyncHistory()"
        >
          {{ 'outlook.sync.settings.history' | translate }}
        </button>
        <div class="flex gap-1">
          <button
            id="outlook-sync-ui-settings-close"
            kendoButton
            class="uppercase"
            (click)="openSyncSettings.emit(false)"
          >
            {{ 'outlook.buttons.close' | translate }}
          </button>
          <button
            id="outlook-sync-ui-settings-save"
            kendoButton
            themeColor="primary"
            class="uppercase"
            [disabled]="!form.valid || loading || !form.group.dirty"
            (click)="updateSyncSettings()"
          >
            {{ 'outlook.buttons.save' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</kendo-dialog>

<maximizer-outlook-notification #notification [closable]="true">
</maximizer-outlook-notification>
