import { EntityFields } from './entity';
import {
  ReadRequest,
  RequestConfiguration,
  Request,
  WriteRequest,
} from './request';
import { DataResponse, WriteResponse, Response } from './response';
import { ValueAndDisplay } from './value-and-display';

export interface AddressKey {
  Value: string;
  ID: string;
  Number: number;
  EntityType: number;
}

export enum AddressFields {
  Key = EntityFields.Key,
  AddressLine1 = 'AddressLine1',
  AddressLine2 = 'AddressLine2',
  City = 'City',
  Country = 'Country',
  Default = 'Default',
  Description = 'Description',
  ParentKey = 'ParentKey',
  StateProvince = 'StateProvince',
  ZipCode = 'ZipCode',
  DisplayValue = EntityFields.DisplayValue,
  Location = 'Location',
}
export enum AddressLocationFields {
  Latitude = 'Latitude',
  Longitude = 'Longitude',
}
export interface Address<T = string> {
  [EntityFields.Key]?: string | AddressKey | T;
  [AddressFields.Description]?: T;
  [AddressFields.AddressLine1]: T;
  [AddressFields.AddressLine2]: T;
  [AddressFields.City]: T;
  [AddressFields.Country]: T;
  [AddressFields.StateProvince]: T;
  [AddressFields.ZipCode]: T;
  [AddressFields.Default]?: boolean | T;
  [AddressFields.ParentKey]?: string | T;
  [EntityFields.DisplayValue]?: string;
  [AddressFields.Location]?: AddressLocation<T>;
}
export interface AddressLocation<T = number> {
  [AddressLocationFields.Latitude]: T;
  [AddressLocationFields.Longitude]: T;
}
export type EntityAddress = Address<ValueAndDisplay<string>>;

export const AddressFieldsEditableListFields = {
  [AddressFields.City]: 60007,
  [AddressFields.StateProvince]: 60008,
  [AddressFields.Country]: 60009,
};

export interface AddressReadRequest extends Request {
  Address: ReadRequest<Address>;
}

export interface AddressWriteRequest extends Request {
  Address: WriteRequest<Address>;
}

export interface AddressResponse extends Response {
  Address: DataResponse<Address>;
}

export interface AddressWriteResponse extends Response {
  Address: WriteResponse<Address>;
}
export const AddressReadDriver: RequestConfiguration = {
  Drivers: {
    IAddressSearcher: 'Maximizer.Model.Access.Sql.AddressSearcher',
  },
};
