export const DefaultJourneyValue = 'action';
export const DefaultActionValue = 'clicked';

export interface SystemEventData {
  event?: string;
  source?: Source;
  metadata?: Record<string, string>;
}

export interface Source {
  module: string;
  location: string;
}

export interface TrackEventData extends SystemEventData {
  [key: string]: unknown;
  module?: string;
  journey?: string;
  action?: string;
}
