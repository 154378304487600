import { Component, Output, EventEmitter } from '@angular/core';
import { OutlookMsalService } from '@maximizer/outlook/shared/data-access';

@Component({
  selector: 'maximizer-outlook-msal-login-message',
  template: `<kendo-dialog [width]="256">
    <div class="py-2">
      <div class="flex flex-col gap-2 pb-3">
        <p
          class="text-body"
          [innerHtml]="'outlook.auth.msal.loginTitle' | translate"
        ></p>
        <p
          class="text-body"
          [innerHTML]="'outlook.auth.msal.loginBody1' | translate"
        ></p>
        <p class="text-body">
          {{ 'outlook.auth.msal.loginBody2' | translate }}
        </p>
      </div>
      <div class="flex justify-end">
        <button
          id="outlook-auth-msal-loginMessage-login"
          kendoButton
          themeColor="primary"
          class="uppercase"
          (click)="login()"
        >
          {{ 'outlook.buttons.logIn' | translate }}
        </button>
      </div>
    </div>
  </kendo-dialog>`,
  standalone: false,
})
export class MsalLoginMessageComponent {
  @Output() hideMsalLoginDialog = new EventEmitter<boolean>();

  constructor(private readonly msalService: OutlookMsalService) {}

  login(): void {
    localStorage.setItem('hideLoginMessage', JSON.stringify(true));
    this.hideMsalLoginDialog.emit(true);
    this.msalService.loginPopup();
  }
}
