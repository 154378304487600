export enum EntryType {
  AddressBook = 'addressBook',
  Case = 'case',
  Campaign = 'campaign',
  Lead = 'lead',
  Opportunity = 'opportunity',
  SalesProcess = 'salesProcess',
  Team = 'team',
  User = 'user',
}

export enum KeyFieldsEntryTypeCode {
  CompanyLead = 0,
  Company = 1,
  IndividualLead = 2,
  Individual = 3,
  ContactLead = 4,
  Contact = 5,
  Opportunity = 6,
  Campaign = 7,
  CSCase = 8,
}
