import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AvatarService } from '@maximizer/core/shared/data-access';
@Pipe({
  name: 'avatar',
})
export class AvatarPipe implements PipeTransform {
  constructor(private service: AvatarService) {}

  transform(key: string): Observable<string | null> {
    if (!key) {
      return of(null);
    }
    
    return this.service.get(key);
  }
}
