/* eslint-disable @angular-eslint/directive-selector */
import { AfterViewInit, Directive, ViewContainerRef } from '@angular/core';
import { MultiSelectTreeComponent } from '@progress/kendo-angular-dropdowns';

@Directive({
  selector: 'kendo-multiselecttree',
  standalone: false,
})
export class KendoMultiSelectTreeDirective implements AfterViewInit {
  component: MultiSelectTreeComponent;

  constructor(protected view: ViewContainerRef) {
    this.component = view.injector.get(MultiSelectTreeComponent);
  }

  ngAfterViewInit(): void {
    if (this.component.filterable) {
      this.component.closed.subscribe(() => {
        this.component.filterChange.emit('');
      });
    }
  }
}
