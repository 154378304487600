/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { ContextService } from '../services/context.service';
import { Octopus } from '@maximizer/core/shared/domain';
import { TokenService } from '../services/token.service';
import { isNullOrEmpty } from '@maximizer/shared/util';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private readonly SessionExpiredPage = '/SessionExpired.aspx';

  constructor(
    private readonly token: TokenService,
    private readonly context: ContextService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      (request.url.includes('Data.svc') &&
        !request.url.endsWith(Octopus.Action.AUTHENTICATE)) ||
      this.isFerretRequest(request)
    ) {
      return this.getOctopusRequest(request, next);
    }
    return next.handle(request);
  }

  private getOctopusRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    retryCount = 0
  ): Observable<HttpEvent<any>> {
    return this.token.getSession().pipe(
      switchMap((response) => {
        if (response.isSessionExpired) {
          location.href = this.context.website + this.SessionExpiredPage;
        }

        const headers = new HttpHeaders().append(
          'Authorization',
          'Bearer ' + this.context.token,
        );
        const newRequest = request.clone({ headers });
        return next.handle(newRequest).pipe(
          catchError((error) => {
            if (
              this.isFerretRequest(request) &&
              error instanceof HttpErrorResponse &&
              error.status === 401
            ) {         
              return of(null);
            }
            return throwError(() => error);
          }),
          switchMap((event) => {
            if (!event){
              if(retryCount > 3){
                throwError(() => new Error('Maximum authentication retries reached'));
              }
              this.context.token = '';
              return this.getOctopusRequest(request, next, retryCount + 1);
            }
            if (event.type == HttpEventType.Response) {
              if (this.isInvalidAuthentication(event)) {
                this.context.token = '';
                return this.getOctopusRequest(request, next);
              }
            }
            return of(event);
          }),
        );
      }),
    );
  }

  private isInvalidAuthentication(event: HttpEvent<any>): boolean {
    const httpResponse = event as HttpResponse<any>;
    const response = httpResponse.body as Octopus.Response;
    const invalidToken = 'invalid token';

    if (response) {
      const code = response.Code;
      const messages = response.Msg ?? [];

      const invalid = code === Octopus.ResponseStatusCode.InvalidAuthentication;
      const unsuccessful = code === Octopus.ResponseStatusCode.Unsuccessful;

      return (
        invalid ||
        (unsuccessful &&
          messages.some(
            (error: string | Octopus.ResponseError) =>
              (typeof error === 'string' &&
                error.toLowerCase().includes(invalidToken)) ||
              (error as Octopus.ResponseError).Message?.toLowerCase().includes(
                invalidToken,
              ),
          ))
      );
    }
    return false;
  }

  private isFerretRequest(
    request: HttpRequest<any> | HttpResponse<any>,
  ): boolean {
    return (
      (!isNullOrEmpty(this.context.ferretUrl) &&
        request.url?.startsWith(this.context.ferretUrl)) ??
      false
    );
  }
}
