import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContextService } from '@maximizer/core/shared/data-access';
import { Octopus } from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import {
  CaseCard,
  CaseCardMapper,
  CaseFieldOption,
  CaseFieldOptionsMapper,
  CaseForm,
} from '@maximizer/outlook/shared/domain';

@Injectable()
export class CaseService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  getFieldOptions(): Observable<CaseFieldOption> {
    const request: Octopus.CaseFieldOptionsRequest = {
      Case: {
        FieldOptions: {
          Status: [
            {
              Value: 1,
              DisplayValue: 1,
            },
          ],
          Subject: [
            {
              Value: 1,
              DisplayValue: 1,
            },
          ],
          Category: [
            {
              Value: 1,
              DisplayValue: 1,
            },
          ],
          Product: [
            {
              Value: 1,
              DisplayValue: 1,
            },
          ],
        },
      },
    };

    return this.http
      .post<Octopus.CaseFieldOptionsResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new CaseFieldOptionsMapper();
            return mapper.from(result);
          }
          return { status: [], subject: [], product: [], category: [] };
        }),
      );
  }

  save(data: CaseForm): Observable<boolean> {
    const request: Octopus.CaseWriteRequest = {
      Case: {
        Data: {
          Subject: data.subject,
          Description: data.description,
          AbEntryKey: data.abEntryKey,
          ContactKey: data.contactKey,
          Status: data.status,
          Product: data.product,
          Category: data.category,
        },
      },
    };

    return this.http
      .post<Octopus.CaseWriteResponse>(
        this.context.api + Octopus.Action.CREATE,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === 0 && response.Case?.Data) {
            return true;
          }
          return false;
        }),
      );
  }

  getCaseByContactId(key: string | string[]): Observable<CaseCard[]> {
    const request = {
      Configuration: Octopus.CaseReadDriver,
      Globalization: Octopus.DefaultGlobalization,
      Case: {
        Scope: {
          Fields: this.getCaseScope(),
        },
        Criteria: {
          SearchQuery: this.getLogicalQuery(key),
        },
        OrderBy: {
          Fields: [{ LastModifyDate: 'DESC' }],
        },
      },
    };

    return this.http
      .post<Octopus.CaseResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new CaseCardMapper();
            const cases = result.Case.Data || [];
            return cases.map((caseItem) => mapper.from(caseItem));
          }
          return [];
        }),
      );
  }

  private getLogicalQuery(key: string | string[]) {
    if (Array.isArray(key) && key.length) {
      return {
        $OR: [{ AbEntryKey: { $IN: key } }, { ContactKey: { $IN: key } }],
        $AND: [
          {
            Status: {
              Group: {
                $IN: [
                  Octopus.StatusAvailability.Assign,
                  Octopus.StatusAvailability.Escalate,
                ],
              },
            },
          },
        ],
      };
    } else {
      return {
        $OR: [{ AbEntryKey: { $EQ: key } }, { ContactKey: { $EQ: key } }],
        $AND: [
          {
            Status: {
              Group: {
                $IN: [
                  Octopus.StatusAvailability.Assign,
                  Octopus.StatusAvailability.Escalate,
                ],
              },
            },
          },
        ],
      };
    }
  }

  private getCaseScope(): Octopus.Scope<Octopus.Case> {
    return {
      Key: 1,
      CaseNumber: 1,
      Subject: 1,
      ParentKey: 1,
      Priority: {
        Value: 1,
        DisplayValue: 1,
      },
      Severity: {
        Value: 1,
        DisplayValue: 1,
      },
      AbEntry: {
        Key: 1,
        CompanyName: 1,
        FirstName: 1,
        LastName: 1,
        Type: 1,
      },
      Contact: {
        Key: 1,
        FirstName: 1,
        LastName: 1,
        DisplayValue: 1,
      },
      Owner: {
        Value: 1,
        DisplayValue: 1,
      },
      CreationDate: {
        Value: 1,
        DisplayValue: 1,
      },
      Status: {
        Value: 1,
        DisplayValue: 1,
      },
    };
  }
}
