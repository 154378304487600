import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ContextService } from './context.service';
import { Octopus, FavoriteList } from '@maximizer/core/shared/domain';
import { EntityType } from 'libs/core/shared/domain/src/lib/api/octopus';

@Injectable()
export class FavoriteListService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  getAddressBookFavoriteList(): Observable<FavoriteList[]> {
    const request: Octopus.FavoriteListReadRequest = {
      FavoriteList: {
        Criteria: {
          SearchQuery: {
            EntityType: {
              $EQ: 1,
            },
            Name: {
              $NIN: ['****STARTUP LIST****', '***Refresh list***'],
            },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            Name: 1,
            EntityType: EntityType.AbEntry,
            Description: 1,
            Creator: 1,
            CreationDate: 1,
          },
        },
      },
      Configuration: Octopus.FavoriteListReadDriver,
    };

    return this.http
      .post<Octopus.FavoriteListResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.FavoriteListMapper();
            return result.FavoriteList.Data.map((items) => mapper.from(items));
          }
          return [];
        }),
      );
  }
}
