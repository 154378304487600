import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Security } from '@maximizer/core/shared/domain';
import { CaseCard } from '@maximizer/outlook/shared/domain';

@Component({
  selector: 'maximizer-outlook-case-card',
  templateUrl: './case-card.component.html',
})
export class CaseCardComponent {
  @Output() save = new EventEmitter<string>();

  @Input() rights?: Security;
  @Input() case: CaseCard = {
    key: '',
    subject: '',
    parentKey: '',
    status: null,
    caseNumber: '',
    abEntry: {
      key: null,
      companyName: '',
    },
    owner: {
      value: '',
      display: '',
    },
    isSaved: false,
    contact: {
      key: null,
      firstName: '',
      lastName: '',
    },
  };
}
